"use strict";

// onboarding
import "jquery";
import "angular";
import "angular-cookies";
import "angular-sanitize";
import "noty/js/noty/packaged/jquery.noty.packaged.js";
import "../../lib/angular.noty.js";
import "Jwt/jwt.js";
import "Environment/index.js";
import "./app.js";
import "./onboarding.less";
