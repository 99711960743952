/**
 * Add the user authentication module.
 */
(function () {
    'use strict';

    // Define the jwt module
    angular.module('jwt', []);

    var app = angular
        .module('brightfunnelUserAuth', ['ngCookies', 'environment',
            'jwt']);

    /**
     * Configuration for the application.
     */
    function appConfig() {
    }

    app.config(appConfig);

    /**
     * Run function for the application.
     */
    function appRun() {
    }

    app.run(appRun);
})();
