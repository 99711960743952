/**
 * Onboarding Service.
 */
(function () {
    'use strict';

    angular
        .module('brightfunnelOnboard')
        .factory('utilsSrvc', utilsSrvc);

    utilsSrvc.$inject = ['domainSrvc'];

    /**
     * Service to get onboarding data.
     */
    function utilsSrvc(domainSrvc) {
        var service = {};

        service.jsonToFieldObject = function (json) {
            const fieldParams = [];
            for (let key in json) {
                const param = {
                    name: key,
                    value: json[key],
                };
                fieldParams.push(param);
            }
            return fieldParams;
        };

        service.getBaseHost = function () {
            return window.location.protocol + "//" + domainSrvc.getBfDomain();
        };

        service.getParamFromQueryString = function (paramName) {
            let urlParams = new URLSearchParams(window.location.search);
            return urlParams.has(paramName) ? urlParams.get(paramName) : '';
        };

        return service;
    }
})();
