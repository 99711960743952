(function () {
    'use strict';

    angular.module('accountJourney').directive('insightsComponent',insightsComponent);

    insightsComponent.$inject = ['c3ChartManager'];

    function insightsComponent(c3ChartManager) {
        return {
            restrict:  'E',
            template: require('./insights-component.html'),
            link: function (scope, element, attr) {
                c3ChartManager.startObservingChartDirtyState(attr, element);
            }
        };
    }
})();
