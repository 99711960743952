(function () {
    'use strict';

    angular.module('accountJourney').directive('mostActivePersonCard',mostActivePersonCard);

    mostActivePersonCard.$inject = [];

    function mostActivePersonCard() {
        return {
            template: require('./most-active-person-card.html'),
            controller: 'mostActivePersonCardController',
            controllerAs: 'mostActivePersonCardCtrl',
        };
    }
})();

