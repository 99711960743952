/**
 * Directive to implement the error/info and sucess messages in a form
 */
(function () {
    'use strict';

    angular.module('brightfunnelUserAuth').directive('formError', formErrorComponent);

    formErrorComponent.$inject = [];

    /**
     * Form message component
     * @returns {{bindToController: boolean, controller: formErrorCtrl, scope: {type: string, value: string}, restrict: string, controllerAs: string, templateUrl: string}}
     */
    function formErrorComponent() {
        return {
            restrict: 'E',
            template: require('./form-error.html'),
            controller: formErrorCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                'type': '=',
                'value': '='
            }
        };
    }

    /**
     * Empty controller
     */
    function formErrorCtrl($scope, $sce) {
        var ctrl = this;
        $scope.$watch('$ctrl.value', function (newValue) {
            ctrl.trustedValue = $sce.trustAsHtml(newValue);
        });
    }
})();

