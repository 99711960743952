(function () {
    'use strict';

    angular.module('accountJourney').controller('accountJourneyController', accountJourneyController);

    accountJourneyController.$inject = ['accountJourneyService', '$scope', '$filter', '$state'];

    function accountJourneyController(accountJourneyService, $scope, $filter, $state) {
        var self = this;

        self.refreshInsightCharts = undefined;
        self.refreshJourneyCharts = undefined;

        self.insightsSelected = function() {
            $scope.state = { data: $state.current.data };

            $scope.peopleTabSortColumn = undefined;
            $scope.activeTab = 0;
            self.refreshInsightCharts = $scope.insightChartsDirty;
            $scope.insightChartsDirty = undefined;
            clearJourneyFlags();
        };

        self.journeySelected = function() {
            self.refreshJourneyCharts = $scope.journeyChartsDirty;
            $scope.journeyChartsDirty = undefined;
            clearInsightFlags();
        };

        self.tabSelected = function() {
            // Clear these flags on all tabs that don't have charts
            clearInsightFlags();
            clearJourneyFlags();
        };

        function clearInsightFlags() {
            self.refreshInsightCharts = undefined;
        }

        /**
         * Initialize controller variables.
         * Executed at start.
         */
        function init() {
            $scope.state = { data: $state.current.data };
            /**
             * Check if we are running standalone and get the last update
             */
            if (accountJourneyService.isStandaloneApp()) {
                accountJourneyService.getLastUpdate().then(function(date) {
                    $scope.headerUpdate = date;
                });
            }
        }

        init();

        function clearJourneyFlags() {
            self.refreshJourneyCharts  = undefined;
        }

        self.insightsSelected();

        $scope.contactCellLink = function (id) {
            return id ? $scope.sfdcURL + '/' + id : null;
        };

        $scope.navigateToPeopleTab = function(sortBy) {
            $scope.activeTab = 1;
            $scope.peopleTabSortColumn = sortBy;
        };

        $scope.$on('$destroy', function() {
            accountJourneyService.reset();
        });

        $scope.exportDateField = function(dateValue) {
            return dateValue ? $filter('date')(dateValue) : '';
        };
    }
})();
