(function () {
    'use strict';

    angular
        .module('brightfunnelOnboard')
        .constant('onboardingConstants', {
            TERMINUS_HUB: 'Terminus Hub',
            FILTER_LABEL: 'Filter Integrations',
            NO_INTEGRATIONS_LABEL: 'No available integrations',
            NO_INTEGRATION_SELECTED_LABEL: 'Select an integration',
            GO_TO_INTEGRATIONS: 'Go to integrations',
            INTEGRATION_SUCCESS: 'Congratulations. Integration successfully completed.',
            INTEGRATION_ERROR: 'Ups. Credentials may be wrong. Try again.',
            UNKNOWN_INTEGRATION_ERROR: 'Unknown integration supplied.',

            CUSTOM_AUTH: 'CUSTOM',
            SUB_ORG_CLAIM: 'sub.org',
            SUB_CLAIM: 'sub',
            ACL_FIELD: 'acl',
            FIELDS_FIELD: 'fields',
            SERVICE_ID_FIELD: 'serviceId',
            CALLBACK_URL_FIELD: 'callbackUrl',
            CODE_FIELD: 'code',
            STATE_FIELD: 'state',
            ERROR_FIELD: 'error',
            TOKEN_MANAGER_DOMAIN_KEY: 'TOKEN_MANAGER_URL',
            STORAGE_TOKEN: 'token-manager',
            DEFAULT_IMAGE: '/images/terminus-logo-2019-gradient.svg',

            AUTHORIZATION_URL: '/v1/get_auth_url',
            SERVICE_NAMES_URL: '/v1/get_service_names',
            CREDENTIAL_FIELDS_URL: '/v1/get_credential_fields',
            ONBOARDING_URL: '/onboarding',
            ONBOARDING_CALLBACK_URL: '/onboarding/callback',
            CALLBACK_URL: '/oauth/callback',
            AUTHORIZE_URL: '/v1/authorize',
            STORE_TOKEN_URL: '/v1/store_credentials_',
        });
})();
