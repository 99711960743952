/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('chartDataNormalizer', chartDataNormalizer);

    chartDataNormalizer.$inject = ['$filter', 'AccountJourneyConstants'];

    function chartDataNormalizer($filter, AccountJourneyConstants) {
        var service = {};

        service.normalizeDateTimeData = function (data) {
            return $filter('date')(moment(data).startOf('isoWeek').toISOString(), 'shortDate');
        };

        service.generateStartOfWeek = function (weekOfYear) {
            var splitWeekYear = weekOfYear.split(' ');
            var year = splitWeekYear[0];
            var week = splitWeekYear[1];

            return moment().year(year).isoWeek(week).startOf('isoWeek').toISOString();
        };

        // The below functions normalize data into a common format for use by the aggregator
        // algorithm. Each object has a date field and a count field. The count is either an integer that
        // is used to directly sum the total, a string that indicates a property that should be used to
        // to calculate the sum or an object that indicates a list that is used
        service.normalizeData = function (data, type) {
            if (data.length <= 0) {
                return [];
            }
            switch (type) {
            case AccountJourneyConstants.AnonymousData:
                return normalizeAnonymousData(data);
            case AccountJourneyConstants.PeopleData:
                return normalizePeopleData(data);
            default:
                return normalizeWeekOfYearData(data);
            }
        };

        function normalizePeopleData(data) {
            return data.map(function (a) {
                return { date: a.date, count: 'influenced' };
            });
        }

        function normalizeAnonymousData(data) {
            if (data[0].topics) {
                // Bombora intent data
                return data.map(function (a) {
                    return { date: a.date, count: { list: a.topics } };
                });
            } else if (data[0].models) {
                // Engagement data
                return data.map(function (a) {
                    return { date: a.date, count: { list: a.models } };
                });
            } else if (data[0].counts) {
                // Page view data
                return data.map(function (a) {
                    return { date: a.date, count: a.counts };
                });
            }
            return undefined;
        }

        function normalizeWeekOfYearData(data) {
            if (data[0].week_of_year) {
                // sales data by week
                return data.map(function (a) {
                    return {
                        date: $filter('date')(service.generateStartOfWeek(a.week_of_year), 'shortDate'),
                        count: a.counts ? a.counts : 1,
                        payload: a
                    };
                });
            }
            return undefined;
        }

        return service;
    }
})();

