(function () {
    'use strict';

    angular.module('accountJourney').directive('peopleTabComponent', peopleTabComponent);

    peopleTabComponent.$inject = [];

    function peopleTabComponent() {
        return {
            restrict: 'E',
            template: require('./people-tab-component.html'),
            controller: 'peopleTabController',
            controllerAs: 'peopleCtrl'
        };
    }
})();
