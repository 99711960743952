/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('chartDataGenerationService', chartDataGenerationService);

    chartDataGenerationService.$inject = ['$filter', 'chartConfigBuilderService', 'chartDataNormalizer', 'chartDataAggregator', 'AccountJourneyConstants', 'accountJourneyToolTipContentGenerator'];

    function chartDataGenerationService($filter, chartConfigBuilderService, chartDataNormalizer, chartDataAggregator, AccountJourneyConstants, accountJourneyToolTipContentGenerator) {
        var service = {};

        service.generateChartData = function (data, dataType, chartType, options, scope) {
            if (!options) {
                options = {};
            }

            var aggregateData;
            var normalizedData;

            if (dataType === AccountJourneyConstants.JourneyData) {
                aggregateData = {};
                normalizedData = {};

                normalizedData.sales = chartDataNormalizer.normalizeData(data['sales'].data.tasks, AccountJourneyConstants.SalesData);
                normalizedData.marketing = chartDataNormalizer.normalizeData(data['touches'].data.activity_list, AccountJourneyConstants.MarketingData);
                normalizedData.pageViews = chartDataNormalizer.normalizeData(data['anonymous'].data.page_views, AccountJourneyConstants.PageViewData);
                normalizedData.intent = chartDataNormalizer.normalizeData(data['anonymous'].data.intent_topics, AccountJourneyConstants.BomboraIntent);
                normalizedData.engagement = chartDataNormalizer.normalizeData(data['anonymous'].data.spike_models, AccountJourneyConstants.EngagementSpike);

                aggregateData.sales = chartDataAggregator.aggregateData(normalizedData.sales, chartType);
                aggregateData.marketing = chartDataAggregator.aggregateData(normalizedData.marketing, chartType);
                aggregateData.pageViews = chartDataAggregator.aggregateData(normalizedData.pageViews, chartType);
                aggregateData.intent = chartDataAggregator.aggregateData(normalizedData.intent, chartType);
                aggregateData.engagement = chartDataAggregator.aggregateData(normalizedData.engagement, chartType);
            } else {
                var chartDataMap;
                normalizedData = chartDataNormalizer.normalizeData(data, dataType);
                if (normalizedData.length === 0) {
                    return { emptyChart: true };
                }
                chartDataMap = chartDataAggregator.aggregateData(normalizedData, chartType);

                aggregateData = chartType === AccountJourneyConstants.LineGraph
                    ? chartDataAggregator.groupDatesByWeek(Object.values(chartDataMap))
                    : Object.values(chartDataMap);
            }

            var chartDataWrapper = {};
            chartDataWrapper.chartConfig = chartConfigBuilderService.build(aggregateData, null, chartType, scope);
            chartDataWrapper.chartData = aggregateData;
            chartDataWrapper.emptyChart = false;

            if (options.totals) {
                var totals = 0;
                Object.keys(aggregateData).forEach(function (date) {
                    totals += aggregateData[date].total;
                });
                chartDataWrapper.totals = totals;
            }

            if (options.latestDate) {
                var latest = undefined;
                var modelData = [];
                aggregateData.forEach(function (d) {
                    if (!latest) {
                        latest = d;
                    }
                    if (d.text) {
                        modelData.push(d.text);
                    }
                    latest = (moment(latest.date).isAfter(d.date) ? latest : d);
                });
                if (latest) {
                    chartDataWrapper.latestDate = latest.date;
                    chartDataWrapper.data = latest.text === undefined ? latest.total : modelData.join(', ');
                }
            }

            if (chartType === AccountJourneyConstants.JourneyGraph) {
                chartDataWrapper.chartConfig.tooltip = {
                    contents: accountJourneyToolTipContentGenerator.generateToolTipFn(aggregateData, data, chartType, options.sfdcUrl)
                };
            }

            return chartDataWrapper;
        };

        service.generateOpptyRegion = function (opptyHistory, dateRange) {
            var history = opptyHistory.history,
                historicRecords = {},
                opptyCreatedDate = opptyHistory.opptyCreateDate,
                opptyClosedDate = opptyHistory.opptyClosedDate,
                opptyCreated = 'Opportunity Created',
                opptyClosed = 'Opportunity Closed',
                groupedStagesByDate = groupByDate(history),
                dateKeys = Object.keys(groupedStagesByDate).sort(function (a, b) {
                    var aDate = moment(a.date),
                        bDate = moment(b.date);

                    if (aDate.isBefore(bDate)) {
                        return -1;
                    } else if (aDate.isAfter(bDate)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });


            dateKeys.map(function (startOfWeek, index) {
                var nextStage = dateKeys[index + 1],
                    endDate = nextStage ? chartDataNormalizer.normalizeDateTimeData(nextStage) : opptyClosedDate,
                    stages = groupedStagesByDate[startOfWeek];

                historicRecords[startOfWeek] = chartConfigBuilderService.buildRegion('oppty-region', startOfWeek, endDate, dateRange, stages);
            });

            if (dateRange.indexOf(opptyCreatedDate) >= 0) {
                if (historicRecords[opptyCreatedDate]) {
                    historicRecords[opptyCreatedDate]['labels'].unshift(opptyCreated);
                } else {
                    historicRecords[opptyCreatedDate] = chartConfigBuilderService.buildRegion('oppty-region', opptyCreatedDate, opptyCreatedDate, dateRange, opptyCreated);
                }
            }

            if (dateRange.indexOf(opptyClosedDate) >= 0) {
                if (historicRecords[opptyClosedDate]) {
                    historicRecords[opptyClosedDate]['labels'].push(opptyClosed);
                } else {
                    historicRecords[opptyClosedDate] = chartConfigBuilderService.buildRegion('oppty-region', opptyClosedDate, opptyClosedDate, dateRange, opptyClosed);
                }
            }

            return Object.keys(historicRecords).map(function (date) {
                return historicRecords[date];
            });

        };


        function groupByDate (history) {
            return history.reduce(function (acc, event) {
                var formatedStartDate = chartDataNormalizer.normalizeDateTimeData(event.date),
                    stageName = event.activityType;

                if (acc[formatedStartDate]) {
                    acc[formatedStartDate] = acc[formatedStartDate].concat(stageName);
                } else {
                    acc[formatedStartDate] = [stageName];
                }
                return acc;
            }, {});
        }

        return service;
    }
})();
