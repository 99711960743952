/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-vars */
angular.module('bf.filters', [])
    .constant('_', window._)
    .filter('dashForNull', function () {
        return function (input) {
            var retVal = input;
            if (input === null || input === '' || input === "") {
                retVal = '--';
            }
            return retVal;
        };
    })
    .filter('p2sNumber', function () {
        return function (input) {
            if (isNaN(input)) {
                return "--";
            }
            if (input === 0.0) {
                return "0";
            }

            if (Math.abs(input) < 0.01) {
                if (input > 0) {
                    return "< 0.01";
                } else {
                    return "> -0.01";
                }
            } else {
                // ideally would use the number filter, but
                // not sure how to use it here
                return input.toFixed(0);
            }
        };
    })
    .filter('propsFilter', function () {
        return function (items, props) {
            var out = [];

            if (angular.isArray(items)) {
                items.forEach(function (item) {
                    var itemMatches = false;

                    var keys = Object.keys(props);
                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    })
    .filter("nrFormat", ['$rootScope', 'utilities', function ($rootScope, utilities) {
        /**
         * If the decimal is zero, it is ignored if ignoreTrailingZeros is true
         * @param number The number to format
         * @param decimals the number of decimals to use
         * @param ignoreZeroDecimals True, if we should remove trailing decimals
         * @return {strint} The number in string format
         */
        function removeTrailingZeros(number, decimals, ignoreZeroDecimals) {
            number = number.toFixed(decimals);
            if (ignoreZeroDecimals && number % 1 === 0) {
                number = +number;
            }
            return number;
        }

        /**
         * The filter takes a number and converts it to a string.
         * @param number The number to convert
         * @param currency If true, adds a currency symbol, otherwise is ignored
         * @param numberOfDecimals The number of decimals to use. The default is zero.
         * @param ignoreDecimals If (undefined or true) and decimals are exactly zero remove them.
         *  Otherwise, decimals are kept.
         *  @return {string} a String with the label
         */
        return function (number, currency, returnZero, numberOfDecimals, ignoreZeroDecimals) {
            var abbreviated = '0';
            numberOfDecimals = numberOfDecimals || (currency ? 1 : 0);
            ignoreZeroDecimals = ignoreZeroDecimals === false ? false : true;

            if (returnZero && isNaN(number)) {
                return NaN;
            } else {
                number = number || 0;
            }

            if (number) {
                var suffixes = ['', 'K', 'M', 'B', 'T'];
                var exponent = Math.floor(Math.log(number) / Math.log(1000));

                if (exponent < 0) {
                    exponent = 0;
                }

                abbreviated = removeTrailingZeros(number / Math.pow(1000, exponent),
                    numberOfDecimals, ignoreZeroDecimals);
                abbreviated += suffixes[exponent];
            }
            if (currency) {
                abbreviated = utilities.currencySymbol($rootScope.orgConfig.groupings.currency) + abbreviated;
            }

            return abbreviated;
        };
    }])
    // the below currency filter returns a string with the correct currency symbol before each value (rounded to the nearest int, with commas added as necessary)
    .filter('bfCurrency', ['$rootScope', 'utilities', '_', function ($rootScope, utilities, _) {
        return function (val) {
            if (!_.isDefined(val)) { return ""; }
            var rounded = Math.round(val);
            var withCommas = rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return utilities.currencySymbol($rootScope.orgConfig.groupings.currency) + withCommas;
        };
    }])
    .filter('round', function () {
        return function (num, places) {
            if (!num) return 0;
            var p = places || 0;
            if (num.toString() == '0' || num)
                return num.toFixed(p);
        };
    })
    .filter('haRounder', function ($filter) {
        return function (num, places) {
            if (!num) return 0;
            var p = places || 2;
            return + $filter('round')(num, num < 1 ? p : 0);
        };
    })
    .filter('roundCurrency', function ($filter) {
        return function (num, places) {
            if (num === null || num === undefined) return '$0';
            var p = places || 0;
            if (num || num === 0)
                return '$' + $filter('number')(num.toFixed(p));
        };
    })
    .filter('percentage', ['$filter', function ($filter) {
        return function (input, decimals) {
            if (isNaN(input)) {
                return input;
            }
            var percent = input * 100;
            return $filter('numberEx')(percent, decimals) + '%';
        };
    }])
    .filter('capitalize', function () {
        return function (input, all) {
            return (!!input) ? input.replace(/([^\W_]+[^\s-]*) */g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) : '';
        };
    })

    .filter('arrToList', function () {
        return function (arr) {
            var list = angular.copy(arr),
                last = list && list.length > 1 ? list.pop() : false,
                st = list.join(', ');

            if (last) {
                st = st + ', and ' + last;
            }
            return st;
        };
    })

    .filter('truncate', [function () {
        return function (text, length, end) {
            if (text) {
                if (isNaN(length))
                    length = 10;

                if (end === undefined)
                    end = "...";

                if (text.length <= length || text.length - end.length <= length) {
                    return text;
                }
                else {
                    return String(text).substring(0, length - end.length) + end;
                }
            }
        };
    }])

    .filter('replace', ['_', function (_) {
        return function (text, replace, w) {
            if (text) {
                var r = new RegExp(_.escapeRegExp(replace), 'g'); //Note the double backslash
                var txt = text.replace(r, w);
                return txt;
            }
        };
    }])

    .filter('yN', function () {
        return function (input) {
            return input ? 'Y' : 'N';
        };
    })

    .filter('metafilter', function ($interpolate) {
        return function (value, filter) {
            if (!filter) {
                return value;
            }

            var result = $interpolate('{{value | ' + filter + '}}');

            return result({ value: value });
        };
    })

    .filter('orderObjectBy', function () {
        return function (input, attribute) {
            if (!angular.isObject(input)) return input;

            var array = [];
            for (var objectKey in input) {
                array.push(input[objectKey]);
            }

            array.sort(function (a, b) {
                a = parseInt(a[attribute]);
                b = parseInt(b[attribute]);
                return a - b;
            });
            return array;
        };
    })


    .filter('numberEx', ['numberFilter', '$locale',
        function (number, $locale) {

            var formats = $locale.NUMBER_FORMATS;
            return function (input, fractionSize, prefix) {
                if (input === null || input === undefined) {
                    return null;
                }
                //Get formatted value
                var formattedValue = number(input, fractionSize);

                //get the decimalSepPosition
                var decimalIdx = formattedValue.indexOf(formats.DECIMAL_SEP);

                //If no decimal just return
                if (decimalIdx == -1) return formattedValue;


                var whole = formattedValue.substring(0, decimalIdx);
                var decimal = (Number(formattedValue.substring(decimalIdx)) || "").toString();

                return (prefix ? prefix : '') + (whole + decimal.substring(1));
            };
        }
    ])

    .filter('smartSearch', function ($filter, _) {
        return function (array, exp) {
            var s = _.contains(exp.$, ' ') ? exp.$.split(' ') : [exp.$],
                statement = hasCondition(exp.$),
                or = _.contains(s, '||'),
                and = _.contains(s, '&&'),
                specific = _.contains(s, ':'),
                term;

            angular.forEach(s, function (piece, i) {
                var or = _.contains(piece, '||'),
                    and = _.contains(piece, '&&'),
                    equals = _.contains(piece, '='),
                    bang = _.contains(piece, '!'),
                    value = _.contains(piece, '\'') || _.contains(piece, '"');

                if (!or && !and && !equals && !bang && !value) {
                    s[i] = 'obj.ranged.' + s[i];
                }
            });

            term = statement ? function (obj) {
                try {
                    return eval(s);
                } catch (e) {
                    return true;
                }
            } : exp,
            s = s.length ? s.join(' ') : s[0];

            function hasCondition(s) {
                return _.contains(s, '||') || _.contains(s, '&&') || _.contains(s, '=') || _.contains(s, '!');
            }

            return $filter('filter')(array, term);
        };
    })

    // the below date filter returns a year for daily and weekly time units
    .filter('bfDateInterval', function () {
        return function (val, row, timeUnit) {
            if (timeUnit === 'day' || timeUnit === 'week') {
                var d = null;
                if (row["visit_date_utc"]) {
                    d = new Date(row["visit_date_utc"]);
                } else if (row["event_date_utc"]) {
                    d = new Date(row["event_date_utc"]);
                }

                //add year to date
                if (d) {
                    return val + "/" + (d.getFullYear().toString().substr(2));
                }
            } else if (timeUnit === 'fiscal_qtr') {
                //make Q418 --> Q4'18
                return val.substr(0, 2) + "'" + val.substr(2);
            }

            return val;
        };
    });
