(function () {
    'use strict';

    angular.module('accountJourney').directive('activityTrendsCard', activityTrendsCard);

    activityTrendsCard.$inject = [];

    function activityTrendsCard() {
        return {
            restrict: 'E',
            template: require('./activity-trends-card.html'),
            controller: 'activityTrendsCardController',
            controllerAs: 'activityTrendsCardCtrl'
        };
    }
})();


