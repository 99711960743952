/**
 * Handles data from the register controller.
 */
(function () {
    'use strict';

    angular
        .module('jwt')
        .factory('userAuthSrvc', userAuthSrvc);

    userAuthSrvc.$inject = ['$http', '$cookies', '$window',
        'userAuthConstants', 'environmentSrvc'];

    /**
     * Service to post data about the user to impersonate.
     * @param $http The $http service
     * @param $cookies The $cookies service
     * @param $window The $window service
     * @param userAuthConstants The user constants
     * @returns {userAuthSrvc} This service
     */
    function userAuthSrvc($http, $cookies, $window,
        userAuthConstants, environmentSrvc) {
        var service = this;

        /**
         * Returns the environment specific domain to facilitate sharing a cookie
         * with scorecard.
         */
        function getCookieDomain() {
            const host = $window.location.hostname;
            const groups = host.match(/(?:[a-z|-]*\.)?([a-z|\\.]+)/);
            if (groups[1]) {
                return groups[1];
            }
            throw 'Unknown host';
        }

        /**
         * Clean cookies and localstorage at startup.
         */
        service.unauthenticate = function () {
            $window.localStorage.removeItem(userAuthConstants.API.TOKEN_STORAGE_KEY);
            $cookies.remove(userAuthConstants.API.COOKIE_NAME, {domain: getCookieDomain()});
        };

        /**
         * Post the identity of the user to impersonate.
         * @param params The parameters to post
         * @return {Promise<*>} A promise which will resolve/reject based on the
         *    request status.
         */
        service.send = function (url, params) {
            return environmentSrvc.getVariable(userAuthConstants.API.GRAILS_KEY).then(function(host) {
                return $http.post(host + url,
                    JSON.stringify(params),
                    { withCredentials: false });
            }, function(error) {
                throw error;
            });
        };

        /**
         * Checks if the input is a valid email string
         * @param email The email to check
         * @param constants The constants error message to use
         * @returns {string} The message to return to the user
         */
        service.verifyEmail = function (email, constants) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(email).toLowerCase())) {
                return '';
            } else {
                return constants.ERRORS.INVALID_EMAIL;
            }
        };

        /**
         * Check if the password is according to the specifications
         * @param password The password
         * @param retype The retyped password
         * @param constants The constants error message to use
         * @returns {string} The message to return to the user
         */
        service.verifyPassword = function (password, retype, constants) {
            var re = /(?=.*\d)/,
                errors = [],
                match = true,
                hasMin = false;
            if (!re.test(String(password))) {
                errors.push(constants.ERRORS.MISSING_NUMBER);
            }

            re = /(?=.*[a-z])/;
            if (!re.test(String(password))) {
                errors.push(constants.ERRORS.MISSING_LOWERCASE);
            }

            re = /(?=.*[A-Z])/;
            if (!re.test(String(password))) {
                errors.push(constants.ERRORS.MISSING_UPPERCASE);
            }

            re = /(?=.*[!@#$%^&'])/;
            if (!re.test(String(password))) {
                errors.push(constants.ERRORS.MISSING_SYMBOL);
            }

            re = /(.{8,})/;
            hasMin = re.test(String(password));

            match = password === retype;

            if (!errors.length && match && hasMin) {
                return '';
            } else if (errors.length === 1) {
                return constants.ERRORS.PASSWORD_MESSAGE + errors[0];
            } else if (!match && !errors.length) {
                return constants.ERRORS.NOT_MATCH;
            } else if (!hasMin && !errors.length) {
                return constants.ERRORS.SMALL_PASSWORD;
            } else {
                var message = constants.ERRORS.PASSWORD_MESSAGE + ':<ul>';
                for (var i = 0; i < errors.length; i++) {
                    if (i < errors.length - 1) {
                        message += '<li>' + errors[i] + ',</li>';
                    } else {
                        message += '<li>and ' + errors[i] + '.</li>';
                    }
                }
                message += '</ul>';
                if (!hasMin) {
                    message += constants.ERRORS.SMALL_PASSWORD;
                }
                if (!match) {
                    if (!hasMin) {
                        message += '<br/><br/>';
                    }
                    message += constants.ERRORS.NOT_MATCH;
                }
                return message;
            }

        };

        /**
         * Return the search params
         * @param url The url to get
         * @returns {URLSearchParams} The search params for that url
         */
        service.getUrlSearchParams = function (url) {
            var urlObj = new URL(url);
            return urlObj.searchParams;
        };

        /**
         * Get query param from the url
         * @param url The url to extract the code
         * @param paramName The param name to get the value
         * @returns {string} The param extracted from the url or null if not found.
         */
        service.getParamValue = function (url, paramName) {
            return service.getUrlSearchParams(url).get(paramName) || null;
        };

        /**
         * Get absolute url
         * @param url A relative or absolute URL
         * @returns {string} The related absolute URL
         */
        service.getAbsoluteUrl = function (url) {
            var regExp = /(https?:\/\/[^:/]+:?[0-9]*)/i;

            if (typeof location.origin === 'undefined') {
                location.origin = location.protocol + '//' + location.host;
            }

            if (!url) {
                url = location.origin + '/';
            } else {
                var matches = url.match(regExp);
                if (!matches) {
                    var hasSlash = url.startsWith('/');
                    url = location.origin +
                        (hasSlash ? '' : '/') + url;
                }
            }
            return url;
        };

        /**
         * Redirects the page to a url
         * @param url Url to redirect
         */
        service.redirect = function (url) {
            window.location.href = url;
        };

        /**
         * Set the function for the primary button. (Just one at the moment)
         * @param fn The function to call
         */
        service.setFormClickFn = function (fn) {
            service.formFn = fn;
        };

        /**
         * Get the primary function
         * @returns {*} The primary function in the form
         */
        service.getFormClickFn = function () {
            return service.formFn;
        };

        /**
         * Parses a jwt and gets the data in the claims
         * @return {any} The claims to show"exp" (Expiration Time) Claim
         */
        function parseJwt(token) {
            if (token && token !== "\"\"") {
                var base64Url = token.split('.')[1];
                if (base64Url) {
                    var base64 = base64Url.replace('-', '+').replace('_', '/');
                    return JSON.parse(window.atob(base64));
                }
                return '';
            } else {
                return '';
            }
        }

        /**
         * Get a Js Date Object with the JWT expiration date
         * @param jwt The Jwt
         * @returns {Date} The Js Expiration Date
         */
        function getJwtExpirationJsDate(jwt) {
            const tokenObj = parseJwt(jwt);
            return new Date(tokenObj.exp * 1000);
        }

        /**
         * Store the jwt from the http body into a cookie
         * @param jwt The JWT
         */
        service.writeJwtToCookie = function (jwt) {
            $cookies.put(userAuthConstants.API.AUTH_CONT_KEY,
                userAuthConstants.API.AUTH_CONT_EMPLOYEE,
                {
                    path: '/',
                    domain: getCookieDomain(),
                    expires: getJwtExpirationJsDate(jwt),
                });
            $cookies.put(userAuthConstants.API.COOKIE_NAME, jwt, {
                path: '/',
                domain: getCookieDomain(),
                expires: getJwtExpirationJsDate(jwt),
            });
        };

        /**
         * Check if the user should use the Okta login
         * @return Promise<string> A resolved fromise if Okta should be used
         */
        service.hasOktaLogin = function() {
            $cookies.remove(userAuthConstants.API.AUTH_CONT_KEY, {domain: getCookieDomain()});
            return new Promise((resolve, reject) => {
                const isEmployeeEntrance = service.getParamValue(window.location, userAuthConstants.API.AUTH_CONT_EMPLOYEE);
                if (isEmployeeEntrance === "1") {
                    reject();
                } else {
                    const value = $cookies.get(userAuthConstants.API.SPLIT_COOKIE);
                    if (value === 'yes') {
                        resolve();
                    } else {
                        reject();
                    }
                }
            });
        };

        return service;
    }
})();
