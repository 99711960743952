(function () {
    'use strict';

    angular.module('accountJourney').directive('journeyTabComponent', journeyTabComponent);

    journeyTabComponent.$inject = ['c3ChartManager'];

    function journeyTabComponent(c3ChartManager) {
        return {
            controller: 'journeyTabController',
            controllerAs: 'journeyCtrl',
            restrict: 'E',
            template: require('./journey-tab-component.html'),
            link: function (scope, element, attr) {
                c3ChartManager.startObservingChartDirtyState(attr, element);
            }
        };
    }
})();
