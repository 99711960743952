(function () {
    'use strict';

    angular.module('accountJourney').controller('peopleTabActivityController', peopleTabActivityController);

    peopleTabActivityController.$inject = ['peopleTabService', '$scope', 'accountJourneyService'];

    function peopleTabActivityController(peopleTabService, $scope, accountJourneyService) {
        var self = this;

        self.displayedCollection = [];
        accountJourneyService.getData().then(function(data){
            self.rowCollection = peopleTabService.getPeopleDetails(data, $scope.row);
        });
    }
})();

