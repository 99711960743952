/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').directive('accountJourneyComponent', accountJourneyComponent);

    accountJourneyComponent.$inject = ['$timeout', '$window', 'c3ChartManager'];

    function accountJourneyComponent($timeout, $window, c3ChartManager) {
        return {
            restrict: 'E',
            template: require('./account-journey-component.html'),
            controller: 'accountJourneyController',
            controllerAs: 'acctJourneyCtrl',
            link : function(scope, element) {
                var debounced = _.debounce(handleWindowResize, 300);

                angular.element($window).on('resize', debounced);

                scope.$on('$destroy', function() {
                    angular.element($window).off('resize', debounced);
                });

                function handleWindowResize() {
                    setChartDirtyFlags();

                    if(scope.activeTab === 3) {
                        var chartElements = element.find('journey-tab-component .chart-container');
                        c3ChartManager.renderChart(angular.element(chartElements[0]).scope());
                    }
                }

                function setChartDirtyFlags() {
                    scope.insightChartsDirty = true;
                    scope.journeyChartsDirty = true;
                }
            }
        };
    }
})();

