(function () {
    'use strict';

    angular.module('accountJourney').service('tooltipTemplateService',tooltipTemplateService);

    tooltipTemplateService.$inject = ['$filter'];

    function tooltipTemplateService() {
        var service = {};

        service.generateCampaignTemplate = function (data, sfdcUrl) {
            var template = '';

            Object.keys(data).forEach(function (key) {
                var campaign = data[key].campaign,
                    contacts = data[key].contacts,
                    people = '',
                    noDisplayName = 'None';

                contacts.forEach(function (contact, index) {
                    if (contact.name) {
                        people += "<a href='" + sfdcUrl + '/' + contact.id + "'> " + contact.name + '</a>';
                    } else {
                        people += '<span>' + noDisplayName + '</span>';
                    }

                    if (index !== contacts.length - 1) {
                        people += ', ';
                    }
                });

                template += '<div class="journey-tab-tooltip">';
                template += '<div class="campaign-data tooltip-card-container"><div class="details-container"><span class="text-limit tooltip-card-title"><span><a href="' +
                    sfdcUrl + '/' + campaign.id +
                    '">' + campaign.name +
                    '</a></span></span> <span class="align-right">';
                if (contacts.length > 1) {
                    template += contacts.length + ' People';
                }
                template += '</span></div>';

                template += '<div class="align-left"><span class="text-content-container"><b class="card-subtitle">People: </b><span class="text-limit"><span>';
                template += people;

                template += '</span></span></span></div></div></div>';
            });

            return template;
        };

        service.generateEngagementTemplate = function (data) {
            var engagement = data.models;
            var template = '<div class="journey-tab-tooltip">';
            template += '<div class="tooltip-card-container summary-margin"><div class="details-container"><span class="tooltip-card-title">Engagement Spike</span><span class="align-right">';
            if (engagement.length > 1) {
                template +=  engagement.length + ' Models';
            }
            template += '</span></div>';
            template += '<div class="align-left text-limit"><span>' +
                engagement.join(', ') + '</span></div>';

            return template + '</div></div>';
        };

        service.generateIntentTemplate = function (data) {
            var intent = data.topics;
            var template = '<div class="journey-tab-tooltip">';
            template += '<div class="tooltip-card-container summary-margin"><div class="details-container"><span class="tooltip-card-title">Bombora Intent Surge</span><span class="align-right">';
            if (intent.length > 1) {
                template += intent.length + ' Topics';
            }
            template += '</span></div>';
            template += '<div class="align-left text-limit"><span>' + intent.join(', ') +
                '</span></div>';

            return template + '</div></div>';
        };

        service.generateSummaryTemplate = function (data, nameFormat, titleFormat, valueFormat) {
            var name, template, title, value;

            for (var i = 0; i < data.length; i++) {
                if (!(data[i] && (data[i].value || data[i].value === 0))) { continue; }

                if (!template) {
                    title = titleFormat ? titleFormat(data[i].x) : data[i].x;
                    template = '<div class="journey-tab-tooltip"><div class="summary-container summary-margin"><div class="details-container"><span class="tooltip-card-title"> Week of ' +
                        title + '</span></div>';
                    template += '<div class="summary-data-row">';
                }

                name = nameFormat ? nameFormat(data[i].name) : data[i].name;
                value = valueFormat ? valueFormat(data[i].value, data[i].ratio, data[i].id, data[i].index) : data[i].value;

                template += '<div class="summary-data-item">';
                template += '<span><b>' + name + '</b></span>';
                template += '<span>' + value + '</span>';
                template += '</div>';
            }

            return template += '</div></div></div>';
        };

        service.generateTaskTemplate = function (data, sfdcUrl) {
            var template = '';

            Object.keys(data).forEach(function (key) {
                var people = Object.keys(data[key].people),
                    noDisplayName = 'None',
                    subject = data[key].subject,
                    tasks = '',
                    total = 0;

                people.forEach(function (leadId, index) {
                    var person = data[key]['people'][leadId];
                    if (person.name) {
                        tasks += '<a href="' +
                            sfdcUrl + '/' + person.leadId +
                            '">' + person.name + '</a>';
                    } else {
                        tasks += '<span>' + noDisplayName + '</span>';
                    }

                    if (index !== people.length - 1) {
                        tasks += ', ';
                    }
                    total += person.count;
                });

                template += '<div class="journey-tab-tooltip" >';
                template += '<div class="tooltip-card-container task-data"><div class="details-container"><span class="text-limit tooltip-card-title"><span>' +
                    subject + '</span></span><span class="align-right">';
                if (total > 1) {
                    template += total + ' Tasks';
                }
                template += '</span></div>';
                template += '<div class="align-left"><span class="text-content-container"><b class="card-subtitle">People: </b><span class="text-limit"><span>';
                template += tasks;
                template += '</span></span></span></div></div></div>';
            });

            return template;
        };

        return service;
    }
})();
