/**
 * Adds the auth service to the config
 */
(function() {
    'use strict';

    angular
        .module('jwt')
        .config(['$httpProvider', function($httpProvider) {
            $httpProvider.interceptors.push([ '$injector', function($injector) {
                return {
                    /**
                     * Intercept the request
                     * @param config The Request config
                     * @return {Object} The object with the http config
                     */
                    request: function (config) {
                        $injector.get('jwtInterceptor').addJWT(config);
                        return config;
                    },
                    /**
                     * In case of a 401 error redirect to login.
                     * @param rejection The reason for the rejection.
                     * @return {Promise<*>} The Promise with the rejection reason.
                     */
                    responseError: function (rejection) {
                        return $injector.get('jwtInterceptor').removeJWT(rejection);
                    }
                };
            }]);
        }]);
}());
