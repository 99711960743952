/* eslint-disable no-undef */
(function (w, bf) {
    'use strict';

    angular.module('accountJourney').service('accountJourneyService', accountJourneyService);

    accountJourneyService.$inject = ['api', 'AccountJourneyConstants', '$q', '$state', '$rootScope'];

    function accountJourneyService(api, AccountJourneyConstants, $q, $state, $rootScope) {
        var service = {};
        var cachedData;
        var initialFetchPromise;

        /**
         * Check if there was an error in the request and throw an Exception if that was the case.
         *
         * @param data The response or null
         */
        function checkError(data) {
            if (!data || data['info'].status !== 200) {
                service.reset();
                if ($state.href('login')) {
                    $state.go('login');
                }
                throw 'Missing params';
            }
        }

        function logStandalone() {
            const urlParams = new URLSearchParams(window.location.search);
            const email = urlParams.get('email');
            const accountId = $state.params.accountId;
            const logParams = {accountId: accountId, email: email};

            if(service.isStandaloneApp()) {
                api.getter([{
                    url: '/account/log_sfdc_use',
                    params: logParams,
                    key: 'log',
                    paramsNotToUpdate: 'all'
                }]);
            }
        }

        service.getData = function () {
            var accountId = $state.params.accountId;
            var sdfcId = $state.params.userId;
            var accountJourneyParams = {cohort: 'days90', accountId: accountId, excludeOpptyTaskCount: true};

            if (sdfcId) {
                accountJourneyParams.userId = sdfcId;
            }

            if (cachedData) {
                return $q.when(cachedData);
            } else if (!initialFetchPromise) {
                // Set loading state to true for the progress bar to display
                // It is hidden when loading is completed
                $state.current.data.loading = true;

                logStandalone();

                initialFetchPromise = api.getter([{
                    url: '/account/load_info',
                    params: accountJourneyParams,
                    key: 'info',
                    paramsNotToUpdate: 'all'
                }, {
                    url: '/account/load_opportunities',
                    params: accountJourneyParams,
                    key: 'oppty',
                    paramsNotToUpdate: 'all'
                }, {
                    url: '/account/load_sales_data',
                    params: accountJourneyParams,
                    key: 'sales',
                    paramsNotToUpdate: 'all'
                }, {
                    url: '/account/load_touches',
                    params: accountJourneyParams,
                    key: 'touches',
                    paramsNotToUpdate: 'all'
                }, {
                    url: '/account/load_people',
                    params: accountJourneyParams,
                    key: 'people',
                    paramsNotToUpdate: 'all'
                }, {
                    url: '/account/load_anonymous_web',
                    params: accountJourneyParams,
                    key: 'anonymous',
                    paramsNotToUpdate: 'all'
                }, {
                    url: '/account/load_oppty_history',
                    params: accountJourneyParams,
                    key: 'oppty_history',
                    paramsNotToUpdate: 'all'
                }, {
                    url: '/account/spikeDataEnabled',
                    params: accountJourneyParams,
                    key: 'spike_feature',
                    paramsNotToUpdate: 'all'
                }]).then(function (data) {
                    $state.current.data.loading = false;
                    checkError(data);
                    cachedData = data;
                    $state.current.data.title = data['info'].data.name;
                    return data;
                }, function () {
                    $state.current.data.loading = false;
                    checkError();
                });
            }
            return initialFetchPromise;
        };

        service.reset = function () {
            cachedData = null;
            initialFetchPromise = null;
        };

        /**
         * Check if a single call succeeded and return the data and reject the promise if the call failed.
         *
         * @param response The response object
         * @param key The key in the response
         * @param fn The function to evaluate to return a valid result
         * @returns {*} The expected value or throw an exception to reject the promise.
         */
        function checkPromiseError(response, key, fn) {
            var update = response[key];
            if (update.status === 200) {
                return fn();
            } else {
                throw 'Network error: ' + update.status;
            }
        }

        /**
         * Get the SFDC url for this organization
         *
         * @returns {Promise<*>} A promise resolved with the SFDC Url or rejected if an
         *  error occurs
         */
        service.getSFDCURL = function () {
            // Get the url for salesforce and set it in the scope
            return api.getter([{
                url: AccountJourneyConstants.unauthorized.API.CRM_URL,
                key: AccountJourneyConstants.unauthorized.API.SFDC_RESPONSE
            }]).then(function (response) {
                return checkPromiseError(response,
                    AccountJourneyConstants.unauthorized.API.SFDC_RESPONSE,
                    function () {
                        return response[AccountJourneyConstants.unauthorized.API.SFDC_RESPONSE].data;
                    });
            }, function (error) {
                throw error;
            });
        };

        /**
         * Get the last update from the backend
         *
         * @returns {Promise<*>} A promise resolved with the last data update or rejected if an
         *  error occurs
         */
        service.getLastUpdate = function () {
            return api.getter([{
                url: AccountJourneyConstants.header.API.LAST_UPDATE_URL,
                params: {},
                key: AccountJourneyConstants.header.API.LAST_UPDATE_RESPONSE
            }]).then(function (response) {
                return checkPromiseError(response,
                    AccountJourneyConstants.header.API.LAST_UPDATE_RESPONSE,
                    function () {
                        var date = response[AccountJourneyConstants.header.API.LAST_UPDATE_RESPONSE]
                            .data.last_update;
                        return new Date(date).toUTCString();
                    });

            }, function (error) {
                throw error;
            });
        };

        /**
         * Check if we are running standalone. That is true if we have define standalone to be true in the
         * `run` method of the application.
         *
         * @returns {boolean} True if the app is running standalone. False otherwise.
         */
        service.isStandaloneApp = function () {
            return w[bf].standalone;
        };

        service.intentFeatureEnabled = function() {
            return $rootScope.userData
                && $rootScope.userData.hiddenFeatures
                && $rootScope.userData.hiddenFeatures.route_access
                && $rootScope.userData.hiddenFeatures.route_access['app.settings.selfConfigure.bombora'] === 'enabled';
        };

        service.spikeModelFeatureEnabled = function(data) {
            return data['spike_feature'].data.enabled;
        };

        service.mockSpikeModelData = function(data) {
            var columnData = ['AnalyticsPro', 'Application Delivery AI', 'IT Operations Suite', 'MyCloud Services'];
            return mockBarGraphData(columnData, data);
        };

        service.mockIntentData = function(data) {
            var columnData = ['Cloud Security', 'Internet Security', 'Analytics Software', 'Business Analytics'];
            return mockBarGraphData(columnData, data);
        };

        // Set mock intent data if feature is not enabled
        function mockBarGraphData(columnData) {
            var intentTopics = [];
            var startDate = moment('04/29/2019');
            var numberOfWeeks = 0;

            do {
                var topics = [];
                topics.push(columnData[0]);
                if(numberOfWeeks < 8) {
                    topics.push(columnData[1]);
                }
                if(numberOfWeeks < 4) {
                    topics.push(columnData[2]);
                }
                if(numberOfWeeks < 2) {
                    topics.push(columnData[3]);
                }
                intentTopics.push({
                    week_of_year: startDate.format('YYYY ww'),
                    topics: topics,
                    date: startDate.format('MM/DD/YYYY')
                });
                startDate = startDate.subtract(1, 'weeks');
            } while(++numberOfWeeks < 11);
            return intentTopics;
        }

        service.__testonly__ = {
            getCachedData : function() {
                return cachedData;
            },
            getInitialFetchPromise: function() {
                return initialFetchPromise;
            }
        };

        return service;
    }
})(window, 'brightfunnel');
