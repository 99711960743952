/* eslint-disable no-empty */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
app.directive('bfFooter', ['$compile', '$timeout', '$state', function ($compile, $timeout, $state) {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        templateUrl: 'bf-footer.html',
        priority: 1000,
        link: function (scope, elem, attrs, ctrl, transclude) {
            var s = scope,
                content = elem.find('.collapsable-footer-content'),
                target = elem.find('.collapsable-footer-content-target');

            s.footer = {
                open: true,
                header: true
            };

            if (attrs.footerTitle === 'false') {
                s.footer.title = false;
            } else if (attrs.footerTitle) {
                s.footer.title = scope.$eval(attrs.footerTitle);
                scope.$watch(attrs.footerTitle, function (nv, ov) {
                    if (nv != ov) {
                        s.footer.title = nv;
                    }
                });
            } else {
                s.footer.title = $state.current.data.title + ' Data';
            }

            attrs.duration = !attrs.duration ? '.25s' : attrs.duration;
            attrs.easing = !attrs.easing ? 'linear' : attrs.easing;

            content.css({
                'height': '0px',
                'transitionProperty': 'height',
                'transitionDuration': attrs.duration,
                'transitionTimingFunction': attrs.easing
            });

            s.$watch('footer.open', function (nv) {
                var y;
                if (!nv) {
                    y = target.height() + 109;
                    content.css({
                        'height': y + 'px'
                    });
                    content.addClass('collapsable-open');
                    scope.$broadcast('bfFooterOpening');
                    $timeout(function () {
                        content.css({
                            'height': 'auto'
                        });
                    }, parseFloat(attrs.duration) * 1000, false);
                } else {
                    y = target.height() + 109;
                    content.css({
                        'height': y + 'px'
                    });
                    $timeout(function () {
                        content.css({
                            'height': '0px'
                        });
                        content.removeClass('collapsable-open');
                        scope.$broadcast('bfFooterClosing');
                    }, 0, false);
                }
            });
        }
    };
}]);

app.directive('bfConfig', ['$state', '$rootScope', function ($state, $rootScope) {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        templateUrl: 'bf-config.html',
        link: function (scope, elem, attrs) {
            $state.current.data.config = true;
            $state.current.data.configOpen = false;
            $rootScope.$broadcast('$configCheck');
        }
    };
}]);

app.directive('bfDetails', [function () {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {
            header: '=',
            color: '=',
            textTransform: "="
        },
        templateUrl: 'bf-details.html',
        link: function (scope, elem, attrs) {
            if (!scope.color)
                elem.find('.bf-detail-header').css('backgroundColor', '#999999');
            if (scope.textTransform) {
                elem.find('.bf-detail-header').css('text-transform', scope.textTransform);
            }
        }
    };
}]);

app.directive('tableFooterTrigger', [function () {
    return {
        restrict: 'C',
        link: function (scope, elem, attrs) {
            var parent = elem.parents('tr'),
                footer = parent.find('.table-footer'),
                tdp = parseInt(elem.parents('td').css('padding')),
                tp = parseInt(footer.css('padding'));

            function toggleFooter(e) {
                e.stopPropagation();
                var h = footer ? footer.outerHeight() : 0;

                scope.open = !scope.open;

                if (footer) {

                    if (scope.open) {
                        parent.addClass('table-footer-open');
                        elem.parents('td').css('paddingBottom', parseInt(elem.parents('td').css('padding')) + h);
                        footer.css('marginTop', tdp + tp);
                    } else {
                        parent.removeClass('table-footer-open');
                        elem.parents('td').removeAttr('style');
                    }
                }
            }

            elem.click(toggleFooter);

            scope.$on('$destroy', function () {
                elem.unbind('click', toggleFooter);
            });
        }
    };
}]);

app.directive('validityRule', ['$http', '$compile', function ($http, $compile) {
    return {
        //default is 'A'
        restrict: 'E',
        //scope: {},
        replace: true,
        template: require('../config/validity-rule.html'),
        link: function (scope, elem, attrs) { }
    };
}]);

app.directive('validitySummary', [function () {
    return {
        //default is 'A'
        restrict: 'E',
        replace: true,
        template: require('../config/validity-summary.html'),
        link: function (scope, elem, attrs) { }
    };
}]);

app.directive('sparkBarsGroup', ['_', function (_) {
    return {
        //default is 'A'
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {},
        templateUrl: 'spark-bars-group.html',
        controller: function ($scope, $element, $attrs) {
            $scope.textLength = 0;
            $scope.calloutLength = 0;
            $scope.legend = [];
            $scope.hideLegend = $attrs.hideLegend;

            $scope.$on('newBar', function (e, data) {
                var flat = $scope.legend.map(function (d) {
                    return d.label;
                });
                if (!_.contains(flat, data.label)) {
                    $scope.legend.push(data);
                }
            });

            $scope.$on('textSize', function (e, data) {
                if (data.size > $scope.textLength) {
                    $scope.textLength = data.size;
                }
                if (data.diffSize > $scope.calloutLength) {
                    $scope.calloutLength = data.diffSize;
                }
                $scope.$broadcast('adjustSize', {
                    size: $scope.textLength,
                    calloutSize: $scope.calloutLength
                });
            });
        }
    };
}]);

app.directive('sparkBars', [function () {
    return {
        //default is 'A'
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {
            label: '=',
            callout: '=',
            flipPositive: '=',
            hideArrow: '=',
            tooltip: '@tip'
        },
        templateUrl: 'spark-bars.html',
        link: function (scope, elem, attrs) {
            scope.columnWidth = 0;
            scope.parse = function (perc) {
                return parseFloat(perc);
            };

            scope.$watch('[label,callout]', function (nv, ov) {
                scope.$emit('textSize', {
                    size: scope.label.length,
                    diffSize: scope.callout.length
                });
            });

            scope.$on('adjustSize', function (e, data) {
                var ts = parseInt(elem.find('.spark-bars-label').css('font-size')) * 0.65,
                    min = (data.calloutSize * 25) + (scope.hideArrow ? 0 : 30);

                scope.columnWidth = ts * data.size < min ? min : ts * data.size;
            });
        }
    };
}]);

app.directive('sparkBar', [function () {
    return {
        //default is 'A'
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {
            color: '=',
            value: '=',
            total: '=',
            name: '=',
            filter: '@',
            unit: '@'
        },
        templateUrl: 'spark-bar.html',
        link: function (scope, elem, attrs) {
            scope.$emit('newBar', {
                label: scope.name,
                color: scope.color
            });

            if (!scope.filter) {
                scope.filter = 'number:0';
            }
        }
    };
}]);

app.directive('stItemsByPageAuto', ['$uibTooltip', '$compile', '$timeout', '$filter', 'stConfig', '$parse', '$log', function ($uibTooltip, $compile, $timeout, $filter, stConfig, $parse, $log) {
    return {
        //default is 'A'
        restrict: 'A',
        require: '^stTable',
        link: function (scope, elem, attr, ctrl) {
            if (!attr.stItemsByPage) {
                $log.error('attribut "st-items-per-page" is required for this directive to work.');
                return;
            }

            var numItems = $parse(attr.stItemsByPage),
                table = ctrl.tableState(),
                pagination = table.pagination,
                parent = elem.parents('[st-table]');

            if (numItems.literal) {
                $log.error('attribut "st-items-per-page" must be an assignable value in scope.');
                return;
            }

            //------------ adjust the number of pages in view -------------//
            function newSet(num) {
                var set = (num < stConfig.pagination.itemsByPage ? stConfig.pagination.itemsByPage : num);
                numItems.assign(scope, set);
            }

            //------------ try to figure out the number of rows possible within the viewable area -------------//
            function calc() {
                var t = parent.offset().top,
                    tb = t + parent.find('table').height(),
                    cb = elem.parents('#bottom-right').offset().top + elem.parents('#bottom-right').outerHeight(),
                    thh = parent.find('table thead').outerHeight(),
                    spaceAfter = cb - tb,
                    row = parent.find('tbody tr'),
                    wh = $(window).height(),
                    avg = 0;

                row.each(function (i, r) {
                    var h = $(r).outerHeight();
                    if (h > avg) {
                        avg = h;
                    }
                });

                var numRows = Math.floor((wh - t - thh - spaceAfter - 30) / avg);
                scope.$apply(function () {
                    newSet(numRows);
                });
            }

            //------------ do calculation on init load or if the original dataset changes -------------//
            if (parent.attr('st-safe-src')) {
                scope.$watch(parent.attr('st-safe-src'), function (nv, ov) {
                    if (nv !== ov) {
                        $timeout(function () {
                            calc();
                        }, 0, false);
                    }
                });
            }

            //------------ do calculation if the window size changes -------------//
            scope.$on('$windowResizeEnd', calc);
        }
    };
}]);

app.directive('stSelectChange', ['$parse', '$filter', function ($parse, $filter) {
    return {
        //default is 'A'
        restrict: 'A',
        require: '^stTable',
        link: function (scope, elem, attr, ctrl) {
            if (attr.stSelectChange) {
                scope.$evalAsync(function () {
                    var getter = $parse(attr.stSelectChange);

                    scope.$watch(attr.stSelectRow + '.isSelected', function (nv, ov) {
                        if (nv !== undefined) {
                            ctrl.tableState().selected = $filter('filter')(ctrl.getFilteredCollection(), { isSelected: true });
                            getter(scope, {
                                $selected: scope[attr.stSelectRow],
                                $allSelected: ctrl.tableState().selected
                            });
                        }
                    });
                });
            }
        }
    };
}]);

app.directive('stSelectAll', ['$compile', '$timeout', 'stConfig', function ($compile, $timeout, stConfig) {
    return {
        //default is 'A'
        restrict: 'A',
        require: '^stTable',
        link: function (scope, elem, attr, ctrl) {
            if (!attr.stSelectRow) {
                return;
            }

            scope.$evalAsync(function () {
                const parent = elem.parents('table');
                const ps = angular.element(parent).scope();
                const hrow = parent.find('thead tr');
                const mode = attr.stSelectMode || stConfig.select.mode;
                const hasAll = parent.find('.select-all-button').length;
                const htemplate = $(`
                    <th class="select-all-button relative text-center" width="38px">
                      <label class="hub-checkbox">
                        <input type="checkbox"
                            ng-change="selectAll()"
                            ng-model="selectedAll"
                            ng-checked="selectedAll">
                        <span class="icon-check"></span>
                      </label>
                    </th>`);
                const btemplate = $(`
                    <td class="relative">
                      <label class="hub-checkbox">
                        <input type="checkbox"
                            ng-model="${attr.stSelectRow}.isSelected"
                            ng-checked="${attr.stSelectRow}.isSelected" >
                        <span class="icon-check"></span>
                      </label>
                    </td>`);

                btemplate.bind('click', function (e) {
                    e.stopPropagation();
                });
                if (elem.hasClass('collapse-init')) {
                    elem.find('.fas fa-plus-square').parent().after($compile(btemplate)(scope));
                } else {
                    if (elem.next('tr').hasClass('row-collapsable')) {
                        elem.prepend($compile(btemplate)(scope));
                    } else {
                        elem.prepend($compile(btemplate)(scope));
                        if (attr.stSelectAll !== "0") {
                            elem.prepend('<td></td>');
                        }
                    }
                }

                elem.addClass('select-init');

                if (!hasAll) {
                    var s = ps.$new(true);

                    s.data = ctrl.getFilteredCollection();
                    s.firstClick = true;
                    s.selectedAll = false;

                    s.listen = s.$watch(function () {
                        return ctrl.getFilteredCollection();
                    }, function (nv, ov) {
                        if (nv && nv !== ov) {
                            s.data = nv;
                        }
                    });

                    s.selectAll = function () {
                        angular.forEach(s.data, function (row, i) {
                            row.isSelected = s.selectedAll;
                        });
                    };

                    if (!hrow.find('.select-all-button').length) {
                        if (hrow.find('.icon-webtracking').length) {
                            hrow.find('.icon-webtracking').parent().after($compile(htemplate)(s));
                        } else {
                            hrow.prepend($compile(htemplate)(s));
                        }
                    }
                }
            });
        }
    };
}]);

app.directive('exportCsv', ['$timeout', '$compile', '$parse', '$q', '$filter', '$rootScope', '_',
    '$location', 'api', 'authSrvc',
    function ($timeout, $compile, $parse, $q, $filter, $rootScope, _,
        $location, api, authSrvc) {
        return {
            //default is 'A'
            restrict: 'A',
            require: '^stTable',
            scope: true,
            link: function (scope, elem, attr, ctrl) {
                var manual = attr.exportCsv,
                    table = elem.parents('[st-table]:first'),
                    ngCsv,
                    csvHeader,
                    s = scope.$new(),
                    extras = attr.exportCsvExtras ? scope.$eval(attr.exportCsvExtras) : null,
                    masterHeaders,
                    lazy = attr.lazyLoad,
                    streaming;

                function colHeaderToDisplay(oldColName) {
                    // place any column mapping here for display
                    var colHeaderMap = {
                        "id": "Id",
                        "lead_id": "LeadOrContactId"
                    };

                    return colHeaderMap[oldColName] ? colHeaderMap[oldColName] : oldColName;
                }

                function calcRows(data, th, cells, headers) {
                    th.each(function () {
                        var field = $(this).attr('st-export'),
                            getter = $parse(field),
                            filter = $(this).hasAttr('st-export-filter') ? $(this).attr('st-export-filter') : false,
                            intervalFilter = $(this).hasAttr('st-export-interval-filter') ? $(this).attr('st-export-interval-filter') : false,
                            interval = $(this).hasAttr('st-export-interval') ? $(this).attr('st-export-interval') : false,
                            v;

                        if (_.contains(field, '(') && _.contains(field, ')')) {
                            v = getter(angular.element(this).scope(), {
                                $row: data
                            });
                        } else {
                            v = getter(data);
                        }

                        if (v !== undefined && v !== null) {
                            if (filter) {
                                v = $filter('metafilter')(v, filter);
                            } else if (intervalFilter) {
                                v = $filter('bfDateInterval')(v, data, interval);
                            }
                            cells.push(v);
                        } else {
                            cells.push('null');
                        }
                    });

                    if (extras && angular.isObject(extras)) {
                        angular.forEach(extras, function (field, label) {
                            try {
                                var getter = $parse(field);
                                cells.push(getter(data));
                            } catch (e) {
                                _.remove(headers, function (n) {
                                    return n === label;
                                });
                                delete extras[label];
                            }
                        });
                    }

                    return cells;
                }

                //------------------------ do we need to generate the csv from the table or do you provide us with a function? ------------------------//
                if (!manual) {
                    csvHeader = 'getHeaders();';
                    ngCsv = 'getRows();';

                    s.getHeaders = function () {
                        $rootScope.$broadcast('exportData', "csv", { info: "from ui" });
                        return masterHeaders;
                    };

                    s.getRows = function () {
                        var d = table.hasAttr('st-pipe') ? $parse(table.attr('st-table'))(scope) : ctrl.getFilteredCollection(),
                            headers = [],
                            rows = [],
                            totalValues = [],
                            th = table.find('thead:first th[st-export]'),
                            tr = table.find('tbody:first tr[st-export]:visible'),
                            totals = table.find('tr[st-export-totals]');

                        //------------------------ get all the headers here because this function fires first ------------------------//
                        if (!th.length && tr.length) {
                            th = table.find('thead:first tr:last th:visible');
                        }

                        th.each(function () {
                            var t = $(this).hasAttr('st-export-value') ? $(this).attr('st-export-value') : $(this).text().trim();
                            headers.push(t);
                        });

                        //grab anything that looks like an ID
                        findIds(d[0], headers);

                        if (extras && angular.isObject(extras)) {
                            headers = headers.concat(Object.keys(extras));
                        }

                        if (headers) {
                            headers = headers.map(function (item) {
                                return colHeaderToDisplay(item);
                            });
                        }

                        masterHeaders = headers;

                        //------------------------ end getting headers ------------------------//

                        if (th.length && !tr.length) {
                            angular.forEach(d, function (row, i) {
                                var cells = [];
                                cells = calcRows(row, th, cells, headers);
                                rows.push(cells);
                            });

                            if (totals.length) {
                                var tester = totals.attr('st-export-totals').length;
                                if (tester) {
                                    var arr = [],
                                        parsed_data = $parse(table.find('tr[st-export-totals]').attr('st-export-totals'))(scope),
                                        y = calcRows(parsed_data, th, arr, headers);
                                    y[0] = 'Totals';
                                    totalValues.push(y);
                                }
                                else {
                                    table.find('tr[st-export-totals] td').each(function () {
                                        if ($(this).text().length) {
                                            var x = this.innerText;
                                            totalValues.push(x);
                                        }
                                    });
                                }
                            }

                        } else {
                            tr.each(function () {
                                var cells = [],
                                    field = $(this).attr('st-export'),
                                    getter = $parse(field),
                                    filter = $(this).hasAttr('st-export-filter') ? $(this).attr('st-export-filter') : false;

                                if ($(this).find('[st-export-value]').length) {
                                    $(this).find('[st-export-value]').each(function () {
                                        cells.push($(this).text().trim());
                                    });
                                } else {
                                    cells.push($(this).find('td:first').text().trim());
                                }

                                angular.forEach(d, function (cell, i) {
                                    var v;

                                    if (_.contains(field, '(') && _.contains(field, ')')) {
                                        v = getter(angular.element(this).scope(), {
                                            $cell: cell
                                        });
                                    } else {
                                        v = getter(cell);
                                    }

                                    if (v !== undefined && v !== null) {
                                        if (filter) {
                                            v = $filter('metafilter')(v, filter);
                                        }
                                        cells.push(v);
                                    } else {
                                        cells.push('null');
                                    }
                                });

                                rows.push(cells);
                            });
                        }

                        if (tester) {
                            rows = rows.concat(totalValues);
                        }
                        else {
                            rows = rows.concat([totalValues]);
                        }
                        rows = rows.concat([['     '], [$location.url()]]);
                        return rows;
                    };

                } else {
                    if (_.includes(manual, '{{')) { return; }

                    var raw = _.includes(manual, '()') ? manual.split('()')[0] : manual,
                        getter = $parse(raw);

                    if (angular.isFunction(getter(scope))) {
                        ngCsv = raw + '()';
                        csvHeader = attr.exportCsvHeader;
                        lazy = true;
                    } else {
                        streaming = true;
                    }
                }

                scope.calcMargin = function () {
                    if (!elem.parents('table').length) {
                        return;
                    }
                    var pleft = Math.abs(elem.position().left),
                        w = (elem.width() - pleft) - table.width();
                    return (w < 0 ? 0 : w) + 'px';
                };

                function downloadCSV(url) {
                    api.getter({ url: "authenticate/createExportToken", paramsNotToUpdate: "all", skipFilters: true })
                        .then(function (response) {
                            for (var key in response.data) {
                                url += "&" + key + "=" + response.data[key];
                            }
                            url = authSrvc.getUrlToUse(url);
                            authSrvc.redirect(url);
                        });
                }

                var tpl = $(`<a class="btn btn-sm btn-default pull-right margin-top" ng-style="{marginRight:calcMargin()}"` + (streaming ? ` ng-csv-direct ng-click="downloadAndSendMetric(${manual})"` : (csvHeader ? `csv-header="${csvHeader}"` : '') + ` ng-csv="${ngCsv}"` + (lazy ? ' lazy-load="true"' : '') + ' filename="{{fileName || \'untitled.csv\'}}"') + '><i class="icon-download space-right inline-block align-middle"></i>CSV</a>');


                $timeout(function () {
                    elem.addClass('clearfix');
                    elem.append($compile(tpl)(s));
                }, 0, false);

                if (elem.parents('table').length) {
                    table.scroll(updateMargin);
                }

                scope.downloadAndSendMetric = function(url) {
                    downloadCSV(url);
                };

                function refreshScope() {
                    scope.$digest();
                }

                function updateMargin() {
                    $rootScope.$broadcast('updateCsvPosition');
                }

                function findIds(obj, headers) {

                    var additionalHeader = attr.stAdditionalHeader ? attr.stAdditionalHeader : null;
                    check(obj, [], additionalHeader);

                    function check(o, pth, additional_header) {
                        _.forOwn(o, function (value, field) {
                            if (angular.isObject(value)) {
                                pth.push(field);
                                check(value, pth, additional_header);
                            } else {
                                var isIdField = (field === 'id'
                                    || (additional_header && field === additional_header)
                                    || _.includesAny(field, ['Id', '_id', '_Id', 'id_']));

                                if (isIdField && !_.includes(headers, field) && value && value !== '--') {
                                    pth.push(field);
                                    if (!extras || !angular.isObject(extras)) {
                                        extras = {};
                                    }
                                    extras[pth.join('_')] = pth.join('.');
                                    pth.length = 0;
                                    return additional_header || false;
                                }
                            }
                        });
                    }
                }

                attr.$observe('exportCsvFilename', function (val) {
                    s.fileName = val;
                });

                scope.$on('$destroy', function () {
                    table.unbind('scroll', updateMargin);
                    s.$destroy();
                });

                scope.$on('$windowResizeEnd', refreshScope);

                scope.$on('updateCsvPosition', refreshScope);
            }
        };
    }]);

app.directive('stTableState', ['$parse', '_', function ($parse, _) {
    return {
        restrict: 'A',
        require: '^stTable',
        link: function (scope, elem, attrs, ctrl) {
            if (!attrs.stTableState) return;
            var getter = $parse(attrs.stTableState),
                setter = getter.assign,
                isFunction = angular.isFunction(getter(scope));

            scope.$on('stPiped', setState);

            setState();

            function setState() {
                var params = {
                    totalItems: ctrl.getFilteredCollection().length,
                    pagination: ctrl.tableState().pagination,
                    items: ctrl.getFilteredCollection(),
                    controller: ctrl
                };

                if (isFunction) {
                    getter(scope)(params);
                } else {
                    setter(scope, params);
                }
            }
        }
    };
}]);


app.directive('rowCollapsable', ['$timeout', '$compile', '$parse', function ($timeout, $compile, $parse) {
    return {
        restrict: 'C',
        scope: true,
        link: function (scope, elem, attrs) {
            if (elem[0].nodeName != 'TR') {
                return;
            }
            var onOpenFn = $parse(attrs.onOpen)(scope);
            var onCloseFn = $parse(attrs.onClose)(scope);
            scope.$expanded = false;
            scope.$evalAsync(function () {
                const tbl = elem.closest('table');
                const collapser = elem.prev('tr');
                const span = elem.find('> td');
                const header = tbl.find('thead:first');
                const toggleTpl = $('<td class="expand-toggle" ng-click="$event.stopPropagation();"></td>');
                const headerTpl = $('<th class="expand-placeholder" width="30">' + (attrs.rowCollapsableIcon ? '<i class="' + attrs.rowCollapsableIcon + ' icon-lg icon-inherit"' + (attrs.rowCollapsableTooltip ? ' uib-tooltip="' + attrs.rowCollapsableTooltip + '" tooltip-append-to-body="true" tooltip-placement="top"' : '') + '></i>' : '') + '</th>');
                const cls = 'row-expanded';
                const minusSquareIcon = $('<i class="fas fa-minus-square"></i>');
                const plusSquareIcon = $('<i class="fas fa-plus-square"></i>');

                if (!header.find('.expand-placeholder').length && !header.find('.icon-webtracking').length) {
                    header.find('> tr').prepend($compile(headerTpl)(scope));
                }

                if (!collapser.find('.expand-toggle').length) {
                    collapser.prepend(toggleTpl);
                }

                if (!collapser.find('.fas fa-plus-square').length) {
                    if (attrs.onOpen) {
                        //attached the function on the collapser instead of on the icon
                        //because the function that is attached to the collapser gets called
                        //ALWAYS gets called.
                        scope.close = close;
                        attachOnOpenClickHandler(collapser, scope);
                    }
                    toggleTpl.append(plusSquareIcon);
                }

                tbl.addClass('has-collapsable');
                collapser.addClass('collaps-init');
                collapser.addClass('pointer');

                collapser.click(toggleCollapse);

                scope.$on('$destroy', function () {
                    collapser.unbind('click', toggleCollapse);
                    collapser.unbind('click', onOpenFn);
                    collapser.unbind('click', onCloseFn);
                });

                function generateAutoCollapseFn(isolateScope) {
                    return function (currentRow) {
                        if (currentRow) {
                            currentRow.close(cls);
                        }
                        return isolateScope;
                    };
                }

                function open() {
                    var content = elem.find('> td').children().first();
                    if (content[0]) {
                        content.slideToggle();
                    } else {
                        $timeout(open, 0, false);
                    }
                    scope.$emit('rowExpanding', scope);
                }

                function close(c) {
                    var content = elem.find('> td').children().first();
                    content.slideUp(function () {
                        toggleTpl.html('');
                        var ic = $('<i class="fas fa-plus-square"></i>');
                        if (attrs.onOpen) {
                            collapser.unbind('click');
                            collapser.click(toggleCollapse);
                            scope.close = close;
                            attachOnOpenClickHandler(collapser, scope);
                        }
                        toggleTpl.append(ic);
                        elem.removeClass(c);
                        scope.$expanded = false;
                        scope.$apply();
                    });
                }

                function attachOnOpenClickHandler(el, scope) {
                    el.click({ autoCollapseFn: generateAutoCollapseFn(scope) }, onOpenFn);
                }

                function toggleCollapse(e) {
                    if (!span.hasAttr('colspan')) {
                        span.attr('colspan', collapser.find('td').length);
                    }
                    // span.css('padding-left', (tbl.find('.expand-placeholder').width() + 16) + 'px');
                    if (scope.$expanded) {
                        close(cls);
                    } else {
                        scope.$expanded = true;
                        elem.addClass(cls);
                        toggleTpl.html('');
                        if (attrs.onClose) {
                            collapser.unbind('click');
                            collapser.click(toggleCollapse);
                            collapser.click(onCloseFn);
                        }
                        toggleTpl.append(minusSquareIcon);
                        scope.$evalAsync(open);
                    }
                }

                scope.$on('closeAllRows', function () {
                    close(cls);
                });

                // if(attrs.onOpen) {
                // 	plusSquareIcon.click($parse(attrs.onOpen)(scope));
                // 	$('.expand-toggle').append(plusSquareIcon);
                // } else {
                // 	$('.expand-toggle').append(plusSquareIcon);
                // }

            });
        }
    };
}]);

app.directive('stTooltip', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (attrs.stTooltip) {
                scope.$evalAsync(function () {
                    var tpl = $('<i class="icon-question-circle space-left" uib-tooltip="' + attrs.stTooltip + '" tooltip-placement="right" tooltip-append-to-body="true"></i>');
                    elem.append($compile(tpl)(scope));
                });
            }
        }
    };
}]);

function getKey(column, key) {
    var k = key(column);
    if (!k) { throw Error('all columns must have a key'); }
    return k;
}

app.directive('stToggle', ['$parse', '$compile', '$timeout', '$templateCache', '_', '$rootScope', function ($parse, $compile, $timeout, $templateCache, _, $rootScope) {
    return {
        restrict: 'A',
        require: '^stTable',
        scope: true,
        link: function ($scope, $element, $attrs, ctrl) {
            if (!$attrs.stToggle || !$attrs.stToggleLabel || !$attrs.stToggleKey) {
                throw Error('"st-toggle, st-toggle-label, and st-toggle-key are all required"');
            }

            //------------------------ set up data ------------------------//
            var table = $element.parents('[st-table]:first'),
                togglePredicate = $attrs.stToggleVisible || '$visible',
                tempScope = $scope.$new(),
                listener = angular.noop,
                key = $parse($attrs.stToggleKey);

            $scope.offset = $attrs.stToggleOffset ? parseInt($attrs.stToggleOffset) : 0;
            $scope.columns = $parse($attrs.stToggle)($scope);
            $scope.attrs = $attrs;
            $scope.state = ctrl.tableState();


            // if(!$scope.columns) {
            // 	return;
            // } else if(!angular.isArray($scope.columns)) {
            // 	throw Error('"st-toggle must be an array used to iterate over column headers"');
            // }

            $templateCache.put('st-toggle.html', getTemplate());

            $element.removeAttr('st-toggle');
            //------------------------ set up popover ------------------------//
            $element.attr({
                'uib-popover-template': '\'st-toggle.html\'',
                'popover-placement': 'bottom',
                'popover-title': 'Toggle Table Columns',
                'tooltip-class': 'headers-toggle',
                'popover-append-to-body': 'true',
                'popover-trigger': '\'outsideClick\''
            });
            $compile($element)($scope);

            var triggerMove = _.throttle(function () {
                if ($('[title="Toggle Table Columns"]').length) {
                    $scope.$digest();
                }
            }, 50);

            $scope.init = function () {
                $timeout(function () {
                    $('input[ng-model="searchText"]').focus();
                    $scope.$digest();
                }, 0, false);

            };

            //------------------------ set up methods ------------------------//
            $scope.toggleColumn = function (column, i) {
                let visible = $scope.getVisible(column);

                if (visible === undefined || visible === 'always') {
                    visible = true;
                }

                return visible;
            };

            $scope.label = function (column) {
                var labelPredicate = $attrs.stToggleLabel,
                    labelGetter = $parse(labelPredicate);
                return labelGetter(column);
            };

            $scope.searchFun = function (criteria) {
                return function (val, i) {
                    if (criteria) {
                        return i > ($scope.offset - ($scope.offset ? 1 : 0)) && _.includes(_.toLower(val[$attrs.stToggleLabel]), _.toLower(criteria));
                    }
                    else {
                        return i > ($scope.offset - ($scope.offset ? 1 : 0));
                    }
                };
            };

            $scope.getVisible = function (column) {
                //we have to make a temp scope so that the $parse works when passing the column in
                tempScope.column = column;

                var getter = $parse('column.' + togglePredicate),
                    val = getter(tempScope);

                delete tempScope.column;

                return val;
            };

            $scope.setVisible = function (val, column) {
                //we have to make a temp scope so that the $parse works when passing the column in
                tempScope.column = column;

                var getter = $parse('column.' + togglePredicate),
                    setter = getter.assign;

                setter(tempScope, val);
            };

            $scope.maxHeight = function () {
                return $(window).height() - $('#toggleBody').offset().top - 20;
            };

            if (table.hasAttr('st-persist')) {
                table.hide();
                $scope.$on('tableStateLoaded', function (e, s) {
                    $scope.state = s;
                    listener();
                    if ($scope.state.columnsVisible) {
                        $scope.columns = $parse($attrs.stToggle)($scope);
                        if (angular.isObject($scope.state.columnsVisible)) {
                            angular.forEach($scope.columns, function (col, i) {
                                var k = getKey(col, key);
                                if ($scope.state.columnsVisible[k] !== null && $scope.state.columnsVisible[k] !== undefined) {
                                    $scope.setVisible($scope.state.columnsVisible[k], col);
                                }
                            });
                        } else {
                            delete $scope.state.columnsVisible;
                        }
                    }
                    listen();
                    table.show();
                });
            } else {
                listen();
            }

            function updateToggles(nv, ov) {
                if (nv && nv != ov) {
                    $scope.columns = nv;
                    var columnsVisible = {};
                    angular.forEach($scope.columns, function (column, i) {
                        var visible = $scope.toggleColumn(column, i);
                        columnsVisible[getKey(column, key)] = visible;
                    });
                    $scope.state.columnsVisible = columnsVisible;
                    $rootScope.$broadcast('changeTableState', angular.copy(ctrl.tableState()));
                    $rootScope.$broadcast('$$rebind::refreshHeaders');
                }
            }

            function getTemplate() {
                var tpl = '<input class="primary-input somemargin-bottom" type="text" placeholder="Search by table column" ng-model="searchText"/>' +
                    '<div id="toggleBody" ng-style="{maxHeight:maxHeight()}" style="overflow:auto;min-height:100px;" ng-init="init()">' +
                    '<ul class="list-group">' +
                    '<li ng-repeat="column in columns | filter:searchFun(searchText)" class="list-group-item clearfix">' +
                    '<span ng-bind="label(column) || \'column \' + ($index + 1)" class="inline-block align-middle margin-right ellipsis" style="width:185px"></span><toggle-switch class="pull-right switch-small switch-success" model="column.' + togglePredicate + '" on-fulabel="<i class=\'icon-check\'></i>" off-label="<i class=\'icon-times\'></i>" html="true"><toggle-switch>' +
                    '</li>' +
                    '</ul>' +
                    '</div>';
                return tpl;
            }

            function listen() {
                //------------------------ watch the collection for changes in the $index or visibility ------------------------//
                updateToggles($parse($attrs.stToggle)($scope), null);
                listener = $scope.$watch($attrs.stToggle, updateToggles, true);
                $('#content').scroll(triggerMove);
            }

            $scope.$on('$destroy', function () {
                $('#content').unbind('scroll', triggerMove);
                tempScope.$destroy();
            });
        }
    };
}]);


app.directive('stPersist', ['$localStorage', '$state', '$timeout', '$parse', '$q', '_', function ($storage, $state, $timeout, $parse, $q, _) {
    return {
        require: '^stTable',
        link: function (scope, element, attr, ctrl) {
            var nameSpace = attr.stPersist,
                location = $state.current.name,
                headers,
                cols,
                collection;

            // handle communicating tables in campaigns list, change location to be parent state name
            if ($state.current.data.parent === 'app.analyze.campaigns.listAnalysis' || $state.current.data.parent === 'app.analyze.webTracking.webActivity') {
                location = $state.current.data.parent;
            }

            if (!$storage.tableStates) {
                $storage.tableStates = {};
            }

            if (!$storage.tableStates[location]) {
                $storage.tableStates[location] = {};
            }

            function parseRepeater(scope, str, noEval) {
                var repeatExpression = str,
                    match;

                if (!repeatExpression) {
                    return;
                }

                match = repeatExpression.match(/^(.*\sin).(\S*)/);
                if (!match) { return; }
                return noEval ? match[2] : scope.$eval(match[2]);
            }

            //get the collection from the ng-repeat comment nodeType and set the column order property on tableState
            function getCollection() {
                //loop through headers (only ng-repeats), save DOM nodes into array of headers
                headers = $(element).find('thead tr').contents().filter(function () {
                    return (this.nodeType === 8 && (this.nodeValue.indexOf('ngRepeat') > 0));
                });
                if (headers) {
                    //loop through ng-repeat domNodes and save column values to cols array
                    angular.forEach(headers, function (comm) {
                        cols = parseRepeater(scope, comm.nodeValue);
                        collection = parseRepeater(scope, comm.nodeValue, true);
                        if (cols && collection) { return; }
                    });
                }

                //add the original indexes and watch the collection, changing the column order prop every time
                //the collection changes
                if (cols && attr.stPersistOrderKey) {
                    var key = $parse(attr.stPersistOrderKey),
                        first = getKey(cols[0], key);

                    scope.$watchCollection(function () {
                        return cols;
                    }, function (nv, ov) {
                        if (nv) {
                            var order = {};
                            angular.forEach(nv, function (col, i) {
                                order[getKey(col, key)] = i;
                            });
                            ctrl.tableState().columnOrder = order;
                        }
                    });
                }
            }

            /* Reverting St-Persist Column Order */
            // //set timeout to get ng-repeat, need to wait for the table to render
            // $timeout(function() {
            // 	getCollection();
            // }, 0, false);

            //save the table state every time it changes
            scope.$watch(function () {
                return ctrl.tableState();
            }, function (newValue, oldValue) {
                nameSpace = attr.stPersist;
                if (newValue !== oldValue) { $storage.tableStates[location][nameSpace] = angular.copy(newValue); }
                // getCollection(); /* Reverting St-Persist Column Order */
                loadSaved(nameSpace);
            }, true);

            //fetch the table state when the directive is loaded
            $timeout(function () {
                loadSaved(attr.stPersist).then(function () {
                    attr.$observe('stPersist', function (nv) {
                        getCollection();
                        loadSaved(nv);
                    });
                });
            }, 0, false);

            //load saved table if it exists, extending it with the tableState
            function loadSaved(nv, ov) {
                nameSpace = nv;
                var defer = $q.defer(),
                    tableState = ctrl.tableState();

                if (nameSpace && $storage.tableStates[location][nameSpace]) {
                    var savedState = $storage.tableStates[location][nameSpace];
                    delete savedState.selected;

                    /* Reverting St-Persist Column Order */

                    // if(cols && savedState.columnOrder && angular.isObject(savedState.columnOrder) && attr.stPersistOrderKey) {
                    // 	var key = $parse(attr.stPersistOrderKey),
                    // 		first = getKey(cols[0], key),
                    // 		second = getKey(cols[1], key);

                    // 	//sort the cols based on column order
                    // 	cols.sort(function(a,b) {
                    // 		var aii = savedState.columnOrder[getKey(a,key)],
                    // 			bii = savedState.columnOrder[getKey(b,key)];
                    // 		    if(aii === bii) return 0;
                    // 		    return aii > bii ? 1 : -1;
                    // 	});

                    // 	var fi = _.findIndex(cols, function (col) {
                    // 		return getKey(col, key) === first;
                    // 	});

                    // 	var se = _.findIndex(cols, function (col) {
                    // 		return getKey(col, key) === second;
                    // 	});

                    // 	if (fi !== 0) {
                    // 		cols.move(fi, 0);
                    // 	}

                    // 	if(second === 'ranged.campaign_group' && se !== 1) {
                    // 		cols.move(se, 1);
                    // 	}
                    // }

                    if (attr.stPersistSearch) {
                        if (attr.stPersistSearch === 'false') {
                            delete savedState.search;
                        } else {
                            var getter = $parse(attr.stPersistSearch),
                                val = angular.isFunction(getter(scope)) ? getter(scope)() : getter(scope);
                            savedState.search.predicateObject = { $: val };
                        }
                    }
                    else {
                        delete savedState.search;
                    }
                    angular.extend(tableState, savedState);
                    ctrl.pipe();
                }
                scope.$broadcast('tableStateLoaded', tableState);
                defer.resolve();
                return defer.promise;
            }
        }
    };
}]);

app.directive('stTotals', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        require: '^stTable',
        link: function (scope, elem, attrs, ctrl) {
            if (!attrs.stTotals) { return; }
            var getter = $parse(attrs.stTotals);
            if (!angular.isFunction(getter(scope))) { return; }
            scope.$watchCollection(function () {
                return ctrl.getFilteredCollection();
            }, function (nv, ov) {
                if (nv) {
                    getter(scope)(nv);
                }
            });
        }
    };
}]);


app.directive('stPaginationFluid', ['$parse', '$timeout', '_', 'stConfig', function ($parse, $timeout, _, stConfig) {
    return {
        restrict: 'A',
        require: '^stTable',
        link: function (scope, elem, attrs, ctrl) {
            scope._ = _;
            /* New Calc Function */
            var calc = _.debounce(function () {
                if (scope.numPages) {
                    var n = angular.copy(stConfig.pagination.displayedPages),
                        total_nav = elem.width(),
                        small_width = elem.find('small').width(),
                        total_icon_width = 201,
                        allowable_space = (total_nav - (small_width + 200) - (total_icon_width + 200)),
                        used_space = pagesToPixels(scope.pages) < allowable_space ? pagesToPixels(scope.pages) : allowable_space;

                    while (used_space <= allowable_space) {
                        var range = generateArray(n, scope.currentPage),
                            rangePixels = pagesToPixels(range);

                        if (rangePixels < allowable_space) {
                            n++;
                            used_space = rangePixels;
                        }
                        else if (rangePixels > allowable_space) {
                            n--;
                            break;
                        }
                        else {
                            break;
                        }
                    }
                    scope.stDisplayedPages = n;
                }
            }, 30);

            /* Utility Functions */
            var generateArray = function (n, c) {
                var starting = c === 1 ? 1 : c === scope.numPages ? scope.numPages - n : Math.floor(c - ((n - 1) / 2));
                var ending = c === 1 ? n : c === scope.numPages ? scope.numPages : Math.floor(c + ((n - 1) / 2));
                return fillRange(starting, ending);
            };

            function fillRange(start, end) {
                return Array((end - start) + 1).fill().map(function (v, i) { return start + i; });
            }

            function pagesToPixels(pages) {
                var pieces = _.map(pages, function (o) {
                        return ((String(o).length * 7) + 26);
                    }),
                    sum = _.sum(pieces);
                return sum;
            }
            /* Watch Expressions */

            scope.$watch("totalItemCount", function (nv, ov) {
                if (nv != ov) {
                    calc();
                }
            });
            scope.$watch("currentPage", function (nv, ov) {
                if (nv != ov) {
                    calc();
                }
            });
            scope.$on('$windowResize', calc);
        }
    };
}]);

app.directive('loadingSearch', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        require: '^uiSelect',
        link: function (scope, elem, attrs, ctrl) {
            if (ctrl.multiple) {
                var loader = $('<img ng-if="$select.loading" style="margin-top:3px;margin-right:3px;" class="pull-right" src="../fonts/ring-alt.svg" height="14px" width="14px"></img>');
                ctrl.searchInput.after($compile(loader)(scope));
            }
            else {
                var loader = $('<li ng-if="$select.loading" class="center-all bg-snow" style="top:0;right:0;bottom:0;left:0;position:absolute;z-index:1"><img src="../fonts/ring-alt.svg" height="14px" width="14px"></img></li>');
                elem.find('.ui-select-choices').append($compile(loader)(scope));
            }
        }
    };
}]);

app.directive('bindWidth', ['$parse', '$timeout', '_', function ($parse, $timeout, _) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.bindWidth) return;
            var getter = $parse(attrs.bindWidth),
                setter = getter.assign,
                isFunction = angular.isFunction(getter(scope)),
                oldWidth;

            function change() {
                if (elem.is(':visible')) {
                    var w = elem.width();

                    if (w !== oldWidth) {
                        if (isFunction) {
                            getter(scope)(w);
                        } else {
                            setter(scope, w);
                        }
                        oldWidth = w;
                    }
                }
            }

            change();

            scope.$on('$windowResizeEnd', function () {
                scope.$apply(change);
            });

            if (attrs.bindWidthDigest) {
                var throttled = _.debounce(function () {
                    scope.$apply(change);
                }, 300);
                scope.$watch(throttled);
            }
        }
    };
}]);

app.directive('bindHeight', ['$parse', '$timeout', '_', '$state', function ($parse, $timeout, _, $state) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.bindHeight) return;
            var getter = $parse(attrs.bindHeight),
                setter = getter.assign,
                isFunction = angular.isFunction(getter(scope)),
                oldHeight;

            function change() {
                var h = elem.height();
                if (h !== oldHeight) {
                    scope.loading = true;
                    if (isFunction) {
                        getter(scope)(h);
                    } else {
                        setter(scope, h);
                    }
                    oldHeight = h;
                    scope.$broadcast('$$rebind::bindHeight');
                } else {
                    scope.loading = false;
                }
            }

            change();

            scope.$on('$windowResizeEnd', function () {
                scope.$apply(change);
            });

            if (attrs.bindHeightDigest) {
                var throttled = _.debounce(function () {
                    scope.$apply(change);
                }, 300);
                scope.$watch(throttled);
            }
        }
    };
}]);

app.directive('timestampFormat', function () {
    // Directive that converts timestamp back and forth from
    // seconds to Date object
    return {
        scope: true, // isolated scope
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {

            ngModelCtrl.$formatters.push(function (modelValue) {
                // returns $viewValue
                return {
                    timestamp: (modelValue ? new Date(Number(modelValue)) : "")
                };
            });

            scope.$watch('timestamp', function () {
                ngModelCtrl.$setViewValue({ timestamp: scope.timestamp });
            });

            ngModelCtrl.$parsers.push(function (viewValue) {
                // returns $modelValue
                if (viewValue.timestamp instanceof Date) return Math.floor(viewValue.timestamp.getTime());
                else return "";
            });

            ngModelCtrl.$render = function () {
                // renders timestamp to the view.
                if (!ngModelCtrl.$viewValue) ngModelCtrl.$viewValue = { timestamp: "" };
                scope.timestamp = ngModelCtrl.$viewValue.timestamp;
            };
        }
    };
});

app.directive('isoFormat', function () {
    // Directive that converts timestamp back and forth from
    // seconds to Date object
    return {
        scope: true, // isolated scope
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {

            ngModelCtrl.$formatters.push(function (modelValue) {
                // returns $viewValue
                return {
                    iso: (modelValue ? new Date(modelValue) : "")
                };
            });

            scope.$watch('iso', function () {
                ngModelCtrl.$setViewValue({ iso: scope.iso });
            });

            ngModelCtrl.$parsers.push(function (viewValue) {
                // returns $modelValue
                if (viewValue.iso instanceof Date) return viewValue.iso.toISOString();
                else return "";
            });

            ngModelCtrl.$render = function () {
                // renders timestamp to the view.
                if (!ngModelCtrl.$viewValue) ngModelCtrl.$viewValue = { iso: "" };
                scope.iso = ngModelCtrl.$viewValue.iso;
            };
        }
    };
});

app.directive('lrDragDisabled', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.lrDragDisabled || !attrs.lrDragSrc) { return; }

            scope.$evalAsync(function () {
                var disable = scope.$eval(attrs.lrDragDisabled);
                if (disable) {
                    elem.unbind('drop');
                    elem.unbind('dragstart');
                    elem.unbind('dragend');
                    elem.unbind('dragleave');
                    elem.unbind('dragover');
                    elem.attr('draggable', false);
                }
            });
        }
    };
}]);

app.directive('globalSearch', ['api', 'hotkeys', '$state', '$timeout', '$rootScope', function (api, hotkeys, $state, $timeout, $rootScope) {
    return {
        restrict: 'E',
        templateUrl: 'global-search.html',
        scope: true,
        replace: true,
        controller: function ($scope, $element, $attrs) {
            var mask = $('<div class="mask shadow globalSearch"></div>'),
                topMask = $('<div class="mask shadow globalSearchTop"></div>');
            $scope.data = {};

            $scope.searchAll = function (term) {
                var searchObj = [{
                    api: 'campaign_names_like',
                    url: false,
                    params: { likeName: term }
                }, {
                    api: 'oppty_names_like',
                    url: false,
                    params: { startName: term }
                }, {
                    api: 'account_names_like',
                    url: false,
                    params: { likeName: term }
                }];

                if ($rootScope.userData.platform === 'standard') {
                    searchObj = [{
                        api: 'account_names_like',
                        url: false,
                        params: { likeName: term }
                    }];
                }

                return api.get(searchObj).then(function (data) {
                    var oppties = data.oppty_names_like.data.map(function (val) {
                            val.category = 'Opportunities';
                            return val;
                        }),
                        campaigns = data.campaign_names_like.data.map(function (val) {
                            val.category = 'Campaigns';
                            return val;
                        }),
                        accounts = data.account_names_like.data.map(function (val) {
                            val.category = 'Accounts';
                            return val;
                        }),
                        results = [];

                    if (oppties.length) {
                        oppties[0].firstGroup = true;
                    }
                    if (campaigns.length) {
                        campaigns[0].firstGroup = true;
                    }

                    if (accounts.length) {
                        accounts[0].firstGroup = true;
                    }

                    results = results.concat(oppties);
                    results = results.concat(campaigns);
                    results = results.concat(accounts);
                    return results;
                });
            };

            $scope.toggle = function (e, hotkey) {
                $scope.$evalAsync(function () {
                    $scope.toggleSearchPre = e && e.keyCode === 27 && !$scope.toggleSearch ? false : !$scope.toggleSearch;

                    $timeout(function () {
                        $scope.toggleSearch = $scope.toggleSearchPre;

                        if ($scope.toggleSearch) {
                            $element.find('input').focus();
                            $element.find('input').keyup();
                            $('body').append(mask);
                            $element.parents('#top-right').append(topMask);
                            mask.mousedown($scope.toggle);
                            topMask.mousedown($scope.toggle);
                        } else {
                            $element.find('input').blur();
                            mask.remove();
                            topMask.remove();
                        }
                    }, 0, false);
                });
            };

            $scope.goToResult = function ($item, $model, $value) {
                var accountState = 'app.analyze.accountsAccountSpecific.attribution',
                    state = $item.category === 'Campaigns' ? 'app.campaign.attribution' : $item.category === 'Accounts' ? accountState : 'app.leadsAndOpps.opportunityCampaignHistory',
                    params = $item.category === 'Campaigns' ? { name: $item.name, campaignId: $item.id } : $item.category === 'Accounts' ? { accountId: $item.id } : { oppty: $item.id, isId: true };
                $state.go(state, params);
                $scope.toggle();
                $scope.data.searchFor = null;
            };

            hotkeys.bindTo($scope)
                .add({
                    combo: 'ctrl+s',
                    description: 'Toggle the global search.',
                    allowIn: ['INPUT'],
                    callback: $scope.toggle
                })
                .add({
                    combo: 'esc',
                    description: 'Cancel global search when it\'s focused.',
                    allowIn: ['INPUT'],
                    callback: $scope.toggle
                });

            $scope.$on('$destroy', function () {
                mask.unbind('mousedown', $scope.toggle);
                if (topMask) {
                    topMask.unbind('mousedown', $scope.toggle);
                }
                mask.remove();
                topMask.remove();
            });
        }
    };
}]);

app.directive('newSearch', ['api', 'hotkeys', '$state', '$timeout', '$rootScope', function (api, hotkeys, $state, $timeout, $rootScope) {
    return {
        restrict: 'E',
        templateUrl: 'new-global-search.html',
        scope: true,
        replace: true,
        controller: function ($scope, $element, $attrs) {
            var mask = $('<div class="new-mask shadow globalSearch"></div>');

            $scope.data = {};

            var toggleState;

            $scope.searchAll = function (term) {
                var searchObj = [{
                    api: 'campaign_names_like',
                    url: false,
                    params: { likeName: term }
                }, {
                    api: 'oppty_names_like',
                    url: false,
                    params: { startName: term }
                }, {
                    api: 'account_names_like',
                    url: false,
                    params: { likeName: term }
                }];

                if ($rootScope.userData.platform === 'standard') {
                    searchObj = [{
                        api: 'account_names_like',
                        url: false,
                        params: { likeName: term }
                    }];
                }

                if ($rootScope.userData.permissions.web_tracking) {
                    searchObj.push({
                        api: 'channel_assets_like',
                        url: false,
                        params: { likeName: term }
                    });
                }

                return api.get(searchObj).then(function (data) {
                    var results = [];
                    if (data.oppty_names_like) {
                        var oppties = data.oppty_names_like.data.map(function (val) {
                            val.category = 'Opportunities';
                            return val;
                        });

                        if (oppties.length) {
                            oppties[0].firstGroup = true;
                        }

                        results = results.concat(oppties);
                    }

                    if (data.campaign_names_like) {
                        var campaigns = data.campaign_names_like.data.map(function (val) {
                            val.category = 'Campaigns';
                            return val;
                        });

                        if (campaigns.length) {
                            campaigns[0].firstGroup = true;
                        }

                        results = results.concat(campaigns);
                    }

                    if (data.account_names_like) {
                        var accounts = data.account_names_like.data.map(function (val) {
                            val.category = 'Accounts';
                            return val;
                        });

                        if (accounts.length) {
                            accounts[0].firstGroup = true;
                        }

                        results = results.concat(accounts);
                    }

                    if (data.channel_assets_like) {
                        var channelAssets = data.channel_assets_like.data.map(function (val) {
                            val.category = 'Channel Assets';
                            return val;
                        });

                        if (channelAssets.length) {
                            channelAssets[0].firstGroup = true;
                        }

                        results = results.concat(channelAssets);
                    }

                    $scope.$broadcast('$$rebind::refreshResults');

                    return results;
                });
            };

            $scope.$on('toggle-search', function (e, s) {
                toggleState = s;
                $scope.toggle();
            });

            $scope.clickSearch = function (e) {
                if (!$scope.toggleSearch) {
                    $scope.toggle(e);
                }
            };

            $scope.toggle = function (e) {
                $scope.$evalAsync(function () {
                    $scope.toggleSearchPre = e && e.keyCode === 27 && !$scope.toggleSearch ? false : !$scope.toggleSearch;

                    if (!$scope.toggleSearchPre) {
                        $element.find('input').removeAttr('value');
                    }

                    $timeout(function () {
                        $scope.toggleSearch = $scope.toggleSearchPre;
                        $scope.$broadcast('$$rebind::refreshGlobalSearch');
                        if ($scope.toggleSearch) {
                            $timeout(function () {
                                $element.find('input').removeAttr('value');
                                $element.find('input[uib-typeahead]').focus();
                                $element.find('input[uib-typeahead]').keyup();
                            }, 0, false);
                            $element.parents('#new-top-right').append(mask);
                            mask.mousedown($scope.toggle);
                        } else {
                            $element.find('input[uib-typeahead]').blur();
                            toggleState = null;
                            mask.remove();
                        }
                    }, 0);
                });
            };

            $scope.goToResult = function ($item, $model, $value) {
                var campaignState = 'app.analyze.campaigns.campaignSpecific.attribution',
                    accountState = 'app.analyze.accountsAccountSpecific.attribution',
                    opptyState = 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches',
                    channelAssetState = 'app.analyze.webTracking.channelAssetSpecific.attribution',
                    state = $item.category === 'Campaigns' ? campaignState : $item.category === 'Accounts' ? accountState : $item.category === 'Channel Assets' ? channelAssetState : opptyState,
                    params = $item.category === 'Campaigns' ? { name: $item.name, campaignId: $item.id } : $item.category === 'Accounts' ? { accountId: $item.id } : $item.category === 'Channel Assets' ? { campaignId: $item.id } : { oppty: $item.id, isId: true };

                if (toggleState) {
                    state = toggleState.name;
                }

                $state.go(state, params);
                $scope.toggle();
                $scope.data.searchFor = null;
            };

            hotkeys.bindTo($scope)
                .add({
                    combo: 'ctrl+s',
                    description: 'Toggle the global search.',
                    allowIn: ['INPUT'],
                    callback: $scope.toggle
                })
                .add({
                    combo: 'esc',
                    description: 'Cancel global search when it\'s focused.',
                    allowIn: ['INPUT'],
                    callback: $scope.toggle
                });

            $scope.$on('$destroy', function () {
                if (mask) {
                    mask.unbind('mousedown', $scope.toggle);
                    mask.remove();
                }
            });
        }
    };
}]);
app.directive('zoomFit', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var parent = elem.parent(),
                lastHeight = 0;

            $timeout(function () {
                calc();
                scope.$watch(calc);
            }, 0, false);

            function calc() {
                var parentHeight = parent.height(),
                    height = elem.height();

                if (parentHeight !== lastHeight) {
                    var zoom = (100 - (((height - parentHeight) / height) * 100)) + '%';
                    lastHeight = parentHeight;
                    elem.css('zoom', zoom);
                }
            }

            scope.$on('$windowResize', calc);
            scope.$on('$windowResizeEnd', function () {
                $timeout(calc, 0, false);
            });
        }
    };
}]);

app.directive('hideOnError', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.on('error', function () {
                $(this).hide();

                if (attrs.hideOnError) {
                    var getter = $parse(attrs.hideOnError),
                        setter = getter.assign,
                        isFunction = angular.isFunction(getter(scope));

                    if (isFunction) {
                        getter(scope)(true);
                    } else {
                        setter(scope, true);
                    }
                }
            });
        }
    };
}]);

app.directive('loadingCircle', [function () {
    return {
        restrict: 'A',
        scope: true,
        template: '<div class="circle-progress-wrapper relative">' +
            '<div class="circle-progress ng-binding" ng-style="getStyle()" style="top: 50%; bottom: auto; left: 50%;transform: translateY(-50%) translateX(-50%);">{{current/max|percentage:0}}</div>' +
            '<div round-progress max="max" current="current" color="{{::currentColor}}" bgcolor="{{::bgColor}}" radius="{{::radius}}" stroke="{{::stroke}}" semi="isSemi" rounded="rounded" clockwise="clockwise" responsive="responsive" duration="{{::duration}}" animation="{{::currentAnimation}}" offset="{{::offset}}"></div>' +
            '</div>',
        link: function (scope, elem, attrs) {
            if (!attrs.current || !attrs.max) { return; }

            scope.offset = 60;
            scope.timerCurrent = 0;
            scope.uploadCurrent = 0;
            scope.stroke = 5;
            scope.radius = 80;
            scope.isSemi = false;
            scope.rounded = false;
            scope.responsive = true;
            scope.clockwise = true;
            scope.currentColor = '#1890c4';
            scope.bgColor = '#eaeaea';
            scope.duration = 800;
            scope.currentAnimation = 'easeInOutQuart';

            scope.$watch(attrs.current, function (nv, ov) {
                scope.current = nv;
            });

            scope.$watch(attrs.max, function (nv, ov) {
                scope.max = nv;
            });

            scope.getStyle = function () {
                var transform = (scope.isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)';

                return {
                    'top': scope.isSemi ? 'auto' : '50%',
                    'bottom': scope.isSemi ? '5%' : 'auto',
                    'left': '50%',
                    'transform': transform,
                    '-moz-transform': transform,
                    '-webkit-transform': transform,
                    'font-size': (0.25 * (scope.radius - (scope.stroke * 2))) + 'px'
                };
            };
        }
    };
}]);

app.directive('linky', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.linky) { return; }
            if (elem.parent().attr('no-linkage')) { return; }

            scope.$evalAsync(function () {
                var v = scope.$eval(attrs.linky);

                if (v) {
                    var link = $('<a href="' + v + '"' + (attrs.linkyClass ? ' class="' + attrs.linkyClass + '"' : '') + '></a>');
                    if (attrs.linkyExternal) {
                        link.attr('target', '_blank');
                    }
                    if (attrs.linkyParent) {
                        link.attr('target', '_top');
                    }
                    elem.wrap(link);
                    scope.$watch(function (nv, ov) {
                        elem.parent().attr('href', scope.$eval(attrs.linky));
                    });
                }
            });
        }
    };
}]);

app.directive('cohort', ['$templateCache', '$compile', '_', function ($templateCache, $compile, _) {
    return {
        restrict: 'E',
        require: 'ngModel',
        //transclude
        link: function (scope, elem, attrs) {
            var tpl = '<label class="somemargin-right widget-stretch cohort-selector">\
							Cohort\
							<ui-select ng-if="${cohorts}.length > 1" ng-model="${ngModel}" theme="bootstrap" ng-change="${ngChange}" append-to-body="$tile" append-to-body-mask>\
								<ui-select-match placeholder="select time...">{{$select.selected.value}}</ui-select-match>\
								<ui-select-choices repeat="time.key as time in ${cohorts} | filter: $select.search" group-by="\'scope\'">\
									<div ng-bind-html="::time.value | highlight: $select.search"></div>\
								</ui-select-choices>\
							</ui-select>\
						</label>';
            compiled = _.template(tpl)({ 'ngModel': attrs.ngModel, 'cohorts': (attrs.cohorts || 'data.cohorts'), 'ngChange': (attrs.ngChange || 'cohortChanged(' + attrs.ngModel + ')') });

            elem.replaceWith($compile(compiled)(scope));
        }
    };
}]);

app.directive('bsPanel', ['$compile', function ($compile) {
    return {
        restrict: 'E',
        transclude: true,
        link: function (scope, elem, attrs, ctrl, transclude) {
            transclude(scope, function (clone) {
                var tpl = $('<div class="panel panel-default">' +
                    (attrs.panelTitle ? '<div class="panel-heading"><h4 class="flush"><strong class="dim">{{' + attrs.panelTitle + '}}</strong></h4></div>' : '') +
                    '<div class="panel-body"></div>' +
                    (attrs.panelFooter ? '<div class="panel-footer"><h4 class="flush"><strong class="dim">{{' + attrs.panelFooter + '}}</strong></h4></div>' : '') +
                    '</div>');

                tpl = $compile(tpl)(scope);

                tpl.find('.panel-body').append(clone);

                if (tpl.find('.panel-heading').length) {
                    tpl.prepend(tpl.find('.panel-heading'));
                }

                if (tpl.find('.panel-footer').length) {
                    tpl.append(tpl.find('.panel-footer'));
                }

                elem.append(tpl);
            });
        }
    };
}]);

app.directive('boxBackInnerScroll', [function () {
    return {
        restrict: 'C',
        link: function (scope, elem, attrs) {
            elem.on('mousewheel', function (event) {
                event.stopPropagation();
                event.preventDefault();
                var scroll = elem.scrollTop();
                elem.scrollTop(scroll - event.originalEvent.wheelDeltaY);
                return true;
            });
        }
    };
}]);

app.directive('appendToBodyMask', [function () {
    return {
        restrict: 'A',
        require: 'uiSelect',
        link: function (scope, elem, attrs, ctrl) {
            var scroller = elem.scrollParent(),
                scroll;

            scope.$on('uis:activate', function () {
                scroll = scroller.scrollTop();
                scroller.on('scroll', noScroll);
            });
            scope.$on('uis:close', function () {
                scroller.off('scroll', noScroll);
            });

            function noScroll(e) {
                e.preventDefault();
                e.stopPropagation();
                scroller.scrollTop(scroll);
                return true;
            }
        }
    };
}]);

app.directive('uiSelectFocusInput', function ($timeout) {
    return {
        restrict: 'A',
        require: 'uiSelect',
        link: function (scope, element, attrs, uiSelect) {

            scope.$on('uis:activate', function () {
                $timeout(function () {
                    $('input[ng-model="$select.search"]').focus();
                }, 0, false);
            });
            scope.$on('uis:close', function () { });
        }
    };
});

app.directive('customRange', ['$parse', function ($parse) {
    return {
        restrict: 'E',
        scope: true,
        templateUrl: 'customRange.html', //parital.gsp
        link: function (scope, elem, attrs) {

            //start date and end date binding
            scope.range = {
                options: {
                    'year-format': "'yy'",
                    'starting-day': 1
                },
                startDatepicker: {},
                endDatepicker: {},
                showWeeks: true,
                startingDay: 1,
                isRangeValid: false
            };

            scope.open = function ($event, model) {
                $event.preventDefault();
                $event.stopPropagation();

                return model.opened = true;
            };

            scope.today = function () {
                return scope.dt = new Date();
            };

            scope.today();

            var start = attrs.ngModelStart,
                end = attrs.ngModelEnd,
                apply = attrs.apply;


            if (start) {
                //getter gets values of destination setter sets value of destination
                var getterStart = $parse(start);
                var setterStart = getterStart.assign;

                //gets inital value
                scope.range.start = getterStart(scope);

                //need to know when value changes
                scope.$watch("range.start", function (newVal, oldVal) {
                    setterStart(scope, newVal);
                    scope.range.isRangeValid = checkRangeValidity();
                });
            }

            if (end) {
                var getterEnd = $parse(end);
                var setterEnd = getterEnd.assign;

                //gets inital value
                scope.range.end = getterEnd(scope);

                //need to know when value changes
                scope.$watch("range.end", function (newVal, oldVal) {
                    setterEnd(scope, newVal);
                    scope.range.isRangeValid = checkRangeValidity();
                });
            }

            if (apply) {
                var getterApply = $parse(apply);
                scope.range.apply = getterApply(scope);
            }

            function checkRangeValidity() {
                return (
                    typeof scope.range.start === "number"
                    && typeof scope.range.end === "number"
                    && scope.range.start <= scope.range.end
                );
            }

        }

    };
}]);

app.directive('smallLoading', [function () {
    return {
        restrict: 'A',
        link: function ($scope, elem, attrs) {
            var parent = elem.parent(),
                tpl = $('<div class="max max-width center-all small-loading-wrapper"><img src="../fonts/ring-alt.svg" height="' + (attrs.smallLoadingSize ? attrs.smallLoadingSize : '40') + 'px" width="' + (attrs.smallLoadingSize ? attrs.smallLoadingSize : '40') + 'px"></img></div>');

            if (attrs.smallLoading) {
                $scope.$watch(attrs.smallLoading, function (val) {
                    if (val) {
                        parent.addClass("relative");
                        elem.addClass("blur ghost double-ghost");
                        parent.append(tpl);
                    }
                    else {
                        parent.removeClass("relative");
                        elem.removeClass("blur ghost double-ghost");
                        tpl.remove();
                    }
                });
            }
            else {
                elem.append(tpl);
            }
        }
    };
}]);

app.directive('stSearchBox', ['_', '$parse', '$compile', '$timeout', function (_, $parse, $compile, $timeout) {
    return {
        restrict: 'A',
        require: '^stTable',
        link: function (scope, elem, attrs, ctrl) {
            //if elm has st-table, then table = elem else not elem
            var table = elem.hasAttr('st-table') ? elem : elem.parents('[st-table]');

            var tpl = $('<input type="text" class="primary-input" ng-keypress="onKeyPress($event)" ng-model="query.search" placeholder="Search">');

            scope.callSearch = function () {
                ctrl.search(scope.query.search);
            };

            scope.onKeyPress = function (event) {
                // initiate search when there is at least 1 character in the input box followed by the enter key
                if (event && event.key === 'Enter' && scope.query.search.length) {
                    ctrl.search(scope.query.search);
                }
            };

            scope.stSearchBoxChanged = _.debounce(function (model) {
                if (!model) {
                    ctrl.search(scope.query.search);
                }
            }, 30);

            scope.isDisabled = function (model) {
                return !model || (table.find('.small-loading-wrapper').length && table.find('.small-loading-wrapper').is(':visible'));
            };

            //placeholder
            if (attrs.stSearchPlaceholder) {
                tpl.find("input").attr('placeholder', attrs.stSearchPlaceholder);
            }

            if (table.hasAttr('[st-pipe]') || table.hasAttr('st-pipe') || attrs.stPipe) {
                if (attrs.stSearchBox) {
                    //find element that matches input, then assigns value of stsearchbox to ng-model
                    tpl.find("input").attr('ng-model', attrs.stSearchBox);
                }
                //ng-change = "stSearchBoxChanged(query.search)" ng-disabled="!query.search || scope.isPiping"
                tpl.find("input").attr('ng-change', "stSearchBoxChanged(" + tpl.find("input").attr("ng-model") + ")");

                tpl.find("input").after('<span class="input-group-btn">\
                    <button class="btn btn-sm btn-default search-btn" ng-click="callSearch()" type="button">Search</button>\
                    </span>');
                tpl.find("button").attr('ng-disabled', 'isDisabled(' + tpl.find("input").attr("ng-model") + ')');

            }
            else {
                //if(attrs.stSearchBox) {
                tpl.find("input").attr('st-search', (attrs.stSearchBox || ''));
                //}
            }

            elem.prepend(tpl);
            $compile(tpl)(scope);

            (function () {
                //resets the search to empty when ctrl loads, we don't want persistent search
                $timeout(function () {
                    scope.query.search = "";
                    ctrl.pipe();
                }, 0, false);
            })();

        }

    };
}]);

app.directive('donutTitle', ['AngularChartService', '$timeout', function (AngularChartService, $timeout) {
    return {
        restrict: 'AC',
        require: 'angularChart',
        link: function (scope, elem, attrs, ctrl) {
            var instance = $(elem).data('$isolateScopeNoTemplate').instance;
            calc();

            scope.$on('$windowResizeEnd', calc);
            scope.$watch(attrs.options, calc, true);
            function calc() {
                $timeout(function () {
                    if (elem.find('.c3-chart-arcs .c3-chart-arc').length) {
                        var ww = $(window).width(),
                            w = elem.find('.c3-chart-arcs .c3-chart-arc:last-child')[0].getBoundingClientRect().width - elem.find('.c3-chart-arcs .c3-chart-arc:eq(0)')[0].getBoundingClientRect().height,
                            vw = ((w / ww) * 100) / 3;

                        elem.find('.c3-chart-arcs-title').css('font-size', vw + 'vw');
                    }
                }, 200, false);
            }
        }
    };
}]);

app.directive('relativeFixed', [function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var parent = elem.scrollParent();

            parent.scroll(calc);
            calc();

            function calc() {
                elem.css('top', parent[0].scrollTop + 'px');
            }

            scope.$on('$destroy', function () {
                parent.unbind('scroll', calc);
            });
        }
    };
}]);

app.directive('manageAccountLists', ['$rootScope', function ($rootScope) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            var editListener, doneEditListener;
            scope.disableManageList = false;

            scope.manageListsModal = function () {
                $rootScope.$broadcast('manageLists');
            };

            editListener = $rootScope.$on('editingList', function (e) {
                scope.disableManageList = true;
            });

            doneEditListener = $rootScope.$on('doneEditingList', function (e) {
                scope.disableManageList = false;
            });

            scope.$on('$destroy', function () {
                editListener();
                doneEditListener();
            });
        }
    };
}]);

app.directive('addToDashboard', ['$templateCache', '$compile', 'api', '$b', '$timeout', '$state', 'widgets', 'filters', 'utilities', function ($templateCache, $compile, api, $b, $timeout, $state, widgets, filtersSrv, utilities) {
    return {
        restrict: 'A',
        link: function (scope, elem, iAttrs) {
            scope.$on('runAddTile', function (e) {
                scope.dashboardOptions();
            });
            scope.dashboardOptions = function () {
                var parent = scope,
                    model = {
                        title: 'Add ' + $state.current.data.title + ' Tile to Dashboard',
                        id: 'add-tile',
                        templateUrl: 'dashboard.html',
                        width: '435px',
                        buttons: [{
                            'html': 'Add to Dashboard',
                            'ng-disabled': true,
                            'ng-click': 'addTile(widget)',
                            'class': 'btn btn-primary btn-sm pull-right'
                        }, {
                            'text': 'Cancel',
                            'ng-click': '$modal.closeModal()'
                        }],
                        x: true,
                        maxed: false,
                        controller: ['$scope', 'api', 'utilities', 'noty', '$rootScope', '$interpolate', 'filters', '$window', function ($scope, api, utilities, noty, $rootScope, $interpolate, filters, $window) {
                            /* for stage options, to check what widget options you want on add tile modal */
                            var st = $state.current.name;
                            $rootScope.addTileModal = true;
                            $scope.loading = false;
                            $scope.widget = angular.copy(_.find(widgets.list(), { state: st }));
                            $scope.data = {
                                dashboards: widgets.dashboards()
                            };
                            /* for dashboard options, to check which dashboard you are adding the tile to */
                            $scope.data.dashboard = $scope.data.dashboards[_.findIndex($scope.data.dashboards, { 'is_mine': true })];

                            if ($scope.data.dashboard) {
                                model.buttons[0]['ng-disabled'] = false;
                            }

                            $scope.cohorts = $rootScope.rawCohorts;

                            $scope.$modal.beforeClose = function () {
                                $rootScope.addTileModal = false;
                            };

                            $scope.$on('$apiStart', function () {
                                $scope.apiLoading = true;
                            });

                            $scope.$on('$apiFinish', function () {
                                $scope.apiLoading = false;
                            });

                            $scope.addTile = function (w) {
                                $scope.$modal.mergeFilters().then(function () {
                                    if ($scope.data.dashboard.widgets.length < 12) {
                                        var cp = widgets.unique(w, false, $scope.data.dashboard.id);
                                        if (cp) {
                                            $scope.data.dashboard.widgets.push(cp);
                                            $scope.loading = true;
                                            widgets.setDashboards($scope.data.dashboard).then(function () {
                                                $scope.loading = false;
                                                $scope.$modal.closeModal();
                                                noty.growl("Tile added successfully.", 'information', true);
                                            });
                                        }
                                        else {
                                            $scope.loading = false;
                                            noty.growl("Oops, something went wrong", 'information', true);
                                            return;
                                        }
                                    } else {
                                        $b.alert('You\'ve reached the maximum number of allowable dashboard tiles.');
                                    }
                                });
                            };

                            $scope.cleanTitle = function (title) {
                                return $interpolate(title)($scope);
                            };

                            $scope.previewTile = function ($tile) {
                                $scope.$modal.mergeFilters().then(function () {
                                    $tile.data.loading.failed = false;
                                    $tile.data.noData = false;
                                    $scope.$broadcast('widgetUpdate');
                                });
                            };

                            $scope.resizeText = function (tile) {
                                $scope.$broadcast('gridster-item-resize'); //resize the chart
                                $timeout(function () {
                                    $scope.$broadcast('resizeText'); //if there's fittext involved, then it'll resize the text
                                }, 0, false);
                            };

                            $scope.$on('$tileInit', function (e, scope) {
                                $timeout(function () {
                                    _.forEach($scope.widget.settings.scope, function (v, k) {
                                        var wo = _.get(scope, v),
                                            po = _.get(parent, k);
                                        if (wo && po && _.isActualObject(wo) && _.isActualObject(po)) {
                                            angular.merge(wo, po);
                                            scope.$apply();
                                        }
                                    });
                                    filtersSrv.initializeFilters($scope.widget.data.filters, filtersSrv.makeSelectedFilterMap(scope.$state.params), "sync");
                                    filtersSrv.initializeFolders($scope.widget.data.accountLists, filtersSrv.getListIdsFromParams(scope.$state.params), "sync");
                                    if (_.includes($state.current.name, 'app.campaign.attribution')
                                        || _.includes($state.current.name, 'app.analyze.campaigns.campaignSpecific.trending')
                                        || _.includes($state.current.name, 'app.analyze.webTracking.channelAssetSpecific.trending')) {
                                        var fake_objects = [{
                                            name: parent.data.data.campaign.name,
                                            id: parent.query.campaignId
                                        }];
                                        scope.data.campaigns = fake_objects;
                                    }
                                }, 0, false);
                            });
                        }]
                    };
                $b.modal(model);
            };
        }
    };
}]);

app.directive('dashboardButton', ['$rootScope', '$state', 'widgets', function ($rootScope, $state, widgets) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            scope.addTile = function () {
                $rootScope.$broadcast('runAddTile');
            };

            scope.dashValid = function () {
                var s = $state.current.name;
                scope.tooltip = _.find(widgets.list(), { state: s }) ? 'Add to Dashboard' : 'Coming Soon';
                return _.find(widgets.list(), { state: s });
            };
        }
    };
}]);

app.directive('isVisible', ['_', '$timeout', '$parse', function (_, $timeout, $parse) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.isVisible) { return; }
            var visible = $parse(attrs.isVisible);

            scope.$on('gridster-item-resize', _.once(function (item) {
                if (angular.equals(item.targetScope, scope)) {
                    $timeout(init, 100, false);
                }
            }));

            function init() {
                $timeout(isVisible, 0);
                scope.$on('$contentScrolling', isVisible);
                scope.$on('$windowResizeEnd', isVisible);
                scope.$on('$destroy', function () {
                    visible.assign(scope, false);
                });
                function isVisible() {
                    if (!attrs.isVisibleOneWay || attrs.isVisibleOneWay && !visible(scope)) {
                        visible.assign(scope, !elem.is(':offscreen'));
                    }
                }
            }
        }
    };
}]);

app.directive('bfWidget', [function () {
    return {
        restrict: 'A',
        controller: 'widgetCtrl'
    };
}]);

app.directive('navBreadcrumb', ['_', '$templateCache', '$compile', '$rootScope', '$state', 'utilities', 'widgets', '$timeout', 'isFeatureEnabled', function (_, $templateCache, $compile, $rootScope, $state, utilities, widgets, $timeout, isFeatureEnabled) {
    return {
        restrict: 'E',
        scope: true, //use parent scope
        template: require('../../main/nav-breadcrumb.html'),
        link: function (scope, elem) {
            const DASHBOARD = 'Dashboard';
            const SETTINGS = 'Settings';
            const PLATFORM_TYPE_FULL = 'full';
            const bfStateProvider = new BFStateProvider();
            const ANALYZE_CAMPAIGNS_LIST_ANALYSIS = 'app.analyze.campaigns.listAnalysis';
            const ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC = 'app.analyze.campaigns.campaignSpecific';

            var currentUrl,
                currentParams;

            $rootScope.breadcrumbs = [];
            init();
            checkSize();

            function init() {
                const current = $state.$current,
                    parent = current.data.parent ? $state.get(current.data.parent) : null;

                scope.widgets = widgets;
                if (!current.blind) {
                    $rootScope.breadcrumbs.push(current);
                }
                makeCrumbs(parent);

                const isDataStudioReport = utilities.isDataStudioReport($state.current.name);
                const navMenu = bfStateProvider.getNavTree($rootScope.userData.platform, isDataStudioReport);
                const baseCrumb = $rootScope.breadcrumbs[0];
                const child = $rootScope.breadcrumbs[$rootScope.breadcrumbs.length - 1];
                handleDataStudioMeasurementStudioCrumbs(baseCrumb, child, current, isDataStudioReport, navMenu);
            }

            function makeCrumbs(state) {
                if (state) {
                    if (!state.blind) {
                        $rootScope.breadcrumbs.unshift(state);
                    }
                    if (state.data && state.data.parent) {
                        makeCrumbs($state.get(state.data.parent));
                        generateCrumbUrl($state.get(state.data.parent));
                    }
                }
            }

            function generateCrumbUrl(state) {
                if (state.data && state.data.parent) {
                    //make sure whichever parents have links associated with them (i.e. navUrl's) inherit the params of the state that you're currently on
                    currentUrl = $state.$current.data.navUrl ? $state.$current.data.navUrl : ($state.$current.parent.data && $state.$current.parent.data.navUrl) ? $state.$current.parent.data.navUrl : '';
                    currentParams = queryToObj(currentUrl);
                    var parent = $state.get(state.data.parent);
                    if (parent.data && parent.data.navUrl) {
                        var params = queryToObj(parent.url), //create object of parent url params
                            keysToMergeBy = Object.keys(params); //these are the keys you're going to merge on

                        _.mergeBy(params, currentParams, keysToMergeBy); //new params object with merged similar key-value paris from current state and parent
                        utilities.paramsKeyMapper(params, currentParams); //take care exceptions regarding keys (i.e. modelType --> model)

                        if (_.contains(parent.data.navUrl, '?')) { //continually make the parent url the default without params
                            parent.data.navUrl = parent.data.navUrl.split('?')[0];
                        }
                        parent.data.navUrl = parent.data.navUrl + '?' + objToQuery(params); //create new navUrl which will show as link for breadcrumb
                    }
                }
            }

            function handleDataStudioMeasurementStudioCrumbs(baseCrumb, child, current, isDataStudioReport, navMenu) {
                let parent;
                if (
                    current.data.title !== DASHBOARD
                    && baseCrumb.data.title !== SETTINGS
                ) {
                    if (isDataStudioReport) {
                        $rootScope.breadcrumbs = _.cloneDeep($rootScope.breadcrumbs).slice($rootScope.userData.platform === PLATFORM_TYPE_FULL ? 2 : 1);
                    } else {
                        if ($rootScope.breadcrumbs.length > 3) {
                            parent = $rootScope.breadcrumbs[2];
                            const grandParent = navMenu[2].children.filter((navItem) => navItem.children.filter((item) => item.config.data.title === parent.data.title).length)[0];
                            $rootScope.breadcrumbs = [navMenu[2], grandParent, parent, child];
                        } else {
                            parent = navMenu[2].children.filter((navItem) => navItem.children.filter((item) => {
                                return item.config.data.title === current.data.title
                                    || current.data.parent === item.name
                                    || (
                                        current.data.parent === ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC
                                        && item.name === ANALYZE_CAMPAIGNS_LIST_ANALYSIS
                                    );
                            }).length)[0];
                            $rootScope.breadcrumbs = [navMenu[2], parent, child];
                        }
                    }
                }
            }

            function checkSize() {
                let searchBox = $('#new-global-search-box');
                if (!searchBox.size()) {
                    window.requestAnimationFrame(checkSize);
                    return;
                }
                var crumbPosition = elem.find('#nav-breadcrumb').offset().left + elem.find('#nav-breadcrumb').width(),
                    searchPosition = searchBox.offset().left;

                if ((searchPosition - crumbPosition) < 20) {
                    searchBox.addClass('overlapped');
                } else {
                    searchBox.removeClass('overlapped');
                }
            }

            scope.$on('$windowResize', function () {
                checkSize();
            });

            scope.$on('$apiFinish', function (s, success) {
                $timeout(function () {
                    checkSize();
                    scope.$broadcast('$$rebind::crumbsRefresh');
                }, 0, false);

                if (success) {
                    $rootScope.breadcrumbs = [];
                    init();
                    // checkSize();
                    scope.$broadcast('$$rebind::crumbsRefresh');
                    scope.$apply();
                }
            });
        }
    };
}]);

app.directive('pageLoading', ['$interval', '$state', '$timeout', '$compile', '$rootScope', 'api', function ($interval, $state, $timeout, $compile, $rootScope, api) {
    return {
        restrict: 'EA',
        link: function (scope, elem, attrs) {
            var parent = elem.parent(),
                decayPoint = 0.9,
                decay = 0.5,
                endTimeout,
                startProgress,
                listener = angular.noop,
                isElement = elem[0].tagName === 'PAGE-LOADING',
                template = $('<div class="page-loader center-all" style="display:none;z-index:100;"><uib-progressbar style="width:40%;" class="progress-striped active" max="pageLoading.max" value="pageLoading.progress"></uib-progressbar></div>');

            scope.pageLoading = {
                max: 100
            };

            scope.pageLoading.progress = 0;

            $compile(template)(scope);

            elem.before(template);

            function init() {
                scope.pageLoading.progress = 0;
                let seconds = 20;

                var steadyProgress = Math.floor(decayPoint * seconds),
                    steadyProgressStep = generateProportion(steadyProgress, (decayPoint * 100), (seconds < 1 ? 3 : 9)),
                    decayedProgress = seconds - steadyProgress;

                listener = scope.$watch(position);

                startProgress = $interval(function () {
                    if (steadyProgress) {
                        scope.pageLoading.progress = scope.pageLoading.progress + steadyProgressStep[steadyProgress - 1];
                        steadyProgress--;
                    } else if (scope.pageLoading.progress < 98) {
                        var step = (_.random((seconds - (decayPoint * seconds)), seconds) / 100);
                        scope.pageLoading.progress = scope.pageLoading.progress + step;
                    } else {
                        $interval.cancel(startProgress);
                    }
                }, 1000);
            }

            function position() {
                var o = elem.offset(),
                    w = parent.width(),
                    wh = $(window).height();

                o.bottom = o.top + elem.height();

                template.css({
                    top: `${ (o.top >= 0 ? o.top : 71) }px`,
                    left: `${ o.left }px`,
                    width: `${ w }px`,
                    bottom: `${(o.bottom > wh ? 0 : (wh - o.bottom)) }px`,
                });
            }

            scope.$on('$windowResize', position);
            scope.$on('$contentScrolling', position);

            scope.$on('$destroy', function () {
                template.remove();
                listener();
                $interval.cancel(startProgress);
                $timeout.cancel(endTimeout);
            });

            function set() {
                listener();
                $interval.cancel(startProgress);
                $timeout.cancel(endTimeout);
                position();
                template.show();
                parent.addClass('relative');
                elem.addClass('blur');
                $('#runButton i').addClass('spin');
                if (api.getApiMap()[$state.current.name]) {
                    init();
                } else {
                    $timeout(init, 0, false);
                }
            }

            function unset() {
                listener();
                $interval.cancel(startProgress);
                scope.pageLoading.progress = 100;
                $rootScope.$broadcast('scrollpointShouldReset');
                endTimeout = $timeout(function () {
                    template.hide();
                    parent.removeClass('relative');
                    elem.removeClass('blur');
                    $('#runButton i').removeClass('spin');
                    $rootScope.$broadcast('checkScroll');
                }, 1000);
            }

            scope.$watch((isElement ? attrs.loadingWhen : attrs.pageLoading), function (loading) {
                if (loading) {
                    set();
                } else {
                    unset();
                }
            });
        }
    };
}]);

app.directive('applyChanges', ['$parse', '_', '$timeout', 'influenceTypes', function ($parse, _, $timeout, influenceTypes) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            if (!attrs.applyChanges) { return; }
            const applyBtn = $(`
                <button ng-if="$state.current.name !== 'app.dashboard'"
                        id="runButton" class="btn btn-sm btn-default inline-block"
                        type="button"
                        disabled="true">
                     <i class="fas fa-sync-alt space-right"></i>Apply
                </button>`);
            const fun = $parse(attrs.applyChanges);
            const funData = $parse(attrs.applyFinished);
            const getter = fun(scope);
            const getterData = angular.isFunction(funData(scope));
            const repeaters = [];
            const queryModels = [];
            const dataModels = [];
            let queryWatch = angular.noop;
            const ignore = eval(attrs.applyIgnore);
            let dataWatch = angular.noop;
            let parentQueryWatch = angular.noop;
            const applyDisabled = attrs.applyDisabled;
            let dirty;

            scope.dirty = function (obj, doIgnore) {
                if(scope.query.cohort === 'time' && !validateQuery(scope)) {
                    return false;
                }

                if (obj) {
                    var localObj = doIgnore && ignore ? _.omit(scope[obj], ignore) : scope[obj],
                        scopeObj = doIgnore && ignore ? _.omit(scope.$parent[obj], ignore) : scope.$parent[obj];

                    dirty = !angular.equals(localObj, scopeObj);
                } else {
                    _.forEach(scope, function (child, key) {
                        if (_.isActualObject(child)) {
                            var localObj = child,
                                scopeObj = scope.$parent[key];
                            dirty = !angular.equals(localObj, scopeObj);
                        }
                    });
                }
                if (attrs.contingent) { //this is for pages that require an input before the apply button can be enabled (i.e. campaign perf trending goal, cohorted waterfall first + last touch)
                    var contingency = scope.$eval(attrs.contingent), //contigent upon this array being defined (first is where the value is located, second is is the evaluted value)
                        isContingent = false;

                    contingency.forEach(function (val, index) {
                        if (index > 0 && !isContingent) {
                            isContingent = ((scope.$eval(scope.$eval(attrs.contingent)[0]) === scope.$eval(attrs.contingent)[index])); //check if any of the values in the array are defined
                        }
                    });

                    if (applyDisabled && _.includes(attrs.applyDisabled, '[') && _.includes(attrs.applyDisabled, ']') && isContingent) {
                        //disabledProps is the namespacing as to where you'll find the contingent property (can be an object or a string)
                        //so for example: for camp perf trending, the property that makes the apply button disabled ['data.goals', 'query.field', 'query.cohort']
                        //data.goals is an object that is namespaced with query.field and query.cohort
                        //this can also be an array of evaluated strings, where-in we can just check if either of these values are defined
                        var disabledProps = scope.$eval(attrs.applyDisabled),
                            evaluated = [],
                            ob;

                        angular.forEach(disabledProps, function (o) {
                            var e = scope.$eval(o);
                            if (_.isActualObject(e)) {
                                ob = e;
                            }
                            else {
                                evaluated.push(e);
                            }
                        });

                        //for pages like campaign perf trending, where values are namespaced inside objects
                        if (ob && _.isActualObject(ob)) {
                            dirty = _.isDefined(_.get(ob, evaluated));
                        }
                        //for pages like cohorted waterfall, where values are just strings in an array that are either defined or not
                        else {
                            var checker = false;
                            evaluated.forEach(function (val) {
                                if (!checker) {
                                    checker = _.isDefined(val);
                                    if (_.isArray(val)) { checker = (val.length !== 0); } //can also sometimes be arrays that are already instantiated with length 0
                                    dirty = checker;
                                }
                            });
                        }
                    }
                }
                return dirty;
            };

            scope.useTypeFilteredAttr = influenceTypes.useTypeFilteredAttr;

            if (!attrs.overrideTypeAttrModelFilter) {
                scope.typeAttrModelFilter = influenceTypes.typeAttrModelFilter;
            }

            applyBtn.on('click', check);
            elem.append(applyBtn);

            function check() {
                parentQueryWatch();
                //------------------------ check to see if query is different ------------------------//
                var doQuery = false;
                if (scope.dirty('query', true) && angular.isFunction(getter)) {
                    doQuery = true;
                }
                //------------------------ merge child into parent -----------------------------------//
                var newObj = {},
                    obj = {};

                newObj.data = _.cloneDeep(scope.data);
                newObj.query = _.cloneDeep(scope.query);

                var ov = dataCopy(scope.$parent), //builds an object with only model values and repeaters
                    ovq = _.cloneDeep(scope.$parent.query);

                angular.merge(scope.$parent.data, newObj.data);
                angular.extend(scope.$parent.query, newObj.query);

                obj.data = ov.data;
                obj.query = ovq;
                //------------------------ if query was different then apply the api call ------------------------//
                if (doQuery) {
                    scope.$apply(getter);
                }
                else {
                    scope.$parent.$apply(function () {
                        if (getterData) {
                            funData(scope)(newObj, obj);
                        }
                    });
                }
                applyBtn.prop('disabled', 'true');
                applyBtn.removeClass('btn-primary');
                initParentWatch();
            }

            function validateQuery(scope) {
                if (typeof scope.query.startDate !== "number") {
                    return false;
                }
                else if (typeof scope.query.endDate !== "number") {
                    return false;
                }
                else if (scope.query.endDate < scope.query.startDate) {
                    return false;
                }

                return true;
            }

            function dataCopy(d) {
                var obj = {},
                    testArray = _.union(repeaters, dataModels),
                    d = _.get(d.$parent, testArray[0]) ? _.cloneDeep(d.$parent) : d; // you're either passing in $parent or the child...so we need to determine which one to use

                if (attrs.extraData && _.includes(attrs.extraData, '[') && _.includes(attrs.extraData, ']')) {
                    testArray = _.concat(testArray, scope.$eval(attrs.extraData));
                }

                _.forEach(testArray, function (val, i) {
                    _.set(obj, val, _.cloneDeep(_.get(d, val)));
                });
                return _.compactObject(obj);
            }

            function findRepeaters() {
                if (elem.find('[repeat]').length) {
                    elem.find('[repeat]').each(parseRepeater);
                }
                if (elem.find('[ng-repeat]').length) {
                    elem.find('[ng-repeat]').each(parseRepeater);
                }
            }

            function findQueryModels() {
                if (elem.find('.ui-select-container[ng-model]')) {
                    elem.find('.ui-select-container[ng-model]').each(parseQueryModels);
                    elem.find('.ui-select-container[ng-model]').each(parseDataModels);
                }
            }

            function parseRepeater() {
                var attr = $(this).hasAttr('ng-repeat') ? 'ng-repeat' : 'repeat';
                var dataArray = $(this).attr(attr).match(/^\s*(.+)\s+in\s+(.*)\s*$/)[2];
                if (_.includes(dataArray, '|')) {
                    dataArray = dataArray.split('|')[0].trim(); //data.dataSets, data.model, data.cohorts, etc.
                }
                repeaters.push(dataArray);
            }

            function parseQueryModels() {
                var qm = $(this).attr('ng-model'),
                    delim = qm.split("query.");

                qm = delim[delim.length - 1];
                queryModels.push(qm); //dataSets, model, cohorts, etc.
            }

            function parseDataModels() {
                var dm = $(this).attr('ng-model'),
                    delim = dm.split("data.");

                if (delim[0] === dm) {
                    dm = null;
                }
                dataModels.push(dm);
            }

            //create watch expressions for both parent objects, parse repeaters and compare to $parent
            function initParentWatch() {
                scope.$on('$apiFinish', function () {
                    var testArray = _.clone(repeaters);
                    if (attrs.extraData && _.includes(attrs.extraData, '[') && _.includes(attrs.extraData, ']')) {
                        testArray = _.concat(testArray, scope.$eval(attrs.extraData));
                    }

                    angular.forEach(testArray, function (r) {
                        var localVal = _.get(scope, r),
                            parentVal = _.get(scope.$parent, r);
                        if (parentVal && !angular.equals(localVal, parentVal)) {
                            _.set(scope, r, angular.copy(parentVal));
                        }
                    });
                });
                parentQueryWatch = scope.$watch(() => scope.$parent.query, (newValue, oldValue) => {
                    if (newValue) {
                        if (scope.query) {
                            var parentObj = _.omit(angular.copy(newValue), queryModels.concat('tstamp')),
                                localObj = _.omit(scope.query, queryModels);
                            if (!angular.equals(parentObj, localObj)) {
                                queryWatch();
                                scope.query = _.omitBy(scope.query, (value, key) => {
                                    return _.startsWith(key, "filter");
                                });
                                angular.merge(scope.query, parentObj);
                                initQueryWatch();
                            }
                        } else {
                            scope.query = _.cloneDeep(newValue);
                        }
                    }
                }, true);
            }

            function initDataWatch() {
                dataWatch = scope.$watch(function () {
                    return scope.data;
                }, function (nv, ov) {
                    if (!angular.equals(nv, ov)) {
                        var isDirty = scope.dirty('data');
                        applyBtn.prop('disabled', !isDirty);
                        if (isDirty) {
                            applyBtn.addClass('btn-primary');
                        }
                        else {
                            applyBtn.removeClass('btn-primary');
                        }
                    }
                }, true);
            }

            function initQueryWatch() {
                queryWatch = scope.$watch(function () {
                    return scope.query;
                }, function (nv, ov) {
                    if (influenceTypes.useNewInfluenceType() && influenceTypes.defaultToEven(scope)) {
                        if (scope.query.model) {
                            scope.query.model = 'even';
                        }
                        if (scope.query.modelType) {
                            scope.query.modelType = 'even';
                        }
                    }
                    if (!angular.equals(nv, ov)) {
                        $timeout(function () {
                            var isDirty = scope.dirty('query');
                            applyBtn.prop('disabled', !isDirty);
                            if (isDirty) {
                                applyBtn.addClass('btn-primary');
                            }
                            else {
                                applyBtn.removeClass('btn-primary');
                            }
                        }, 100);
                    }
                }, true);
            }

            (function init() {
                $timeout(function () {
                    findRepeaters();
                    findQueryModels();

                    scope.query = _.cloneDeep(scope.query);
                    scope.data = {}; //local scope data object
                    var intermediate = dataCopy(scope);
                    angular.merge(scope.data, intermediate.data);
                    initParentWatch();
                    initDataWatch();
                    initQueryWatch();
                }, 0, false);
            })();
        }
    };
}]);

app.directive('dropdownCutoff', [function () {
    return {
        restrict: 'A',
        require: '^uibDropdown',
        link: function (scope, element, attrs, ctrl) {
            $(element).on('click', toggleDropdown);

            function toggleDropdown() {
                var isExpanded = $(element).find('[aria-expanded]').attr('aria-expanded');
                if (isExpanded === 'true') {
                    // 160px is the width of each of the dropdown menus, so if the left offset is more than
                    // the window minus 160, make the offset window minus 160 (plus a little for padding, ergo 165)
                    var offSet = $(ctrl.dropdownMenu).offset().left,
                        diff = $(window).width() - 165;
                    if (offSet > diff) {
                        $(ctrl.dropdownMenu).offset({ left: diff });
                    }
                }
            }
        }
    };
}]);

app.directive('saveButton', ['$parse', '_', '$timeout', function ($parse, _, $timeout) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            if (!attrs.saveButton || !attrs.watchFor) { return; }
            var saveBtn = $('<button class="btn btn-default inline-block" type="button" disabled="true">' + (attrs.iconClass ? '<i class="' + attrs.iconClass + ' space-right inline-block"></i>' : '') + attrs.addLabel + '</button>'),
                fun = $parse(attrs.saveButton),
                getter = fun(scope),
                watchFunc = angular.noop,
                ignore = attrs.ignore,
                dirty;

            saveBtn.on('click', save);
            elem.append(saveBtn);

            function dirtyCheck(local, parent) {
                if (local && parent) {
                    var localObj = scope.$eval(local),
                        parentObj = parent;
                    dirty = !angular.equals(localObj, parentObj);
                    return dirty;
                }
            }

            function save() {
                dataWatcher();
                var doQuery = false;
                if (dirty && angular.isFunction(getter)) {
                    doQuery = true;
                }

                if (doQuery) {
                    scope.$apply(getter);
                }

                saveBtn.prop('disabled', 'true');
                saveBtn.removeClass('btn-primary');
                dataWatcher();
            }

            function dataWatcher() {
                watchFunc = scope.$watch(function () {
                    return scope.$parent.$eval(attrs.watchFor);
                }, function (nv, ov) {
                    if (!angular.equals(nv, ov)) {
                        var isDirty = dirtyCheck(attrs.watchFor, nv);
                        var isError = attrs.errorWatch ? scope.$eval(attrs.errorWatch) : false;
                        saveBtn.prop('disabled', !isDirty || isError);
                        if (isDirty && !isError) {
                            saveBtn.addClass('btn-primary');
                        }
                        else {
                            saveBtn.removeClass('btn-primary');
                        }
                    }
                }, true);
            }

            (function init() {
                scope.$on('$apiFinish', function () {
                    scope.data = _.cloneDeep(scope.$parent.$eval(attrs.watchFor));
                    dataWatcher();
                });
                scope.$on('startDataWatch', dataWatcher);
                scope.$on('saveConfigurations', save);
            })();
        }
    };
}]);
