(function () {
    'use strict';

    angular.module('accountJourney').directive('frequentTopicsCard', frequentTopicsCard);

    frequentTopicsCard.$inject = [];

    function frequentTopicsCard() {
        return {
            restrict:  'E',
            template: require('./most-frequent-topics-card.html'),
            controller: 'frequentTopicsCardController',
            controllerAs: 'frequentTopicsCardCtrl',
        };
    }
})();
