/**
 * Add the onboarding module.
 */
(function () {
    'use strict';

    var app = angular
        .module('brightfunnelOnboard',
            ['jwt', 'notyModule', 'environment']);

    /**
     * Configuration for the application.
     */
    function appConfig(notyProvider) {
        notyProvider.settings = {
            theme: 'metroui',
            text: 'Terminus',
            layout: 'topRight',
            force: true,
            easing: 'swing',
            timeout: 3000,
            animation: {
                open: 'animated  fadeInUp',
                close: 'animated fadeOutDown',
                easing: 'swing'
            }
        };
    }

    app.config(['notyProvider', appConfig]);

    /**
     * Run function for the application.
     */
    function appRun() {
    }

    app.run(appRun);

})();
