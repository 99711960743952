/**
 * Directive to implement the behavior of the primary form button
 */
(function () {
    'use strict';

    angular.module('brightfunnelOnboard').directive('formButton', formButtonComponent);

    formButtonComponent.$inject = [];

    /**
     * Form Primary Button
     */
    function formButtonComponent() {
        return {
            restrict: 'E',
            template: require('./form-button.html'),
            controller: formButtonCtrl,
            controllerAs: '$ctrl',
            scope: {
                'id': '=',
                'text': '=',
                'enabled': '=',
                'fnChange': '&',
                'external': '=',
            },
            bindToController: true
        };
    }

    formButtonCtrl.$inject = ['$scope'];

    /**
     * Form button controller
     */
    function formButtonCtrl($scope) {
        var ctrl = this;

        /**
         * Click function for the button.
         */
        this.formClick = function ($event) {
            if ($event) {
                $event.preventDefault();
            }
            ctrl.fnChange()(ctrl.id);
        };

        $scope.$watch('$ctrl.enabled', function (newVal) {
            ctrl.enabled = newVal;
        });

        $scope.$watch('$ctrl.external', function (newVal) {
            ctrl.external = newVal;
        });
    }
})();
