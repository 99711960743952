/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('journeyTabService', journeyTabService);

    journeyTabService.$inject = ['$filter', 'chartDataNormalizer'];

    function journeyTabService($filter, chartDataNormalizer) {
        var service = {};

        service.opptyHistoryMapper = function (oppty, opptyHistory) {
            var normalizedOpptyHistory = normalizeOpptyHistoryData(opptyHistory);
            return normalizedOpptyHistory.filter(function (stage) {
                return service.omitRetroactiveData(stage, oppty);
            });
        };

        service.isAllChartDataEmpty = function (chartData) {
            var emptyChart = true;

            Object.keys(chartData).map(function (type) {
                if (Object.keys(chartData[type]).length) {
                    emptyChart = false;
                }
            });

            return emptyChart;
        };

        service.omitRetroactiveData = function (stage, oppty) {
            var normalizedOppty = normalizeOpptyData(oppty),
                targetOppty = normalizedOppty.filter(function (record) { return record.id === stage.opportunityId; })[0],
                isBefore,
                isSame;

            stage.opptyCreateDate = targetOppty ? targetOppty.createDate : '';
            stage.opptyClosedDate = targetOppty ? targetOppty.closedDate : '';
            isBefore = moment(stage.opptyCreateDate).isBefore(stage.opptyClosedDate),
            isSame = moment(stage.opptyCreateDate).isSame(stage.opptyClosedDate);

            return (isBefore || isSame) &&  stage;
        };

        function normalizeOpptyData(data) {
            return data.map(function (a) {
                return {
                    closedDate: $filter('date')(chartDataNormalizer.generateStartOfWeek(a.week_closed), 'shortDate'),
                    createDate: $filter('date')(chartDataNormalizer.generateStartOfWeek(a.week_created), 'shortDate'),
                    id: a.id,
                    isOpen: a.is_open,
                    name: a.name
                };
            });
        }

        function normalizeOpptyHistoryData(data) {
            return Object.keys(data).map(function (key) {
                var opptyHistory = {
                    opportunityId: data[key]['opportunity_id'],
                    name: data[key].name,
                };

                opptyHistory.history = Object.keys(data[key].history)
                    .map(function (stage) {
                        var activity = data[key].history[stage];
                        return {
                            date: activity.stage_start_date,
                            activityName: activity.oppty_name,
                            touchType: 'Opportunity',
                            activityType: activity.stage_name,
                            fullName: activity.contact_name || '',
                            contactID: activity.contact_id,
                            title: activity.title || '',
                            email: activity.email || '',
                            activityId: activity.opportunity_id
                        };
                    })
                    .sort(function (a, b) {
                        var aDate = moment(a.date),
                            bDate = moment(b.date);

                        if (aDate.isBefore(bDate)) {
                            return -1;
                        } else if (aDate.isAfter(bDate)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                return opptyHistory;
            });
        }

        return service;
    }
})();
