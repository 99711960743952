/**
 * Directive to implement the not selected behavior
 */
(function () {
    'use strict';

    angular.module('brightfunnelOnboard').directive('notSelected', notSelectedComponent);

    /**
     * Not selected component
     */
    function notSelectedComponent() {
        return {
            restrict: 'E',
            template: require('./not-selected.html'),
            controller: notSelectedCtrl,
            transclude: true,
            controllerAs: '$ctrl',
            scope: {
                'label': '=',
            },
            bindToController: true
        };
    }

    notSelectedCtrl.$inject = [];

    /**
     * Not Selected controller
     */
    function notSelectedCtrl() {
    }
})();

