(function () {
    'use strict';

    angular.module('accountJourney').directive('activityTabComponent', activityTabComponent);

    activityTabComponent.$inject = [];

    /**
     * Controller.
     * @param $scope
     */
    function activityTabComponent() {
        return {
            restrict: 'E',
            template: require('./activity-tab-component.html'),
            controller: 'activityTabController',
            controllerAs: 'activityCtrl'
        };
    }
})();

