(function () {
    'use strict';

    angular.module('accountJourney').controller('activityTrendsCardController', activityTrendsCardController);

    activityTrendsCardController.$inject = ['$scope', 'chartDataGenerationService', 'insightTabService',
        'accountJourneyService', 'AccountJourneyConstants'];

    function activityTrendsCardController($scope, chartDataGenerationService,
        insightTabService, accountJourneyService, AccountJourneyConstants) {
        var self = this;
        accountJourneyService.getData().then(function (data) {
            self.marketingTouches = insightTabService.formatMarketingTouchData(data);
            self.pageView = chartDataGenerationService.generateChartData(data['anonymous'].data.page_views,
                AccountJourneyConstants.AnonymousData,
                AccountJourneyConstants.LineGraph, {
                    cohort: 'days90', totals: true
                });
        });
    }
})();
