import { bootstrapEnvironmentVars } from './env-boostrap';
import builtinNinjaEnvJson from '../../../environment.json';

(() => {
    'use strict';

    /**
     * Service manage environment variables
     */
    class EnvironmentSrvc {

        constructor($http, $q) {
            this.http = $http;
            this.q = $q;
            this.init();
        }

        // this is extracted in this weird way so that
        // unit tests can re-init forcing prod config true
        init(isProductionConfiguration = process.env.NODE_ENV === 'production'){
            if(!this.env){
                this.env = bootstrapEnvironmentVars( this.http, this.q, builtinNinjaEnvJson, isProductionConfiguration, {
                    /* environment.json overrides
                        This should be exceptionally rare, usually just for local
                        testing when testing against prod environment.json
                        example:
                        FRONTEND_HOSTNAME: 'local-ninja.terminusplatform.ninja'
                    */
                });
            }
            return this.env;
        }

        /**
         * Get the value for an property in `environment.json`
         * @param key Property name
         * @returns {*} A promise resolved with the value
         */
        getVariable(key) {
            if(!this.env) this.init();
            return this.env.then( env => this.q.when(env[key]));
            // return this.q.when(this.env[key]);
        }
    }

    EnvironmentSrvc.$inject = ['$http', '$q'];

    angular
        .module('environment')
        .service('environmentSrvc', EnvironmentSrvc);
})();
