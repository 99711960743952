import { module as angularModule } from 'angular';
import {PlatformBrowserIdentitySrvc} from './platform-bi/platform-bi-srvc';
import {SplitIoSdkSrvc} from './split-io/splitio-srvc';
import {UNIFIED_LOGIN} from '../../constants';
import {OktaSrvc} from './okta-sdk/okta-srvc';

const app = angularModule(UNIFIED_LOGIN, ['ngCookies']);

// Services
app.service('platformBrowserIdentitySrvc', PlatformBrowserIdentitySrvc);
app.service('splitIoSrvc', SplitIoSdkSrvc);
app.service('oktaSrvc', OktaSrvc);
