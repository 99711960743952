/* eslint-disable no-undef */
/* eslint-disable no-cond-assign */
/* eslint-disable no-constant-condition */
/* global moment */
"use strict";

app.service('utilities', ['$filter', '$state', '$timeout', '$http', '$location', '$rootScope', '$localStorage', '$stateParams', '$urlRouter', '$q', '$log', '$interval', '_', '$injector', 'isFeatureEnabled', function ($filter, $state, $timeout, $http, $location, $rootScope, $storage, $stateParams, $urlRouter, $q, $log, $interval, _, $injector, isFeatureEnabled) {
    var that = this,
        urlListener = true;

    var monthCutoffInterval = 1460,
        weekCutoffInterval = 200,
        dayCutoffInterval = 13,
        ALL_TIME = "*NONE*";

    this.getAllTimeValue = function () {
        return ALL_TIME;
    };

    this.urlListen = function () {
        return urlListener;
    };

    this.encodeData = function (data) {
        return Object.keys(data).map(function (key) {
            return [key, data[key]].map(encodeURIComponent).join("=");
        }).join("&");
    };

    this.get_month_name = function (index) {
        return ["January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"][index - 1];
    };

    this.get_month_index = function (name) {
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months.indexOf(_.find(months, function (month) {
            return _.startsWith(month, name);
        }));
    };

    this.weekOfMonth = function (date) {
        var prefixes = [1, 2, 3, 4, 5];
        return prefixes[0 | moment(date).date() / 7];
    };

    this.wait = function (fn, delay) {
        return $interval(fn, delay, 1);
    };

    this.waitFor = function (check) {
        if (!angular.isFunction(check)) { return; }
        var defer = $q.defer();
        var wait = $interval(function () {
            if (check()) {
                $interval.cancel(wait);
                defer.resolve(check());
            }
        }, 1);
        return defer.promise;
    };

    this.getYMD = function (date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    };

    this.createLocalDate = function (date) {
        date = new Date(date);
        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        return date;
    };

    this.generateId = function () {
        return (Math.floor((Math.random() * 100000000000000000000) + 1) + new Date().getTime()).toString(32);
    };

    this.getFrequencyStepsFromDates = function (startDate, endDate) {
        // get bounds of selected cohort -- this is going to be your window
        var distance = moment(endDate).diff(moment(startDate), 'days');

        if (distance <= dayCutoffInterval) {
            return 'days';
        } else if (distance <= weekCutoffInterval) {
            return 'weeks';
        } else if (distance <= monthCutoffInterval) {
            return 'months';
        } else {
            return 'years';
        }
    };

    /**
     * @param startDate moment, string or long; should be start of day - i.e. 00:00:00
     * @param endDate moment, string or long; time of day doesn't matter - assumed to be end of day
     * @param step aka interval (days, weeks, months, years)
     * @param format unix/x gives a long (millis from epoch), null/false/empty returns _d
     * @returns {Array}
     */
    this.getDates = function (startDate, endDate, step, format) {
        // eslint-disable-next-line no-redeclare
        var format = format,
            customStep;

        if (_.isArray(step)) {
            customStep = true;
        }

        if (format && format == 'unix') {
            format = 'x';
        }

        // eslint-disable-next-line no-redeclare
        var step = step || 'days';
        var dateArray = [];

        var currentDate = startDate instanceof moment ? startDate.clone() : moment(startDate);
        endDate = endDate instanceof moment ? endDate : moment(endDate);
        while (currentDate < endDate) {
            dateArray.push(format ? currentDate.format(format) : currentDate._d);
            currentDate = currentDate.clone().endOf('day').add((customStep ? step[0] : 1), (customStep ? step[1] : step));
        }

        dateArray.push(format ? endDate.endOf('day').format(format) : endDate.endOf('day')._d);

        return dateArray;
    };

    this.storageLeft = function () {
        var size = 0;
        for (var x in localStorage) {
            size = size + ((localStorage[x].length * 2) / 1024 / 1024);
        }
        return 5 - size;
    };

    this.stringToMb = function (str) {
        return ((str.length * 2) / 1024 / 1024);
    };

    this.last = function (a) {
        if (!angular.isArray(a)) return;
        return a[a.length - 1];
    };

    this.health = function (set, positive, threshhold, middleThreshold, increase) {
        var h = {
                poor: {
                    label: 'poor',
                    cls: 'danger',
                    textCls: 'text-danger',
                    arrowCircle: 'icon-arrow-circle-down',
                    arrow: 'icon-arrow-down',
                    bg: '#a94442'
                },
                average: {
                    label: 'average',
                    cls: 'default',
                    textCls: middleThreshold ? 'text-average' : 'text-muted',
                    bg: '#ECB903'
                },
                good: {
                    label: 'good',
                    cls: 'success',
                    textCls: 'text-success',
                    arrowCircle: 'icon-arrow-circle-up',
                    arrow: 'icon-arrow-up',
                    bg: '#3c763d'
                }
            },
            c = set[0],
            g = set[1];
        if (c === undefined || c === null || g === undefined || g === null) return;

        var d = that.diff(c, g, true, true, increase) * 100,
            t = threshhold !== null && threshhold !== undefined ? threshhold : 10,
            is;
        if (eval(c + positive + g)) {
            if (!middleThreshold && d <= t) {
                is = 'average';
            } else {
                is = 'good';
            }
        } else {
            if (middleThreshold) {
                if (d === 0) {
                    is = 'poor';
                } else if (d <= t) {
                    is = 'good';
                } else if (d <= middleThreshold) {
                    is = 'average';
                } else {
                    is = 'poor';
                }
            } else
            if (d < t) {
                is = 'average';
            } else {
                is = 'poor';
            }
        }

        if (increase) {
            h[is].diff = that.diff(c, g, false, true, increase);
        }
        else {
            // if diff is greater than 100, we need to abs it else it'll be negative bc we are subtracting by 100
            h[is].diff = Math.round(100 - (that.diff(c, g, true, true) * 100)) + '%';
        }
        return h[is];
    };

    this.diff = function (n1, n2, noFilter, abs, increase) {
        var d,
            diff;
        if (increase) {
            d = n2;
        }
        else {
            d = n1 > n2 ? n1 : n2;
            // always want the % relative to the goal?
            // d = n1;
        }
        if (d !== 0) {
            diff = (n1 - n2) / d;
        } else {
            diff = 0;
        }
        return noFilter && abs ? Math.abs(diff) : noFilter ? diff : $filter('percentage')((abs ? Math.abs(diff) : diff), 0);
    };

    this.cohortProgress = function (cohortStart, cohortEnd, lastPoint) {
        var cohortDistance = moment(cohortEnd).diff(moment(cohortStart), 'days'),
            periodDistance = moment(lastPoint).diff(moment(cohortStart), 'days'),
            diff = (periodDistance * 100) / cohortDistance;

        return diff ? Math.round(diff) : 0;
    };

    this.imageSize = function (srcWidth, srcHeight, maxWidth, maxHeight) {

        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    };

    this.lodash = function () {
        return _;
    };

    this.isSameDay = function (date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);
        return date1.getDate() == date2.getDate() && date1.getMonth() == date2.getMonth() && date1.getYear() == date2.getYear();
    };

    this.formatDateTypes = function () {
        return [
            {
                key: 'day',
                value: 'Days'
            },
            {
                key: 'week',
                value: 'Weeks'
            },
            {
                key: 'month',
                value: 'Months'
            },
            {
                key: 'fiscal_qtr',
                value: 'Fiscal Quarters'
            },
            {
                key: 'year',
                value: 'Years'
            }
        ];
    };

    this.formatCohorts = function (data, def, future = false) {
        const arr = [];

        if (_.contains(def, 'ago')) {
            Array.prototype.unshift.apply(arr, [{
                key: 'days7',
                value: 'One Week',
                scope: 'Ago From Today',
                start_date: moment(new Date()).subtract(1, 'week')._d.getTime(),
                end_date: new Date().getTime(),
            }, {
                key: 'days30',
                value: 'One Month',
                scope: 'Ago From Today',
                start_date: moment(new Date()).subtract(1, 'month')._d.getTime(),
                end_date: new Date().getTime(),
            }, {
                key: 'months3',
                value: 'Three Months',
                scope: 'Ago From Today',
                start_date: moment(new Date()).subtract(3, 'month')._d.getTime(),
                end_date: new Date().getTime(),
            }, {
                key: 'months6',
                value: 'Six Months',
                scope: 'Ago From Today',
                start_date: moment(new Date()).subtract(6, 'month')._d.getTime(),
                end_date: new Date().getTime(),
            }, {
                key: 'year',
                value: 'One Year',
                scope: 'Ago From Today',
                start_date: that.getDateYearsFromToday(1),
                end_date: new Date().getTime(),
            }]);
        }

        if (_.contains(def, 'agoRoundDown')) {
            Array.prototype.unshift.apply(arr, [
                {
                    key: 'yearRoundDown',
                    value: 'One Year Ago',
                    scope: 'Ago From Today',
                    start_date: that.getDateYearsFromToday(1),
                    end_date: new Date().getTime(),
                }, {
                    key: 'years2RoundDown',
                    value: 'Two Years Ago',
                    scope: 'Ago From Today',
                    start_date: that.getDateYearsFromToday(2),
                    end_date: new Date().getTime(),
                }]);
        }

        if (_.contains(def, 'lastFull')) {
            Array.prototype.unshift.apply(arr, [{
                key: 'lastFullWeek',
                value: 'Last Full Week',
                scope: 'Last Full',
                start_date: moment(new Date()).subtract(1, 'week').startOf('week').add(1, 'd')._d.getTime(),
                end_date: moment(new Date()).subtract(1, 'week').endOf('week').add(1, 'd')._d.getTime(),
            }, {
                key: 'lastFullMonth',
                value: 'Last Full Month',
                scope: 'Last Full',
                start_date: moment(new Date()).subtract(1, 'month').startOf('month')._d.getTime(),
                end_date: moment(new Date()).subtract(1, 'month').endOf('month')._d.getTime(),
            },
            {
                key: 'lastFullQuarter',
                value: 'Last Full Quarter',
                scope: 'Last Full',
                start_date: (() => {
                    // the first element in the quarters filtered array is your Q2D, so the second is the last full quarter
                    const quarters = data.quarter.past;
                    const last_full_quarter = quarters[quarters.length - 2];
                    return last_full_quarter.start_date;
                })(),
                end_date: (() => {
                    // the first element in the quarters filtered array is your Q2D, so the second is the last full quarter
                    const quarters = data.quarter.past;
                    const last_full_quarter = quarters[quarters.length - 2];
                    return last_full_quarter.end_date;
                })()
            }]);
        }

        if (_.contains(def, 'toDate')) {
            Array.prototype.unshift.apply(arr, [{
                key: 'week2Date',
                value: 'Week to Date',
                scope: 'To Date',
                start_date: moment().startOf('week').add(1, 'd')._d.getTime(),
                end_date: moment().endOf('week').add(1, 'd')._d.getTime(),
            }, {
                key: 'month2Date',
                value: 'Month to Date',
                scope: 'To Date',
                start_date: moment().startOf('month')._d.getTime(),
                end_date: moment().endOf('month')._d.getTime(),
            }, {
                key: 'quarter2Date',
                value: 'Quarter to Date',
                scope: 'To Date',
                start_date: (() => {
                    const current_quarter = data.quarter.current;
                    return current_quarter.start_date;
                })(),
                end_date: (() => {
                    const current_quarter = data.quarter.current;
                    return current_quarter.end_date;
                })()
            }]);
        }

        if (_.contains(def, 'all')) {
            arr.unshift({
                key: ALL_TIME,
                value: 'All Time',
                start_date: (() => {
                    const last_quarter = data.quarter.past[1];
                    return last_quarter.start_date;
                })(),
                end_date: (() => {
                    const current_quarter = data.quarter.current;
                    return current_quarter.end_date;
                })()
            });
        }

        if (_.contains(def, 'custom')) {
            arr.unshift({
                key: 'time',
                value: 'Custom Range'
            });
        }

        angular.forEach((data.data ? data.data : data), (value, key) => {
            if (_.contains(def, key)) {
                const formatted = _.compact(value.past.map((item) => {
                    if (_.isActualObject(item)) {
                        item.scope = _.capitalize(key);
                    }
                    return item;
                })).reverse();

                const futureFormatted = _.compact(value.future.map((item) => {
                    item.scope = _.capitalize(key);
                    return item;
                })).reverse();

                if (future) {
                    angular.forEach(futureFormatted, (v) => {
                        arr.push(v);
                    });
                }

                angular.forEach(formatted, (v) => {
                    arr.push(v);
                });
            }
        });

        return arr;
    };

    this.getDateYearsFromToday = function (years) {
        var date = new Date();
        return date.setFullYear(date.getFullYear() - years);
    };

    this.sumObjects = function (arr, avg, n) {
        var result = arr.reduce(function (destination, source) {
            iter(source, destination);
            return destination;
        }, {});

        function iter(source, destination) {
            angular.forEach(source, function (value, key) {
                if (!destination) {
                    destination = {};
                }
                if (n && angular.isArray(n) && _.contains(n, key)) {
                    destination[key] = "";
                } else if (n && angular.isArray(n) && _.contains(n, '=' + key)) {
                    destination[key] = value;
                } else {
                    if (angular.isNumber(value)) {
                        destination[key] = (destination[key] ? destination[key] : 0) + value;
                    } else if (angular.isString(value)) {
                        // ignore all N/A
                        if (value !== 'N/A') {
                            destination[key] = value;
                        }
                    } else if (angular.isObject(value)) {
                        if (!destination[key]) {
                            destination[key] = {};
                        }
                        iter(value, destination[key]);
                    }
                }
            });
        }

        function average(a, parentKey) {
            angular.forEach(a, function (value, key) {
                if (angular.isObject(value)) {
                    average(value, key);
                } else {
                    if (angular.isArray(avg)) {
                        // eslint-disable-next-line no-unused-vars
                        angular.forEach(avg, function (v, i) {
                            if (!_.contains(v, '=') && _.contains(key, v) || _.contains(v, '=') && key == v.replace('=', '')) {
                                a[key] = a[key] / arr.length;
                            }
                        });
                    } else if (angular.isFunction(avg)) {
                        var computed = avg(a, value, key, result, parentKey);
                        if (computed !== null && computed !== undefined) {
                            a[key] = computed;
                        }
                    }
                }
            });
        }

        if (avg) {
            average(result);
        }

        return result;
    };

    this.subtractObjects = function (arr, n) {
        var result = _.reduce(arr, function (destination, source, i) {
            iter(source, destination, i);
            return destination;
        }, {});

        function iter(source, destination, i) {
            angular.forEach(source, function (value, key) {
                if (!destination) {
                    destination = {};
                }
                if (n && angular.isArray(n) && _.contains(n, key)) {
                    destination[key] = '--';
                } else if (n && angular.isArray(n) && _.contains(n, '=' + key)) {
                    destination[key] = value;
                } else {
                    if (angular.isNumber(value)) {
                        destination[key] = (destination[key] ? destination[key] : 0) + (!i ? value : -value);
                        destination[key] = destination[key] < 0 ? 0 : destination[key];
                    } else if (angular.isString(value)) {
                        destination[key] = value;
                    } else if (angular.isObject(value)) {
                        if (!destination[key]) {
                            destination[key] = {};
                        }
                        iter(value, destination[key], i);
                    }
                }
            });
        }
        return result;
    };

    this.stateUrl = function (name, go) {
        var s = $state.get(name);
        if (s && !s.data.noHistory && $storage.history && $storage.history[$rootScope.userData.organizationId] && $storage.history[$rootScope.userData.organizationId][name]) {
            if (go) {
                $state.go(name, that.getHistory(name), { inherit: false });
            } else {
                return $state.href(s, that.getHistory(name), { inherit: false });
            }
        } else {
            if (go) {
                $state.go(name, { inherit: false });
            } else {
                return $state.href(s, { inherit: false });
            }
        }
    };

    this.href = function (s, params) {
        if (params && $state.current.data && $state.current.data.filter) {
            // eslint-disable-next-line no-unused-vars
            var filters = $injector.get("filters");
            // angular.extend(params,filters.currentFilters());
        }
        var url = params ? $state.href(s, params, { inherit: false }) : $state.href(s, { inherit: false });
        if (_.contains(url, '%25')) {
            url = url.replace(/%25/g, '%');
        }
        return url;
    };

    this.getHistory = function (name) {
        return $storage.history[$rootScope.userData.organizationId][name];
    };

    this.setHistory = function (sname, params) {
        var h = angular.copy($storage.history),
            p = angular.copy(params);

        /*do not copy filters into history of URL */
        p = _.omitBy(p, function (value, key) {
            return _.startsWith(key, "filter");
        });
        if (!h) { return; }

        if (!h[$rootScope.userData.organizationId]) {
            h[$rootScope.userData.organizationId] = {};
        }

        h[$rootScope.userData.organizationId][sname] = p;
        $storage.history = h;
    };

    this.getUrlParams = function (url) {
        // http://stackoverflow.com/a/23946023/2407309
        if (typeof url == 'undefined') {
            url = window.location.search;
        }
        // eslint-disable-next-line no-redeclare
        var url = url.split('#')[0]; // Discard fragment identifier.
        var urlParams = {};
        var queryString = url.split('?')[1];
        if (!queryString) {
            if (url.search('=') !== false) {
                queryString = url;
            }
        }
        if (queryString) {
            var keyValuePairs = queryString.split('&');
            for (var i = 0; i < keyValuePairs.length; i++) {
                var keyValuePair = keyValuePairs[i].split('=');
                var paramName = keyValuePair[0];
                var paramValue = keyValuePair[1] || '';
                urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
            }
        }
        return urlParams;
    };

    this.lodash = function () {
        return _;
    };

    this.replaceLastState = function (fromState, toState) {
        var fromParams = $location.search(),
            toParams = toState.url.indexOf('?') !== -1 ? that.getUrlParams(toState.url) : false;

        if (!$.isEmptyObject(fromParams) && toParams) {
            var fromKeys = [],
                toKeys = [];

            angular.forEach(fromParams, function (v, k) {
                fromKeys.push(k);
            });

            angular.forEach(toParams, function (v, k) {
                toKeys.push(k);
            });

            return (_.intersection(fromKeys, toKeys).length > 0);
        } else {
            return false;
        }
    };

    // eslint-disable-next-line no-unused-vars
    this.queryString = function (params, url) {
        params = _.omit(params, ['tstamp']);
        var defer = $q.defer();
        //------------------------ tell the router to stop listening to url changes: top of run function application.js ------------------------//
        urlListener = false;

        $rootScope.$evalAsync(function () {
            if (!that.isSameState(params)) {
                defer.reject();
                urlListener = true;
                return;
            }

            /*=============================================
             =  OPTION 1: works but without using ui router methods
             =============================================*/
            var search = $location.search();

            //------------------------ merge new params into search params ------------------------//
            if (angular.isObject(params)) {
                angular.extend(search, params);
            }

            angular.forEach(search, function (value, key) {
                //------------------------ get rid of empty values ------------------------//
                if (value === null || value === undefined || value == '') {
                    delete search[key];
                }
            });

            //------------------------ replace them on the url ------------------------//
            $location.search(search).replace();

            //------------------------ sync $state.params with new params ------------------------//
            angular.extend($state.params, params);
            angular.extend($stateParams, params);

            /*=============================================
             =  OPTION 2: almost works using router methods but reloads current controller when navigating away
             =============================================*/
            // var o = {notify:false, reload:false, location:'replace', inherit:true};
            // $state.go($state.current.name,params,o);

            // angular.extend($state.params,$stateParams);

            /*=============================================
             =  stuff that has to happen no matter what
             =============================================*/

            //------------------------ update the last known state for this url in the history table ------------------------//
            if (!$state.current.data.erasePrevious) {
                that.setHistory($state.current.name, $stateParams);
            }

            $timeout(function () {
                //------------------------ update the link in the nav for this state based on the history ------------------------//
                $state.current.data.navUrl = that.stateUrl($state.current.name);
                //------------------------ tell the router to start listening again ------------------------//
                urlListener = true;
                $timeout(function () {
                    defer.resolve();
                }, 0, false);
            }, 0, false);
        });

        return defer.promise;
    };

    this.updateLocationAndStateParams = function (params) {
        params = _.omit(params, ['tstamp']);
        var defer = $q.defer();
        //------------------------ tell the router to stop listening to url changes: top of run function application.js ------------------------//
        urlListener = false;

        $rootScope.$evalAsync(function () {
            if (!that.isSameState(params)) {
                defer.reject();
                urlListener = true;
                return;
            }

            //------------------------ replace them on the url ------------------------//
            $location.search(params).replace();

            //------------------------ sync $state.params with new params ------------------------//
            $state.params = params;

            //------------------------ update the last known state for this url in the history table ------------------------//
            if (!$state.current.data.erasePrevious) {
                that.setHistory($state.current.name, $state.params);
            }

            $timeout(function () {
                //------------------------ update the link in the nav for this state based on the history ------------------------//
                $state.current.data.navUrl = that.stateUrl($state.current.name);
                //------------------------ tell the router to start listening again ------------------------//
                urlListener = true;
                $timeout(function () {
                    defer.resolve();
                }, 0, false);
            }, 0, false);
        });

        return defer.promise;
    };

    this.isSameState = function (params) {
        var keys = Object.keys(params),
            toParams = Object.keys($state.params),
            same = true;

        // eslint-disable-next-line no-unused-vars
        angular.forEach(keys, function (p, i) {
            if (!_.contains(p, 'filter') && !_.contains(toParams, p)) {
                $log.warn('Param "' + p + '" prevented the url from changing because it can not be found in ' + $state.current.name + '\'s route defenition.');
                same = false;
            }
        });

        return same;
    };

    this.param = {
        toObject: function (query) {
            var obj = {};
            var params = query.split("&");
            angular.forEach(params, function (param) {
                var data = param.split("=");
                if (obj[data[0]]) {
                    if ($.isArray(obj[data[0]])) {
                        obj[data[0]].push(data[1]);
                    } else {
                        var temp = obj[data[0]];
                        obj[data[0]] = [];
                        obj[data[0]].push(temp);
                        obj[data[0]].push(data[1]);
                    }
                } else {
                    obj[data[0]] = data[1];
                }
            });
            return obj;
        },

        // eslint-disable-next-line no-undef
        toQueryString: objToQuery
    };


    this.ipad = function () {
        var ua = navigator.userAgent;
        return ua.match(/iPad/i);
    };

    this.uncamel = function (text) {
        var result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    };

    this.base64ToBinary = function (dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    };

    this.b64toBlob = function (b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    this.combineFilters = function (array1, array2) {
        var final = [];
        var temp = array1.slice(0).concat(array2);
        var nameSet = {};
        angular.forEach(temp, function (item) {
            var filter = nameSet[item.name];
            if (!filter) {
                filter = jQuery.extend({}, item);
                final.push(filter);
                nameSet[filter.name] = filter;
            } else {
                nameSet[filter.name].type = "b";
            }
        });
        return final;
    };

    this.createMonthCohorts = function (cohort, plus) {
        var cohorts = [];
        cohorts.push(cohort);
        for (var i = 0; i < plus; i++) {
            var quarter = cohort.substr(1, 1);
            var year = cohort.substr(2, 2);
            quarter++;
            if (quarter == 5) {
                quarter = 1;
                year++;
            }
            cohort = "Q" + quarter + year;
            cohorts.push(cohort);
        }
        return cohorts;
    };

    //just like angular.extend, but doesnt copy empty values
    this.combine = function (dts, src) {
        for (var attr in src) {
            if (src[attr]) {
                dts[attr] = src[attr];
            }
        }
    };

    this.cohortsForUI = function (cohorts) {
        var final = [];
        cohorts.quarter.past.reverse();
        cohorts.year.past.reverse();
        if ($.isPlainObject(cohorts.quarter.current)) {
            final.push(cohorts.quarter.current);
        }
        cohorts.quarter.past.forEach(function (cohort) {
            if (cohort.key.indexOf("Q4") > -1) {
                if ($.isPlainObject(cohorts.year.current)) {
                    final.push(cohorts.year.current);
                    cohorts.year.current = null;
                } else {
                    if (cohorts.year.past.length > 0) {
                        final.push(cohorts.year.past.shift());
                    }
                }
            }
            final.push(cohort);
        });
        if (cohorts.year.past.length > 0) {
            final.push(cohorts.year.past.shift());
        }
        return final;
    };

    this.paramsKeyMapper = function (parent, current) {
        if (_.has(parent, 'model') && _.has(current, 'modelType')) {
            parent.model = current.modelType;
        }
    };

    this.currencySymbol = function (currency) {
        var currencyMappings = {
            "USD": "$",
            "EURO": "€"
        };
        if (currencyMappings[currency]) {
            return currencyMappings[currency];
        } else { //default to USD
            return "$";
        }
    };

    /**
     * chunkData() takes an array and splits the array into data chunks based on size
     *
     */
    this.chunkData = function (obj, data, size, destination, index) {
        var chunks = _.chunk(data, size);

        // eslint-disable-next-line no-unused-vars
        angular.forEach(chunks, function (page, i) {
            _.set(obj, destination, page);
            destination[index]++;
        });
    };

    this.checkStateAndStatusForAddition = function (state, userData) {
        return this.checkStateForAddition(state, userData) && state.status === 'enabled';
    };

    this.checkStateForAddition = function (state, userData) {
        return state.config.orgTypes.includes(userData.platform);
    };

    this.isTrueOrNotExist = function (value) {
        return value === true || value === null || value === undefined;
    };

    this.removeFilterValues = function (params) {
        var cleanParams = {};
        for (var k in params) {
            if (k.indexOf("filter") !== 0) {
                cleanParams[k] = params[k];
            } else {
                cleanParams[k] = [];
            }
            if (k = "al") {
                cleanParams[k] = "";
            }
        }
        return cleanParams;
    };

    this.shouldShowPermission = function (key, platform) {
        if (key !== 'weekly_emails' && key !== 'goals') {
            return key === 'web_tracking' ? platform === 'full' : true;
        }
        return false;
    };

    /**
     * Check if current state is report needs to appear in different sections of url and ui
     * i.e. 'discover/revenue-and-pipeline/marketing-impact' -> 'analyze/revenue-and-pipeline/marketing-impact'
     */
    function isReportRelocated(state) {
        return state.name === 'app.analyze.revenueAndPipeline.marketingImpact';
    }

    /**
     * Adjust Filters for users that have report access that need to appear in different sections of url and ui
     * i.e. 'discover/revenue-and-pipeline/marketing-impact' -> 'analyze/revenue-and-pipeline/marketing-impact'
     */
    function filterStateToReport (state, origin, target) {
        return state.name.slice().replace(target, origin);
    }

    /**
    * Filter states that the user has permission to use.
    * @param {object} parent The root state for the application.
    * @param {object} userData All data about the user.
    */
    this.filterStates = function (userData, parent, _, $stateProvider) {
        let iter, reportName;
        if (parent.children) {
            iter = parent.children;
        } else if (parent.tabs) {
            iter = parent.tabs;
        }
        if (iter) {
            iter.forEach(function (state) {
                reportName = isReportRelocated(state) ? filterStateToReport(state, 'discover', 'analyze') : state.name;
                const influenceType = 'feature-model-influence_type';
                const influenceTypeLimited = 'feature-model-influence_type_limited';
                const shouldEnableAttributionByCampaignType = state.name === 'app.analyze.campaigns.attributionByQuarter'
                    && (
                        !$rootScope.orgConfig
                        || (
                            isFeatureEnabled(influenceType)
                            || isFeatureEnabled(influenceTypeLimited)
                        )
                    );

                if (
                    state.config.alwaysAdd ||
                    userData.hiddenFeatures.route_access[reportName] ||
                    shouldEnableAttributionByCampaignType
                ) {
                    state.status = state.config.alwaysAdd || shouldEnableAttributionByCampaignType
                        ? "enabled"
                        : userData.hiddenFeatures.route_access[reportName];
                    state.config.status = state.status;
                    state.config.data.parent = parent.name;
                    if (parent.config.tabs) {
                        let passed = _.filter(parent.children, function (tab) {
                            // Checking if the organization CRM Type is allowed for the tab
                            let allowAccessForCrmType = !tab.config.organizationCrmType ||
                                (userData.crm && tab.config.organizationCrmType === userData.crm.type);
                            return tab.config.orgTypes.includes(userData.platform) &&
                                allowAccessForCrmType;
                        });
                        state.config.data.tabSet = _.map(passed, function (tab) {
                            return tab.name;
                        });
                    }

                    if (
                        !$state.href(state.name, { inherit: false })
                        && (
                            state.config.url
                            || state.config.noUrl
                        )
                    ) {
                        $stateProvider.state(state.name, state.config);
                    }
                    that.filterStates(userData, state, _, $stateProvider);
                } else {
                    state.status = "removed";
                }
            });
        }
    };

    this.isDataStudioReport = function (stateName) {
        const dataStudioReportList = [
            'app.analyze.accounts',
            'app.analyze.accounts.attribution',
            'app.analyze.accounts.accountJourney',
            'app.analyze.accountsAccountSpecific',
            'app.analyze.accountsAccountSpecific.attribution',
            'app.analyze.accountsAccountSpecific.accountJourney',
            'app.analyze.accountsAccountSpecific.trending',
        ];

        return dataStudioReportList.indexOf(stateName) > -1;
    };

    this.isMeasurementStudioReport = function (stateName) {
        const measurementStudioReportList = [
            'app.analyze.opportunities.listAnalysis',
            'app.analyze.revenueAndPipeline.marketingImpact',
            'app.discover.revenueAndPipeline.marketingImpact',
            'app.analyze.campaigns.listAnalysis',
            'app.analyze.campaigns.listAnalysis.campaigns',
            'app.analyze.campaigns.listAnalysis.campaignGroups',
            'app.analyze.campaigns.trendingAnalysis',
            'app.analyze.campaigns.attributionByQuarter',
            'app.analyze.campaigns.campaignSpecific',
            'app.analyze.campaigns.campaignSpecific.trending',
            'app.discover.revenueAndPipeline.attributionByQuarter',
            'app.discover.revenueAndPipeline.attributionTrends',
            'app.discover.stageProgression.stagesSnapshot',
            'app.discover.stageProgression.attribution',
            'app.discover.stageProgression.trending',
            'app.discover.stageProgression.trendingDetails',
            'app.analyze.accounts.trending',
            'app.analyze.webTracking.webActivity',
            'app.analyze.webTracking.webActivity.channel',
            'app.analyze.webTracking.webActivity.channelAssets',
            'app.analyze.webTracking.webActivityTrending',
        ];

        return measurementStudioReportList.indexOf(stateName) > -1;
    };

    this.isSettingsPage = function (stateName) {
        const settingPages = [
            'app.settings',
            'app.settings.profile',
            'app.settings.permissions',
            'app.settings.permissions.teamPermissions',
            'app.settings.stages',
            'app.settings.pagesAndFeatures',
            'app.settings.pagesAndFeatures.userInterface',
            'app.settings.pagesAndFeatures.salesActivity',
            'app.settings.dataHygiene',
            'app.settings.dataHygiene.orphanLead',
            'app.settings.attributionWeight',
            'app.settings.webTracking',
            'app.settings.globalFilters',
            'app.settings.godMode',
            'app.settings.internalConfigure',
            'app.settings.internalConfigure.permissions',
            'app.settings.internalConfigure.dataCycle',
            'app.settings.internalConfigure.olf',
            'app.settings.internalConfigure.waterfall',
            'app.settings.internalConfigure.demo',
            'app.settings.internalConfigure.users',
            'app.settings.internalConfigure.organizations',
            'app.settings.internalConfigure.sqlConfigs',
            'app.settings.internalConfigure.sqlConfigs.mySqlConfig',
            'app.settings.internalConfigure.sqlConfigs.mySqlDiff',
            'app.settings.internalConfigure.sqlConfigs.impalaConfig',
            'app.settings.internalConfigure.sqlConfigs.impalaDiff',
            'app.settings.internalConfigure.cache',
            'app.settings.internalConfigure.webTracking',
            'app.settings.internalConfigure.sfdcConfigure',
            'app.settings.internalConfigure.bombora',
            'app.settings.internalConfigure.tokenManager',
            'app.settings.selfConfigure',
            'app.settings.selfConfigure.fieldMapping',
            'app.settings.selfConfigure.accountEngagement',
            'app.settings.selfConfigure.bombora',
            'app.settings.selfConfigure.validityConfig',
            'app.settings.selfConfigure.campaignValid',
            'app.settings.selfConfigure.campaignMemberValid',
            'app.settings.selfConfigure.contactValid',
            'app.settings.selfConfigure.leadValid',
            'app.settings.selfConfigure.opportunityValid',
            'app.settings.selfConfigure.integrations',
            'app.settings.selfConfigure.sfdc',
            'app.settings.selfConfigure.eloqua',
            'app.settings.selfConfigure.marketo',
            'app.settings.selfConfigure.dynamics',
            'app.settings.selfConfigure.globalFilters',
            'app.settings.selfConfigure.accountFilters',
            'app.settings.selfConfigure.campaignFilters',
            'app.settings.selfConfigure.campaignMemberFilters',
            'app.settings.selfConfigure.leadFilters',
            'app.settings.selfConfigure.opportunityFilters',
        ];

        return settingPages.indexOf(stateName) > -1;
    };

    this.filterSubNav = function (userData, subnav) {
        const STUDIO_STATE_MEASUREMENT_STUDIO = 'MEASUREMENT_STUDIO';
        const routeAccess = userData.hiddenFeatures.route_access;
        let filteredSubNav = $filter('filter')(subnav, function (state) {
            return state.config.subnav === true || (state.config.abstract === true && state.config.subnav !== false);
        });

        angular.forEach(filteredSubNav, function (cat, i) {
            if (cat.children && cat.children.length) {
                subnav[i].children = $filter('filter')(cat.children, function (child) {
                    if ($rootScope.studioState === STUDIO_STATE_MEASUREMENT_STUDIO) {
                        child.children = child.children.filter((item) => {
                            let itemName = item.name.slice();
                            const influenceType = 'feature-model-influence_type';
                            const influenceTypeLimited = 'feature-model-influence_type_limited';
                            const shouldEnableAttributionByCampaignType = itemName === 'app.analyze.campaigns.attributionByQuarter'
                                && (
                                    isFeatureEnabled(influenceType)
                                    || isFeatureEnabled(influenceTypeLimited)
                                );

                            if ($rootScope.userData.platform === 'campaign') {
                                if (itemName === 'app.analyze.revenueAndPipeline.marketingImpact') {
                                    itemName = itemName.replace('analyze', 'discover');
                                } else if (
                                    itemName === 'app.analyze.campaigns.attributionByQuarter'
                                    && shouldEnableAttributionByCampaignType
                                ) {
                                    return routeAccess['app.discover.revenueAndPipeline.attributionByQuarter']
                                        || routeAccess['app.analyze.campaigns.attributionByQuarter'];
                                }
                            } else if ($rootScope.userData.platform === 'standard') {
                                if (
                                    itemName === 'app.analyze.opportunities.listAnalysis'
                                    || itemName === 'app.measurementStudio.advertisingInsights'
                                ) {
                                    return routeAccess[itemName];
                                } else {
                                    return false;
                                }
                            }

                            return routeAccess[itemName];
                        });
                    }
                    return child.config.subnav !== false;
                });
            }
        });

        return filteredSubNav;
    };

    return that;
}]);
