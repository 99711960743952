/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').controller('activityTabController',activityTabController);

    activityTabController.$inject = ['activityTabService', '$scope',
        'AccountJourneyConstants', 'accountJourneyService'];

    /**
     * Controller.
     * @param $scope
     */
    function activityTabController(activityTabService, $scope,
        AccountJourneyConstants, accountJourneyService) {
        var self = this;
        self.displayedCollection = [];
        self.data = [];
        self.message = AccountJourneyConstants.noData.LABELS.ACTIVITY_NO_DATA;

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return value ? moment(value.date).valueOf() : undefined;
            }
        };

        self.dataFilter = {};
        self.dataFilter[AccountJourneyConstants.TouchTypeMarketing] = {
            displayText: 'Show Marketing Activities',
            active: true
        };
        self.dataFilter[AccountJourneyConstants.TouchTypeSales] = {
            displayText: 'Show Sales Activities',
            active: true
        };
        self.dataFilter[AccountJourneyConstants.TouchTypeAnonymous] = {
            displayText: 'Show Anonymous Activities',
            active: true
        };
        self.dataFilter[AccountJourneyConstants.TouchTypeOpportunity] = {
            displayText: 'Show Opportunities',
            active: true
        };
        accountJourneyService.getData().then(function(data) {
            self.data = activityTabService.mapActivityData(data);
            self.filterClicked();

        });
        self.filterClicked = function () {
            // Select only the filters that are active
            var selectedFilters = Object.entries(self.dataFilter)
                .filter(function (filter) {
                    return filter[1].active;
                }).reduce(function (acc, cur) {
                    acc[cur[0]] = true;
                    return acc;
                }, {});


            // Apply the filters to the data set
            self.rowCollection = self.data.filter(function (row) {
                return !!selectedFilters[row.touchType];
            });
        };
        $scope.exportActivityName = function(row) {
            return row.activityName + (row.displayPageViewLabel ? ' Page Views' : '');
        };
    }
})();

