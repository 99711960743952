/**
 * This is a basic service to handle authentication requests with JWT.
 */
(function (w, bf) {
    'use strict';

    class LoginToken {
        constructor(status, token) {
            this.status = status;
            this.params = token;
        }
    }

    angular
        .module('jwt')
        .factory('authSrvc', authSrvc);

    authSrvc.$inject = ['$http', '$window', '$cookies', 'storageSrvc',
        'Constants', '$timeout', '$log', '$q', 'domainSrvc',
        'splitIoSrvc', 'platformBrowserIdentitySrvc', 'oktaSrvc',
        'environmentSrvc'];

    function authSrvc($http, $window, $cookies, storageSrvc,
        Constants, $timeout, $log, $q, domainSrvc,
        splitIoSrvc, platformBrowserIdentitySrvc, oktaSrvc,
        environmentSrvc) {
        /**
         * Exposed Services
         * @type {!Object}
         */
        var service = {},
            /**
             * User Tracker. Checks if the user in the JWT changed and reloads the page
             * @type {string} The last user
             */
            userTracker = undefined,
            /**
             * The backend pod to use
             * @type {string}
             */
            bfPod = undefined;

        var jwtExpirationPromise;

        // Flag to determine if there has been any user activity. Set to true if a request is seen by the interceptor
        var userIsActive = true;

        // Add or update the jwt for the given app.
        // They are mapped by appName under jwtToken.tokens
        function saveOrUpdateToken(appName) {
            var token = $cookies.get(Constants.COOKIE_NAME);
            if (appName && token) {
                userTracker = service.parseJwt(token)[Constants.SUB];
                bfPod = service.parseJwt(token)[Constants.BF_POD];
                var tokens = getJwtMapFromStorage();
                tokens[appName] = token;
                saveJwtMapToStorage(tokens);
                return true;
            }
            return false;
        }

        // Deletes the jwt for the given appName
        function deleteTokenFromStorage(appName) {
            var tokens = getJwtMapFromStorage();
            if (tokens[appName]) {
                delete tokens[appName];
                userTracker = undefined;
                bfPod = undefined;
                saveJwtMapToStorage(tokens);
            }
        }

        /**
         * Enables mocking this method to disable redirects in unit tests
         * @param url The url to redirect
         * @param newTab True if launched in a new tab, false otherwise
         */
        service.redirect = function (url, newTab) {
            if(!newTab) {
                newTab = false;
            }
            $window.open(url, newTab ? '_blank' : '_self');
        };

        // Retrieve the jwt for the given appName (example: 'Terminus Hub')
        function retrieveToken(appName) {
            var tokens = getJwtMapFromStorage(),
                token = tokens[appName],
                tokenObj = service.parseJwt(token);
            if (userTracker !== tokenObj[Constants.SUB]) {
                return undefined;
            }
            return token;
        }

        // Retrieve the jwt tokens map store under jwtTokens.tokens
        function getJwtMapFromStorage() {
            var tokens = storageSrvc.load(Constants.TOKEN_STORAGE_KEY);
            return tokens ? JSON.parse(tokens).jwtTokens.tokens : {};
        }

        // Save the jwt tokens map to jwtTokens.tokens
        function saveJwtMapToStorage(tokens) {
            storageSrvc.save(Constants.TOKEN_STORAGE_KEY, JSON.stringify({
                jwtTokens: {
                    tokens: tokens
                }
            }));
        }

        /**
         * Get a number of milliseconds, 5 minutes before the token expires or 0
         * if the value is negative.
         * A negative value means that there is less than 5 minutes to expiration
         * or it already expired.
         *
         * @return {number} Milliseconds to renew token, or 0 if now.
         */
        function getJwtExpirationAsDuration() {
            var duration = service.expirationDate(Constants.TERMINUS_HUB).getTime() -
                new Date().getTime() - 300000;
            return duration > 0 ? duration : 0;
        }

        // Notify the caller that the token should expire
        // after it's duration is over
        function renewTokenBeforeExpiration() {
            if (jwtExpirationPromise) {
                $timeout.cancel(jwtExpirationPromise);
            }
            jwtExpirationPromise = $timeout(function () {
                if (userIsActive) {
                    service.renewToken(Constants.TERMINUS_HUB).then(function () {
                        userIsActive = false;
                        renewTokenBeforeExpiration();
                    });
                }
            }, getJwtExpirationAsDuration(), false);
        }

        function getJwtExpirationPromise() {
            return jwtExpirationPromise;
        }

        /**
         * Support function to remove the token and redirect to the
         * login page.
         */
        function removeTokenAndGoToLogin() {
            service.unauthenticate(Constants.TERMINUS_HUB).then(function () {
                service.redirect(Constants.LOGIN_URL);
            });
        }

        /**
         * Function to authenticate the user. On success, it adds the jwt to local storage and
         * prepends the jwt to the following http requests.
         * @returns {Promise<any> | PromiseLike<any>} A promise with success or error.
         */
        service.renewToken = function (appName) {
            return $http.get(Constants.API_URL).then(function (response) {
                service.writeJwtToCookie(response.data.token);
                saveOrUpdateToken(appName);
                return response.data;
            }, function (response) {
                if (response.status === 401) {
                    removeTokenAndGoToLogin();
                }
                $log.error(response.data);
            });
        };

        /**
         * Function to unauthenticate the user. It removes the jwt from future http requests and from the storage
         */
        service.unauthenticate = function (appName) {
            $timeout.cancel(jwtExpirationPromise);
            deleteTokenFromStorage(appName);
            $cookies.remove(Constants.COOKIE_NAME, {domain: domainSrvc.getCookieDomain()});
            $cookies.remove(Constants.AUTH_CONT_COOKIE_NAME, {domain: domainSrvc.getCookieDomain()});
            return $q.when();
        };

        /**
         * Checks if a user is authenticated. That is done by checking if the jwt is in storage.
         * The http header is also updated in case of a page refresh
         * @returns {boolean} True is the token is in storage.
         */
        service.isAuthenticated = function (appName) {
            var tokenCookie = $cookies.get(Constants.COOKIE_NAME);
            var token = retrieveToken(appName);
            return (typeof token) !== "undefined" && token === tokenCookie;
        };

        /**
         * Parses a jwt and gets the data in the claims
         * @return {any} The claims to show"exp" (Expiration Time) Claim
         */
        service.parseJwt = function (token) {
            if (token && token !== "\"\"") {
                var base64Url = token.split('.')[1];
                if (base64Url) {
                    var base64 = base64Url.replace('-', '+').replace('_', '/');
                    return JSON.parse(window.atob(base64));
                }
                return '';
            } else {
                return '';
            }
        };

        /**
         * Get a Js Date Object with the JWT expiration date
         * @param jwt The Jwt
         * @returns {Date} The Js Expiration Date
         */
        function getJwtExpirationJsDate(jwt) {
            const tokenObj = service.parseJwt(jwt);
            return new Date(tokenObj.exp * 1000);
        }

        /**
         * Gets the Expiration Date from the token
         * @return {Date} The expiration date
         */
        service.expirationDate = function (appName) {
            var tokenCookie = $cookies.get(Constants.COOKIE_NAME),
                tokenStorage = retrieveToken(appName);
            if (tokenStorage && tokenCookie) {
                // Sync jwts
                if (tokenStorage !== tokenCookie) {
                    saveOrUpdateToken(appName);
                }
                return getJwtExpirationJsDate(tokenStorage);
            } else {
                return new Date();
            }
        };

        function checkToken(appName) {
            return new Promise((resolve, reject) => {
                const isExpired = service.isTokenExpired(appName);
                if (isExpired) {
                    reject(new LoginToken(Constants.TOKEN_EXPIRED, null));
                } else {
                    resolve(new LoginToken(Constants.LOGIN_TOKEN, null));
                }
            });
        }

        /**
         * Post the identity of the user.
         * @param params The parameters to post
         * @return {Promise<*>} A promise which will resolve/reject based on the
         *    request status.
         */
        service.getTerminusTokenFromOkta = function (params) {
            return environmentSrvc.getVariable(Constants.GRAILS_URL_VAR).then((baseHost) => {
                return $http.post(baseHost + Constants.UNIFIED_LOGIN_API_URL,
                    JSON.stringify(params),
                    { withCredetials: false }).then(function (response) {
                    service.writeJwtToCookie(response.data.token);
                    saveOrUpdateToken(Constants.TERMINUS_HUB);
                    $cookies.put(Constants.AUTH_CONT_COOKIE_NAME, Constants.OKTA_USER, {
                        path: '/',
                        domain: domainSrvc.getCookieDomain(),
                        expires: getJwtExpirationJsDate(response.data.token),
                    });
                    return response.data.token;
                });
            });
        };

        /**
         * Checks if the user is logged in.
         * It tests if the user is under `unified_login` or uses a regular login.
         * @param appName The application name allocated for the cookie
         * @returns {Promise<string>} A promise with the login type
         */
        service.isLoggedIn = function (appName) {
            if (w[bf] && w[bf].testing) {
                return Promise.resolve(new LoginToken(Constants.LOGIN_TOKEN, null));
            } else {
                return checkToken(appName).catch((tokenExpired) => {
                    const userType = $cookies.get(Constants.AUTH_CONT_COOKIE_NAME);
                    if (userType !== Constants.EMPLOYEE_USER) {
                        return platformBrowserIdentitySrvc.getIdentity().then((identityObj) => {
                            return splitIoSrvc.loadSDK(identityObj.identifier).then(() => {
                                return splitIoSrvc.getTreatment(Constants.SPLIT_NAME).then((data) => {
                                    if (data) {
                                        return oktaSrvc.hasSession().then((isSessionValid) => {
                                            if(isSessionValid) {
                                                return oktaSrvc.getToken().then((token) => {
                                                    return service.getTerminusTokenFromOkta({ token: token }).then((terminusJwt) => {
                                                        const jwt = service.parseJwt(terminusJwt);
                                                        platformBrowserIdentitySrvc.getIdentityDecorate(jwt['sub.org']);
                                                        return new LoginToken(Constants.LOGIN_TOKEN, token);
                                                    }, () => {
                                                        throw new LoginToken(Constants.MISSING_PERMISSIONS, token);
                                                    });
                                                }, (error) => {
                                                    throw new LoginToken(Constants.LOGIN_PANIC, error);
                                                });
                                            } else {
                                                throw new LoginToken(Constants.SESSION_EXPIRED, window.location.href);
                                            }
                                        }, (error) => {
                                            throw new LoginToken(Constants.LOGIN_PANIC, error);
                                        });
                                    } else {
                                        // Something is not right, redirecting to employee entrance
                                        throw tokenExpired;
                                    }
                                }, () => {
                                    // Something is not right, redirecting to employee entrance
                                    throw tokenExpired;
                                });
                            }, () => {
                                // Something is not right, redirecting to employee entrance
                                throw tokenExpired;
                            });
                        }, () => {
                            // Something is not right, redirecting to employee entrance
                            throw tokenExpired;
                        });

                    } else {
                        throw tokenExpired;
                    }
                });
            }
        };

        /**
         * Redirect to the correct login page
         * @param error The error received
         * @param params Endpoint or params to send.
         */
        service.goToEndpoint = function (error, params, newTab) {
            let query = '';
            let email = '';
            let key = '';
            switch (error.status) {
            case Constants.MISSING_PERMISSIONS:
                email = service.parseJwt(error.params).email;
                environmentSrvc.getVariable(Constants.UNIFIED_LOGIN_URL).then((host) => {
                    service.redirect(host + Constants.LEARN_MORE_ENDPOINT +
                        Constants.LEARN_MORE_QUERY + encodeURI(email ? email.substr(email.indexOf('@') + 1) : ''), newTab);
                });
                break;
            case Constants.SESSION_EXPIRED:
                key = 'terminus-' + Date.now().toString();
                storageSrvc.save(key, params || error.params);
                environmentSrvc.getVariable(Constants.UNIFIED_LOGIN_URL).then((host) => {
                    service.redirect(host + Constants.LOGIN_ENDPOINT +
                        Constants.LOGIN_QUERY + encodeURI(key), newTab);
                });
                break;
            case Constants.TOKEN_EXPIRED:
                if (params) {
                    query = `?targetUri=${encodeURI(params)}`;
                }
                service.redirect(Constants.LOGIN_URL + query, newTab);
                break;
            case Constants.LOGIN_PANIC:
                query = '?error_msg=' + encodeURI(error.params);
                service.redirect(Constants.PANIC_URL + query, newTab);
                break;
            }
            return;
        };

        /**
         * Checks the Expiration Date from the token
         * @return {boolean} True is the token has less than 5 minutes of expiration time
         *  or no token exists, false otherwise
         */
        service.isTokenExpired = function (appName) {
            return service.expirationDate(appName) <= new Date();
        };

        /**
         * Checks if the cookie exists. Stores it in local storage.
         */
        service.storeToken = function (appName) {
            if (saveOrUpdateToken(appName)) {
                renewTokenBeforeExpiration();
            }
        };

        /**
         * Delete the token from local storage and cookie.
         */
        service.removeToken = function (appName) {
            if (!appName) return;
            var token = $cookies.get(Constants.COOKIE_NAME);
            if (token) {
                $cookies.remove(Constants.COOKIE_NAME, {domain: domainSrvc.getCookieDomain()});
            }
            deleteTokenFromStorage(appName);
        };

        /**
         * Get the Authorization Header with the token.
         */
        service.getAuthorization = function (appName) {
            var token = retrieveToken(appName);
            return token ? Constants.HEADER + token : undefined;
        };

        /**
         * Use the url to determine which of the tokens from the list of apps
         * to use in the request
         */
        service.getAuthorizationFromUrl = function (url) {
            if (!url) {
                return undefined;
            }
            userIsActive = true;
            return service.getAuthorization(Constants.TERMINUS_HUB);
        };

        /**
         * Return the token for this app as a json object
         */
        service.getParsedJwt = function (appName) {
            var token = retrieveToken(appName);
            return service.parseJwt(token);
        };

        /**
         * Retrieve the JWT.
         * @param appName The appName related to the JWT
         * @return {string} The JWT as is.
         */
        service.getJwtToken = function (appName) {
            return retrieveToken(appName);
        };

        /**
         * Logout the user.
         * If the operation success or not, always remove the JWT.
         */
        service.logout = function () {
            const userType = $cookies.get(Constants.AUTH_CONT_COOKIE_NAME);
            if (userType === Constants.EMPLOYEE_USER) {
                removeTokenAndGoToLogin();
            } else {
                splitIoSrvc.getTreatment(Constants.SPLIT_NAME).then((data) => {
                    if (data) {
                        environmentSrvc.getVariable(Constants.UNIFIED_LOGIN_URL).then((url) => {
                            service.redirect(url + Constants.LOGOUT_ENDPOINT);
                        }, () => {
                            removeTokenAndGoToLogin();
                        });
                    } else {
                        removeTokenAndGoToLogin();
                    }
                });
            }
        };

        /**
         * If the url is for a static resource or an absolute url, do not change it.
         * If the url is a call to the backend add the bfpod data.
         *
         * An additional check is done to see if the url starts with '/', if not add.
         *
         * @param url The url to check if needs to change
         * @returns {boolean} True if it needs to change, false otherwise.
         */
        service.needsToChangeUrl = function (url) {
            return !(/(^http)|(\.(gif|jpe?g|tiff|png|svg|css|js|html?|ttf|woff|json)\??.*$)/i).test(url);
        };

        /**
         * Retrieve the url to fetch the data or static file
         * @param url The url to update
         * @returns {string} The updated url
         */
        service.getUrlToUse = function (url) {
            if (service.needsToChangeUrl(url)) {
                url = url.startsWith("/") ? url : "/" + url;
                return (bfPod || "") + url;
            } else {
                return url;
            }
        };

        /**
         * Writes token contained in authenticate response to a cookie.
         *
         * @param jwt The token to store in a cookie.
         */
        service.writeJwtToCookie = function (jwt) {
            $cookies.put(Constants.COOKIE_NAME, jwt, {
                path: '/',
                domain: domainSrvc.getCookieDomain(),
                expires: getJwtExpirationJsDate(jwt),
            });
        };

        service.__testonly__ = {
            jwtExpirationPromise: getJwtExpirationPromise,
            setTracker: (tracker) => { userTracker = tracker; }
        };

        return service;
    }
})(window, 'brightfunnel');
