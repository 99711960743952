"use strict";

// userAuth
import "jquery";
import "angular";
import "angular-cookies";
import "angular-sanitize";
import "noty/js/noty/packaged/jquery.noty.packaged.js";
import "../../lib/angular.noty.js";

// Dependencies
// Define module jwt just to use the domain service
import 'Environment/index';

// App
import "./app.js";
import 'Jwt/domain-srvc';
import "./user-auth.less";

// userAuthDirectives
import "./services/constants.js";
import "./services/auth-srvc.js";
import "./directives/form-button/form-button-ctrl.js";
import "./directives/form-checkbox/form-checkbox-ctrl.js";
import "./directives/form-container/form-container-ctrl.js";
import "./directives/form-error/form-error-ctrl.js";
import "./directives/form-header/form-header-ctrl.js";
import "./directives/form-input/form-input-ctrl.js";
