/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').filter('sortByDate', sortByDate);

    sortByDate.$inject = [];

    function sortByDate() {
        return function(data) {
            if(!data) {
                return [];
            }
            data.sort(function(a, b) {
                return moment(a.date).isAfter(moment(b.date)) ? 1 : -1;
            });
            return data;
        };
    }

})();


