/**
 * Handles local storage in a simple way with (key, value) items.
 */
(function() {
    'use strict';

    angular
        .module('jwt')
        .factory('storageSrvc', storageSrvc);

    storageSrvc.$inject = ['$window'];

    /**
     * Service to handle local storage.
     * @param $window The browser window.
     * @returns {{load: (function(*): *), save: save}}
     */
    function storageSrvc($window) {
        var service = { };

        /**
         * Remove double quotes from the begin/end of string in localstorage
         * @param str The string to remove double quotes
         * @returns {string} The string without double quotes
         */
        function removeQuotes(str) {
            if (str) {
                str = str.replace(/^"?([^"]*)"?$/,'$1');
            } else {
                str = undefined;
            }
            return str;
        }
        /* __start-test-block__ */
        service.__test_only__ = { removeQuotes };
        /* __end-test-block__ */
        /**
         * Loads/gets a value from local storage
         * @param item The key to retrieve the value
         * @returns {*} The value associated with the key
         */
        service.load = function(item) {
            var str = $window.localStorage[item];
            str = removeQuotes(str);
            return str;
        };

        /**
         * Sets the value for a (key, value) pair. If the value is null, the key is removed from
         * local storage.
         *
         * @param item The key to use
         * @param value The value to set
         */
        service.save = function(item, value) {
            if(value) {
                $window.localStorage[item] = value;
            } else {
                $window.localStorage.removeItem(item);
            }
        };

        return service;
    }
})();
