/* eslint-disable no-undef */
angular.module('accountJourney')
    .directive('chartComponent', ['$window', 'journeyHighlightService', 'c3ChartManager', function ($window, journeyHighlightService, c3ChartManager) {
        return {
            link: chartLnk,
            restrict: 'E',
            scope: {
                chart: '=',
                chartConfig: '=',
                chartName: '@',
                yAxisLabel: '@',
                y2AxisLabel: '@'
            },
            template: require('./chart-component.html'),
            controller: chartController
        };

        function chartController() {
            var self = this;

            self.initChart = function (scope, tooltipEventHandlerFn) {
                var chartConfig = scope.chartConfig;

                chartConfig.bindto = '#' + scope.chartName;

                chartConfig.axis.y.label.text = scope.yAxisLabel || '';
                chartConfig.axis.y2.label.text = scope.y2AxisLabel || '';

                // Show x and y axis lines for bar and journey graphs - journey graphs will have a data.types property
                scope.showAxisLines = chartConfig.data.type === 'bar' || chartConfig.data.types;

                if (tooltipEventHandlerFn) {
                    scope.chartConfig.onmouseover = tooltipEventHandlerFn;
                }
                self.chart = c3ChartManager.renderChart(scope);
            };

            self.destroyChart = function () {
                if (self.chart) {
                    self.chart.destroy();
                }
            };
        }

        function chartLnk(scope, element, attr, ctrl) {
            var tooltipHandlersAttached = false;
            scope.width = $window.innerWidth;

            scope.$watch('chartConfig', function () {
                if (scope.chartConfig && scope.chartName) {
                    if (isJourneyChart()) {
                        detachToolTipEventHandlers();
                    }
                    ctrl.initChart(scope, isJourneyChart() ? attachToolTipEventHandlers : undefined);
                }
            }, true);

            angular.element($window).on('resize', function () {
                detachToolTipEventHandlers();
            });

            scope.$on('$destroy', destroyChartAndRemoveHandlers);


            function isJourneyChart() {
                return scope.chartName === 'journey-tab-chart';
            }

            function attachToolTipEventHandlers() {
                if (tooltipHandlersAttached) {
                    return;
                }
                var tooltip = angular.element(element.find('.c3-tooltip-container')[0]);
                var chart = angular.element(element.find('g')[0]);

                chart.on('mouseleave', chartMouseLeave);
                tooltip.on('mouseenter', tooltipMouseEnter);
                tooltip.on('mouseleave', tooltipMouseLeave);

                tooltipHandlersAttached = true;
            }

            function chartMouseLeave() {
                var tooltip = element.find('.c3-tooltip-container')[0];
                if (tooltip) {
                    var container = element.find('.journey-tooltip-container');
                    tooltip.style.display = 'block';

                    if (container.length) {
                        container[0].style.display = 'none';
                    }
                }
            }

            function tooltipMouseEnter() {
                var tooltip = element.find('.c3-tooltip-container')[0];
                if (tooltip) {
                    var container = element.find('.journey-tooltip-container');
                    tooltip.style.display = 'block';

                    if (container.length) {
                        container[0].style.display = 'table';
                    }
                }
            }

            function tooltipMouseLeave() {
                var tooltip = element.find('.c3-tooltip-container')[0];
                if (tooltip) {
                    var container = element.find('.journey-tooltip-container');

                    if (container.length) {
                        container[0].style.display = 'none';
                    }
                }
            }

            function destroyChartAndRemoveHandlers() {
                detachToolTipEventHandlers();
                ctrl.destroyChart();
            }

            function detachToolTipEventHandlers() {
                d3.selectAll('#account-journey-label-svg .truncate-container').on('mouseenter', null);
                d3.selectAll('#account-journey-label-svg .truncate-container').on('mouseleave', null);

                if (!isJourneyChart() || !tooltipHandlersAttached) {
                    return;
                }
                var tooltip = angular.element(element.find('.c3-tooltip-container')[0]);
                tooltip.off('mouseenter', tooltipMouseEnter);
                tooltip.off('mouseleave', tooltipMouseLeave);

                var chart = angular.element(element.find('g')[0]);
                chart.off('mouseleave', chartMouseLeave);

                tooltipHandlersAttached = false;
            }
        }
    }]);
