(function () {
    'use strict';

    angular.module('accountJourney').directive('peopleTabActivityComponent', peopleTabActivityComponent);

    peopleTabActivityComponent.$inject = [];

    function peopleTabActivityComponent() {
        return {
            require: '^peopleTabController',
            restrict: 'E',
            template: require('./people-tab-activity-component.html'),
            controller: 'peopleTabActivityController',
            controllerAs: 'activityCtrl'
        };
    }
})();
