/**
 * Handles domain info.
 */
(function() {
    'use strict';

    angular
        .module('jwt')
        .factory('domainSrvc', domainSrvc);

    domainSrvc.$inject = ['$window'];

    /**
     * Service to fetch domain information.
     * @param $window The browser window.
     * @returns {{load: (function(*): *), save: save}}
     */
    function domainSrvc($window) {
        const service = { };
        const hostMap = {
            'studio.terminusplatform.ninja': 'dev.brightfunnel.com',
            'studio.terminusplatform.com': 'app.brightfunnel.com',
        };

        /**
         * Returns the environment specific domain to facilitate sharing a cookie
         * with scorecard.
         */
        service.getCookieDomain = function() {
            const host = $window.location.hostname;
            const groups = host.match(/(?:[a-z|-]*\.)?([a-z|\\.]+)/);
            if (groups[1]) {
                return groups[1];
            }
            throw 'Unknown host';
        };

        /**
         * Method to retrieve the related BF domain. Mainly used to guarantee that callbacks always
         * get a BF domain.
         * @returns {string} The related BF domain
         */
        service.getBfDomain = function() {
            const host = $window.location.host;
            const bfDomain = hostMap[host];
            return bfDomain == null ? host : bfDomain;
        };

        return service;
    }
})();
