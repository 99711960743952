/**
 * Onboarding Service.
 */
(function () {
    'use strict';

    angular
        .module('brightfunnelOnboard')
        .factory('onboardingSrvc', onboardingSrvc);

    onboardingSrvc.$inject = ['$http', 'authSrvc', 'onboardingConstants',
        'Constants', '$q', 'environmentSrvc'];

    /**
     * Service to get onboarding data.
     */
    function onboardingSrvc($http, authSrvc, onboardingConstants, Constants,
        $q, environmentSrvc) {
        const SUB_ORG = 'sub.org';
        const TERMINUS_HUB = 'Terminus Hub';
        var service = {},
            domain = '';

        function authorizeSrvc(error, params) {
            if (error.status === 403) {
                return $http.get(`${domain}${onboardingConstants.AUTHORIZE_URL}`).then(
                    function (authorizeResponse) {
                        authSrvc.writeJwtToCookie(authorizeResponse.data.token);
                        authSrvc.storeToken(TERMINUS_HUB);
                        return service.get(params);
                    });
            } else {
                return $q.reject(error);
            }
        }

        service.get = function (params) {
            return $http.get(`${domain}${onboardingConstants.SERVICE_URL}`, {params: params}).then(
                function (response) {
                    return response;
                },
                function (error) {
                    return authorizeSrvc(error, params);
                }
            );
        };

        service.storeToken = function (params) {
            return $http.post(`${domain}${onboardingConstants.STORE_TOKEN_URL}`, params);
        };

        /**
         * Setup FullStory
         */
        function setupFullStory() {
            window['_fs_debug'] = false;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_org'] = 'NBGR';
            window['_fs_namespace'] = 'FS';
            (function (m, n, e, t, l, o, g, y) {
                if (e in m) {
                    if (m.console && m.console.log) {
                        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
                    }
                    return;
                }
                g = m[e] = function (a, b, s) {
                    g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
                };
                g.q = [];
                o = n.createElement(t);
                o.async = 1;
                o.src = 'https://' + window['_fs_host'] + '/s/fs.js';
                y = n.getElementsByTagName(t)[0];
                y.parentNode.insertBefore(o, y);
                g.identify = function (i, v, s) {
                    g(l, {uid: i}, s);
                    if (v) g(l, v, s);
                };
                g.setUserVars = function (v, s) {
                    g(l, v, s);
                };
                g.event = function (i, v, s) {
                    g('event', {n: i, p: v}, s);
                };
                g.shutdown = function () {
                    g("rec", !1);
                };
                g.restart = function () {
                    g("rec", !0);
                };
                g.consent = function (a) {
                    g("consent", !arguments.length || a);
                };
                g.identifyAccount = function (i, v) {
                    o = 'account';
                    v = v || {};
                    v.acctId = i;
                    g(o, v);
                };
                g.clearUserCookie = function () {
                };
            })(window, document, window['_fs_namespace'], 'script', 'user');
        }

        /**
         * Initialize Fullstory for the user
         */
        service.initFullStory = function () {
            authSrvc.storeToken(onboardingConstants.TERMINUS_HUB);

            return authSrvc.isLoggedIn(onboardingConstants.TERMINUS_HUB).then(() => {
                let jwtObj = authSrvc.getParsedJwt(onboardingConstants.TERMINUS_HUB);

                if (!window['FS']) {
                    setupFullStory();
                }

                var uid = jwtObj[Constants.SUB];
                let colonIndex = uid.indexOf(':') + 1;
                var userVars = {
                    email: uid.substring(colonIndex),
                    orgUuid: jwtObj[SUB_ORG]
                };
                window['FS'].identify(uid, userVars);
            }, (error) => {
                authSrvc.goToEndpoint(error, window.location.pathname);
                throw 'Redirecting to login';
            });
        };

        service.getConfig = function () {
            var defer = $q.defer();
            environmentSrvc.getVariable(onboardingConstants.CONFIG_KEY).then(function (value) {
                domain = value;
                defer.resolve({enable_button: true});
            }, function () {
                defer.reject({data: 'Missing environment.json', status: 400});
            });
            return defer.promise;
        };

        service.splitParams = function (queryString) {
            var params = {};
            if (queryString.length > 0) {
                if (queryString.charAt(0) === '?') {
                    queryString = queryString.substr(1);
                }
                var pairs = queryString.split('&');
                pairs.forEach(function (pair) {
                    var keyValue = pair.split('=');
                    if (keyValue.length > 1) {
                        params[keyValue[0]] = keyValue[1];
                    }
                });
            }
            return params;
        };

        /**
         * Redirect the window to a specific url
         * @param url The url to redirect
         */
        service.redirect = function (url) {
            window.location.href = url;
        };

        return service;
    }
})();
