/**
 * Manages the jwt token from incoming requests
 */
(function() {
    'use strict';

    angular
        .module('jwt')
        .factory('jwtInterceptor', jwtInterceptor);

    jwtInterceptor.$inject = ['authSrvc', 'Constants',
        'storageSrvc', '$q'];

    function jwtInterceptor(authSrvc, Constants, storageSrvc, $q) {

        var service = {
            /**
             * Inject the JWT into the request in the authorization header
             * @param config The request config
             */
            addJWT : function(config) {
                var authHeader = authSrvc.getAuthorizationFromUrl(config.url);
                config.url = authSrvc.getUrlToUse(config.url);
                if (!config.withCredentials) {
                    config.withCredentials = false;
                }

                if (authHeader) {
                    config.headers['Authorization'] = authHeader;
                }
            },
            /**
             * In case of a 401. Force a reload and the server will redirect the page to the login page.
             * @param rejection The rejection object
             * @return {Promise<*> A promise rejected with the reason}
             */
            removeJWT : function(rejection) {
                if (rejection.status === 401) {
                    // console.error("Response Error 401", rejection);
                    authSrvc.removeToken(Constants.TERMINUS_HUB);
                    var path = window.location.pathname +
                        window.location.search;
                    if (storageSrvc.save) {
                        storageSrvc.save('ngStorage-redirect',
                            JSON.stringify(path +
                                window.location.hash));
                    }
                    authSrvc.isLoggedIn(Constants.TERMINUS_HUB).catch((error) => {
                        authSrvc.goToEndpoint(error, path);
                    });
                }
                return $q.reject(rejection);
            }
        };
        return service;
    }
}());
