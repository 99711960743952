/**
 * Controller for the check-data directive.
 */
(function () {
    'use strict';

    angular
        .module('accountJourney')
        .directive('checkData', function () {
            return {
                restrict: 'E',
                template: require('./check-data.html'),
                controller: checkDataCtrl,
                controllerAs: '$ctrl',
                bindToController: true,
                scope: {
                    message: '=',
                    testVariable: '='
                },
                transclude: true
            };
        });

    checkDataCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function checkDataCtrl() {
        var ctrl = this;
        /**
         * Check if we should display the content or the message.
         *
         * An empty array or an expression that evaluates to false will not display content.
         * @returns {boolean} True if the content is to be displayed, false otherwise.
         */
        ctrl.show = function() {
            if (!ctrl.testVariable) {
                return false;
            }
            var isArray = Array.isArray(ctrl.testVariable);
            return isArray &&
                !!ctrl.testVariable.length ||
                !isArray &&
                !!ctrl.testVariable;
        };
    }

})();
