/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('c3ChartManager', c3ChartManager);

    c3ChartManager.$inject = ['$timeout', 'journeyHighlightService'];

    function c3ChartManager($timeout, journeyHighlightService) {
        var service = {};

        service.renderChart = function (scope) {
            if (!scope || !scope.chartConfig) {
                return;
            }

            var chart = c3.generate(scope.chartConfig);

            if (scope.chartConfig.regionArea) {
                var chartColumns = d3.selectAll(scope.chartConfig.bindto + ' rect.c3-event-rect');
                var labelClassName = 'journey-chart-top-label';
                var targetChartColumns = chartColumns[0];

                // remove previously selected oppty's generated labels
                journeyHighlightService.cleanUpHighlightLabels();

                scope.chartConfig.regionArea.map(function (stage, index) {
                    var isOdd = !!(index % 2);
                    journeyHighlightService.generateHighlightRegion(scope, stage, targetChartColumns, isOdd, labelClassName);
                });
            }
            return chart;
        };

        service.startObservingChartDirtyState = function (attr, element) {
            attr.$observe('chartIsDirty', function (newValue) {
                if (newValue && newValue === 'true') {
                    rebuildCharts(element);
                }
            });
        };

        function rebuildCharts(element) {
            $timeout(function () {
                var chartElements = element.find('.chart-container');
                chartElements.each(function () {
                    var chartEl = $(this);
                    service.renderChart(angular.element(chartEl).scope());
                });
            }, 200);
        }

        return service;
    }
})();
