/**
 * Directive to implement the behavior of the primary form button
 */
(function () {
    'use strict';

    angular.module('brightfunnelUserAuth').directive('formButton', formButtonComponent);

    formButtonComponent.$inject = ['userAuthSrvc'];

    /**
     * Form Primary Button
     * @returns {{bindToController: boolean, controller: formButtonCtrl, scope: {clickFn: string, text: string, enabled: string}, restrict: string, controllerAs: string, templateUrl: string}}
     */
    function formButtonComponent() {
        return {
            restrict: 'E',
            template: require('./form-button.html'),
            controller: formButtonCtrl,
            controllerAs: '$ctrl',
            scope: {
                'text': '=',
                'enabled': '=',
                'clickFn': '&'
            },
            bindToController: true
        };
    }

    /**
     * Form button controller
     */
    function formButtonCtrl($scope, userAuthSrvc) {
        var ctrl = this;

        /**
         * Click function for the button.
         */
        this.formClick = function ($event) {
            if ($event) {
                $event.preventDefault();
            }
            ctrl.clickFn()();
        };

        $scope.$watch('$ctrl.clickFn', function (newFn) {
            userAuthSrvc.setFormClickFn(newFn);
        });
    }
})();
