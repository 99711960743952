(function ($) {
    'use strict';
    var app = angular
        .module('notyModule', []);

    app.provider('noty', notyProvider);

    function notyProvider () {
        var settings = $.noty.defaults;

        settings.maxVisible = 1;
        settings.dismissQueue = true;

        this.settings = settings;
        this.$get =  [ '$timeout', function ($timeout) {
            var callNoty = function (newSettings) {
                return noty(newSettings || {});
            };

            return {
                show: function (message, type) {
                    callNoty({ text: message || settings.text, type: type || settings.type });
                },

                showAlert: function (message) {
                    callNoty({ text: message || settings.text, type: "alert" });
                },

                showSuccess: function (message) {
                    callNoty({ text: message || settings.text, type: "success" });
                },

                showError: function (message) {
                    callNoty({ text: message, type: "error" });
                },

                growl: function (message, type, onlyone, time) {
                    var n = callNoty({ text: message || settings.text, type: type || settings.type, closeWith: ['click'] }),
                        wait = false,
                        timedOut = false;

                    n.timer = $timeout(function () {
                        if (!wait) {
                            n.close();
                        } else {
                            timedOut = true;
                        }
                    }, (time || 4000));

                    $('body').on('mouseenter', '.noty_message', function () {
                        wait = true;
                    });

                    $('body').on('mouseleave', '.noty_message', function () {
                        wait = false;

                        if (timedOut) {
                            n.close();
                        }
                    });

                    if (onlyone) {
                        if (!n.showing) {
                            $timeout.cancel(n.timer);
                        }
                        this.clearShowQueue();
                    }
                },

                closeAll: function () {
                    return $.noty.closeAll();
                },
                clearShowQueue: function () {
                    return $.noty.clearQueue();
                }.bind(this)
            };
        }];

    }
}(jQuery));
