import {SplitFactory} from '@splitsoftware/splitio';
import {SPLITIO_API_KEY_ENV, SPLITIO_COOKIE_NAME, SPLITIO_OFF, SPLITIO_ON, SPLITIO_TRAFFIC_TYPE} from './constants';
import {MAX_WAIT} from '../platform-bi/constants';

export class SplitIoSdkSrvc {
    static $inject: Array<string> = ['$timeout', '$cookies', 'environmentSrvc'];

    private splitIoClient: any;
    private splitIoReady: boolean;
    private testing: boolean;

    constructor(private $timeout: any,
                private $cookies: any,
                private environmentSrvc: any) {
        this.splitIoClient = null;
        this.splitIoReady = false;
        // Change to true for localhost use
        this.testing = false;
    }

    loadSDK(identifier): Promise<any> {
        return this.environmentSrvc.getVariable(SPLITIO_API_KEY_ENV).then((key) => {
            if (key) {
                let settings: any;
                if (this.testing) {
                    settings = {
                        core: {
                            authorizationKey: 'localhost',
                        },
                        features: {
                            'unified_login': 'on',
                            'zd_sso': 'on',
                        },
                        scheduler: {
                            offlineRefreshRate: 15 // 15 sec
                        }
                    };
                } else {
                    settings = {
                        core: {
                            authorizationKey: key,
                            key: identifier,
                            trafficType: SPLITIO_TRAFFIC_TYPE,
                        },
                    };
                }
                if (!this.splitIoClient) {
                    this.splitIoClient = SplitFactory(settings).client();
                }
            }
            return;
        });
    }

    isSdkInitialized(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.splitIoClient) {
                if (this.splitIoReady) {
                    resolve(true);
                } else {
                    const handle = this.$timeout(() => {
                        this.splitIoReady = false;
                        reject(false);
                    }, MAX_WAIT);
                    this.splitIoClient.on(this.splitIoClient.Event.SDK_READY, () => {
                        this.$timeout.cancel(handle);
                        this.splitIoReady = true;
                        resolve(true);
                    });
                }
            } else {
                reject(false);
            }
        });
    }

    getTreatment(splitName: string): Promise<boolean> {
        return this.isSdkInitialized().then(() => {
            const cookieValue = this.$cookies.get(SPLITIO_COOKIE_NAME);
            let attrs = undefined;
            if (cookieValue) {
                attrs = {term_ul_opt: cookieValue};
            }
            const treatment: string =
                this.splitIoClient.getTreatment(splitName,
                    attrs);
            if (treatment === SPLITIO_ON) {
                return true;
            } else if (treatment === SPLITIO_OFF) {
                return false;
            } else {
                throw 'Unknown state';
            }

        }, () => {
            throw 'Client not initialized';
        });
    }

    destroy(): void {
        if (this.splitIoClient) {
            this.splitIoClient.destroy().then(() => {
                this.splitIoClient = null;
            });
        }
    }
}
