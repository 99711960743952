/* eslint-disable no-undef */

(function () {
    'use strict';

    angular.module('accountJourney').service('chartDataAggregator', chartDataAggregator);

    chartDataAggregator.$inject = ['AccountJourneyConstants', '$filter'];

    function chartDataAggregator(AccountJourneyConstants, $filter) {
        var service = {};

        service.aggregateData = function (normalizedData, chartType) {
            var chartDataMap = {};

            if (normalizedData.length === 0) {
                return chartDataMap;
            }

            var counterType = typeof normalizedData[0].count;

            normalizedData.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            normalizedData.forEach(function (d) {
                if (chartType === AccountJourneyConstants.BarGraph) {
                    countAggregate(d, chartDataMap);
                } else {
                    var count = counterType === 'string' ? 1 : d.count;
                    if (!chartDataMap[d.date]) {
                        chartDataMap[d.date] = {};
                        chartDataMap[d.date]['date'] = d.date;
                        chartDataMap[d.date]['total'] = 0;
                        if (d.payload) {
                            chartDataMap[d.date]['data'] = [];
                        }
                    }
                    chartDataMap[d.date].total += count;
                    if (d.payload) {
                        chartDataMap[d.date]['data'].push(d.payload);
                    }
                }
            });
            return chartDataMap;
        };

        service.groupDatesByWeek = function (data) {
            if (_.isEmpty(data)) {
                return [];
            }
            var weekToTotalMap = {};

            data.forEach(function (a) {
                var week = $filter('date')(moment(a.date).startOf('isoWeek').toISOString(), 'shortDate');
                if (!weekToTotalMap[week]) {
                    weekToTotalMap[week] = { total: 0 };
                }
                weekToTotalMap[week]['total'] += a.total;
            });
            return weekToTotalMap;
        };

        // Counts all the items in the list and adds the total to the model attribute
        function countAggregate(insights, aggregateData) {
            insights.count.list.forEach(function (insight) {
                if (!aggregateData[insight]) {
                    aggregateData[insight] = { text: insight, total: 0, date: insights.date };
                }
                aggregateData[insight].total++;
            });
        }

        return service;
    }
})();

