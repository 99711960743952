/**
 * Directive to implement the form header
 */
(function () {
    'use strict';

    angular.module('brightfunnelUserAuth').directive('formHeader', formHeaderComponent);

    formHeaderComponent.$inject = [];

    /**
     * Form header component
     * @returns {{bindToController: boolean, controller: formHeaderCtrl, scope: {title: string}, restrict: string, controllerAs: string, templateUrl: string}}
     */
    function formHeaderComponent() {
        return {
            restrict: 'E',
            template: require('./form-header.html'),
            controller: formHeaderCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                'title': '='
            }
        };
    }

    /**
     * Empty controller
     */
    function formHeaderCtrl() {
    }
})();

