/**
 * Controller for the card directive.
 */
(function () {
    'use strict';

    angular
        .module('accountJourney')
        .directive('pageHeader', function () {
            return {
                restrict: 'E',
                template: require('./page-header.html'),
                controller: pageHeaderCtrl,
                controllerAs: '$ctrl',
                bindToController: true,
                scope: {
                    header: '=',
                    datetime: '='
                }
            };
        });

    pageHeaderCtrl.$inject = ['datetimeSrvc', 'AccountJourneyConstants', '$scope'];

    /**
     * Controller for the directive.
     */
    function pageHeaderCtrl(datetimeSrvc, AccountJourneyConstants, $scope) {
        var ctrl = this;

        function init() {
            if (ctrl.datetime) {
                ctrl.updateTooltip = datetimeSrvc.dateComplex(ctrl.datetime);
                ctrl.updateText = AccountJourneyConstants.header.LABELS.LAST_UPDATE +
                    datetimeSrvc.ago(ctrl.datetime);
            }
        }

        init();

        $scope.$watch('$ctrl.datetime', function() {
            init();
        });
    }

})();
