/**
 * Directive to implement the horizontal split
 */
(function () {
    'use strict';

    angular.module('brightfunnelOnboard').directive('horizontalSplit', horizontalSplitComponent);

    horizontalSplitComponent.$inject = [];

    /**
     * Horizontal Split component
     */
    function horizontalSplitComponent() {
        return {
            restrict: 'E',
            template: require('./horizontal-split.html'),
            controller: horizontalSplitCtrl,
            transclude: true,
        };
    }

    /**
     * Horizontal split controller
     */
    function horizontalSplitCtrl() {
    }
})();

