/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('journeyHighlightService', journeyHighlightService);

    journeyHighlightService.$inject = ['$compile'];

    function journeyHighlightService ($compile) {
        var service = {};

        service.cleanUpHighlightLabels = function () {
            d3.select('#account-journey-label-svg')
                .selectAll('g')
                .on('.', null)
                .remove();
        };

        service.generateHighlightRegion = function (scope, opptyStage, columns, isOdd, className) {
            var startIndex = opptyStage.start;
            var endIndex = opptyStage.end;
            var currentIndex = startIndex;
            var midpointLabelIndex = Math.floor((endIndex - startIndex) / 2) + startIndex;
            var generatedLabels = [];

            while (currentIndex !== endIndex) {
                var targetColumn = columns[currentIndex];
                var column = d3.select(targetColumn);
                var xOffset = parseInt(column.attr('x')) + parseInt(column.attr('width'));
                var yOffset = 100;

                column
                    .attr('class', isOdd ? 'oppty-region-light' : 'oppty-region-dark');

                if (currentIndex === midpointLabelIndex) {
                    var labels = opptyStage.labels;
                    var newLineOffset = 25;

                    // create first label for all oppty stages in selected oppty
                    generatedLabels.push(generateHighlightLabels(scope, xOffset, yOffset, opptyStage.labels[0], className));
                    // create second label for all oppty stages in selected oppty
                    if (labels[1]) {
                        generatedLabels.push(generateHighlightLabels(scope, xOffset + newLineOffset, yOffset, labels[1], className));
                        newLineOffset += newLineOffset;
                    }
                    // all labels after the first two are shown as 'and X more...'
                    if (labels[2]) {
                        var thirdLabel = 'and ' + (labels.length - 2) + ' more...';
                        var truncatedLabels = labels.slice(2);
                        generatedLabels.push(generateHighlightLabels(scope, xOffset + newLineOffset, yOffset, thirdLabel, 'truncated-text', truncatedLabels));
                    }
                }

                currentIndex++;
            }
        };

        function generateHighlightLabels(scope, xOffset, yOffset, label, className, truncatedLabels) {
            var labelContainer  = d3.select('#account-journey-label-svg')
                .append('g')
                .attr('class', 'truncate-container')
                .attr('transform', 'translate(' + xOffset + ',' + yOffset + ')');
            labelContainer
                .append('text')
                .attr('class', className)
                .append('tspan')
                .text(label);

            if (truncatedLabels && truncatedLabels.length) {
                var fullText = truncatedLabels.join(', ');
                generateTooltipHandler(scope, labelContainer, fullText, xOffset);
            }
        }

        function generateTooltipHandler(scope, labelContainer, text, xOffset) {
            var tooltip = d3.select('#target-tooltip-container');

            labelContainer.on('mouseenter', function () {
                tooltip.attr({
                    'tooltip-enabled': true,
                    'uib-tooltip': text
                })
                    .style({
                        left: xOffset + 'px',
                    });

                $compile(tooltip[0])(scope);
            });

            labelContainer.on('mouseleave', function () {
                tooltip.attr({
                    'tooltip-enabled': false,
                    'uib-tooltip': ''
                });

                $compile(tooltip[0])(scope);
                d3.selectAll('.tooltip').remove();
            });
        }

        return service;
    }
})();
