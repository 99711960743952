/**
 * Directive to implement a checkbox with inner components
 */
(function () {
    'use strict';

    angular.module('brightfunnelUserAuth').directive('formCheckbox', formCheckboxComponent);

    formCheckboxComponent.$inject = [];

    /**
     * Checkbox Component
     * @returns {{bindToController: boolean, controller: formCheckboxCtrl, transclude: boolean, scope: {value: string}, restrict: string, controllerAs: string, templateUrl: string}}
     */
    function formCheckboxComponent() {
        return {
            restrict: 'E',
            template: require('./form-checkbox.html'),
            controller: formCheckboxCtrl,
            controllerAs: '$ctrl',
            scope: {
                'value': '='
            },
            bindToController: true,
            transclude: true
        };
    }

    /**
     * Empty controller
     */
    function formCheckboxCtrl() {
    }
})();
