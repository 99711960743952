/* eslint-disable no-undef */

(function () {
    'use strict';

    angular.module('accountJourney').service('personActivityCountAggregator', personActivityCountAggregator);

    personActivityCountAggregator.$inject = [];

    function personActivityCountAggregator() {
        function getMostRecentDate(currentDate, newDate) {
            if(!currentDate) {
                return newDate;
            }
            return moment(newDate).isAfter(currentDate) ? newDate : currentDate;
        }

        // Sums up all the activities that match the person's lead id and places the value in either marketing counts or sales counts
        // Calculates last touch as the most recent date over all sales and activity dates
        function calculateCounts(data, people, leadIdField, dateField, countsField) {
            var countsMap = {};
            var lastTouchDate = {};

            data.forEach(function (activity) {
                countsMap[activity[leadIdField]] = countsMap[activity[leadIdField]] ? (countsMap[activity[leadIdField]] + 1) : 1;
                if(activity[dateField]) {
                    lastTouchDate[activity[leadIdField]] = getMostRecentDate(lastTouchDate[activity[leadIdField]], activity[dateField]);
                }
            });

            people.forEach(function (person) {
                person[countsField] = countsMap[person.lead_id] ? countsMap[person.lead_id] : 0;

                if(lastTouchDate[person.lead_id]) {
                    person.lastTouch = getMostRecentDate(person.lastTouch, lastTouchDate[person.lead_id]);
                }
            });
        }

        return function(data) {
            calculateCounts(data['touches'].data.activity_list, data['people'].data.contacts, 'lead_id', 'activity_date', 'marketingCounts');
            calculateCounts(data['sales'].data.tasks, data['people'].data.contacts,'leadId', 'created_date', 'salesCounts');

            data['people'].data.contacts.forEach(function(person) {
                person.totalCounts = person.marketingCounts + person.salesCounts;
            });
        };
    }
})();

