(function () {
    'use strict';

    angular.module('accountJourney').controller('frequentTopicsCardController', frequentTopicsCardController);

    frequentTopicsCardController.$inject = ['$scope', 'chartDataGenerationService', 'accountJourneyService', 'AccountJourneyConstants'];

    function frequentTopicsCardController($scope, chartDataGenerationService, accountJourneyService, AccountJourneyConstants) {
        var self = this;
        self.headerText = 'Topics Being Researched';
        accountJourneyService.getData().then(function(data) {
            self.intentFeatureEnabled = accountJourneyService.intentFeatureEnabled();

            var intentTopics = self.intentFeatureEnabled ?
                data['anonymous'].data.intent_topics :
                accountJourneyService.mockIntentData(data);

            self.frequentTopics = chartDataGenerationService.generateChartData(intentTopics,
                AccountJourneyConstants.AnonymousData,
                AccountJourneyConstants.BarGraph, {
                    cohort : 'days90', latestDate: true
                },
                $scope);
        });

        // The height without the graph
        // We add the height of the graph in the watch to get the total component height
        var baseHeight = 34;

        $scope.$watch('frequentTopicsCardCtrl.frequentTopics.chartConfig.size.height', function(newValue) {
            if(newValue) {
                self.componentHeight = (newValue + baseHeight) + 'px';
            } else {
                self.componentHeight = 'auto';
            }
        });
    }
})();

