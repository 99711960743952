import LZString from 'lz-string';

(() => {
    'use strict';

    function modelsService(userModels) {
        return userModels;
    }
    modelsService.$inject = ['userModels'];
    app.service('models', modelsService);

    function lzStringService() {
        return LZString;
    }
    app.service('LZString', lzStringService);

    function lodashService() {
        return window._;
    }
    app.service('_', lodashService);
})();
