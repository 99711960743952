import "angular-cookies/angular-cookies.js";

/**
 * Add the jwt module.
 */
(function() {
    'use strict';

    angular
        .module('jwt', ['ngCookies', 'unified_login']);
})();
