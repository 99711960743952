/* eslint-disable no-undef */
(function () {
    'use strict';
    /**
     * Service to perform date conversions.
     */
    app.service('datetimeSrvc',datetimeSrvc);

    datetimeSrvc.$inject = [];

    function datetimeSrvc() {
        var service = {};

        /**
         * From a UTC/GMT string build the tooltip
         *
         * @param dateStr The string in UTC timezone
         * @returns {string} A string representing the last update.
         */
        service.dateComplex = function(dateStr) {
            return moment(dateStr).format('dddd @ h:mm a');
        };

        /**
         * From a UTC/GMT string build the timelapse since the update
         *
         * @param dateStr The string in UTC timezone
         * @returns {string} A string representing the timelapse.
         */
        service.ago = function(dateStr) {
            return moment(dateStr).fromNow();
        };

        return service;
    }
})();
