(function() {
    angular.module('accountJourney').constant('AccountJourneyConstants', {
        JourneyGraph : 'journey',
        BarGraph: 'bar',
        LineGraph: 'line',
        JourneyData: 'journey',
        MarketingData: 'marketing',
        SalesData: 'sales',
        PageViewData: 'pageViews',
        BomboraIntent: 'bomboraIntent',
        EngagementSpike: 'engagementSpike',
        AnonymousData: 'anonymous',
        PeopleData: 'people',
        OpptyData: 'oppty',
        OpptyHistoryData: 'opptyHistory',
        TouchTypeMarketing: 'Marketing',
        TouchTypeSales: 'Sales',
        TouchTypeAnonymous: 'Anonymous',
        TouchTypeOpportunity: 'Opportunity',
        unauthorized: {
            API: {
                SFDC_RESPONSE: 'sfdc',
                CRM_URL: 'organizationInfo/get_crm_url',
            },
            LABELS: {
                UNAUTHORIZED: 'Bummer, looks like you don\'t have access to this feature. ' +
                    'Contact your Terminus Customer Success Manager to get the data flowing!',
                MISSING_SFDC_URL: 'Salesforce user\'s links may not work. Try to refresh the page.'
            },
        },
        noData: {
            LABELS: {
                ACTIVITY_NO_DATA: 'Oops, we couldn\'t find any activity data in the last 90 days.',
                PEOPLE_NO_DATA: 'Looks like this account doesn\'t have any contacts.',
                NO_TOKEN: 'Authentication credentials are missing.',
                CONNECTION_ERROR: 'It was not possible to connect now.'
            },
        },
        header: {
            API: {
                LAST_UPDATE_URL: '/api/v1/last_update',
                LAST_UPDATE_RESPONSE: 'last_update'
            },
            LABELS: {
                NO_ORG: 'ccount Journey',
                LAST_UPDATE: 'Last Updated: '
            }
        }
    });
})();
