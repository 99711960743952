/**
 * Directive to implement the divider
 */
(function () {
    'use strict';

    angular.module('brightfunnelOnboard').directive('divider', dividerComponent);

    dividerComponent.$inject = [];

    /**
     * Divider component
     */
    function dividerComponent() {
        return {
            restrict: 'E',
            template: require('./divider.html'),
            controller: dividerCtrl,
        };
    }

    /**
     * Divider controller
     */
    function dividerCtrl() {
    }
})();

