/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('journeyDateRangeGenerator', journeyDateRangeGenerator);

    journeyDateRangeGenerator.$inject = ['$filter'];

    function journeyDateRangeGenerator($filter) {
        var service = {};

        service.generateDateRange = function () {
            var days = 90;
            var startOfWeek;
            var dateRange = [];
            var numberOfWeeks = 0;
            do {
                startOfWeek = moment().subtract(days, 'days').startOf('isoWeek').toISOString();
                var date = $filter('date')(startOfWeek, 'shortDate');
                dateRange.push(date);
                days -= 7;
                numberOfWeeks++;
            } while (numberOfWeeks < 14);
            return dateRange;
        };
        return service;
    }
})();
