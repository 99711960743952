/**
 * Directive to implement the form container
 */
(function () {
    'use strict';

    angular.module('brightfunnelOnboard').directive('formContainer', formContainerComponent);

    formContainerComponent.$inject = [];

    /**
     * Form Container Component
     */
    function formContainerComponent() {
        return {
            restrict: 'E',
            template: require('./form-container.html'),
            controller: formContainerCtrl,
            controllerAs: '$ctrl',
            scope: {},
            bindToController: true,
            transclude: true
        };
    }

    /**
     * Form container controller
     */
    function formContainerCtrl() {
    }
})();
