import '@okta/okta-auth-js/polyfill';
import {
    OktaAuth,
    OktaAuthOptions,
} from '@okta/okta-auth-js';
import {
    OKTA_CLIENT_ID,
    OKTA_ISSUER,
} from './constants';

export class OktaSrvc {
    static $inject: Array<string> = ['environmentSrvc'];

    private oktaAuth: OktaAuth;

    constructor(private environmentSrvc: any) {
    }

    initialize(): Promise<void> {
        if (this.oktaAuth) {
            return Promise.resolve();
        }

        return this.environmentSrvc.getVariable(OKTA_ISSUER).then((issuer) => {
            return this.environmentSrvc.getVariable(OKTA_CLIENT_ID).then((clientId) => {
                const href = window.location.href;
                const config: OktaAuthOptions = {
                    issuer: 'https://' + issuer + '/oauth2/default',
                    clientId: clientId,
                    scopes: ['openid', 'profile', 'email'],
                    redirectUri: href.substring(0, href.indexOf('#')),
                    tokenManager: {
                        autoRenew: false,
                        storage: 'localStorage',
                    },
                };
                this.oktaAuth = new OktaAuth(config);
                return;
            });
        });
    }

    /**
     * Checks if there is an okta session
     * @returns Promise with a boolean stating if a Okta exists
     */
    hasSession(): Promise<boolean> {
        return this.initialize().then(() => {
            return this.oktaAuth.session.exists().then(function(exists) {
                return exists;
            });
        });
    }

    /**
     * Get the current Okta token
     * @return A Promise with the payload of the token
     */
    getToken(): Promise<any> {
        return this.initialize().then(() => {
            return this.oktaAuth.token.getWithoutPrompt({
                responseType: ['token'],
                scopes: ['openid', 'email', 'profile']
            }).then((tokenResponse) => {
                return tokenResponse.tokens.idToken.idToken;
            });
        });
    }
}
