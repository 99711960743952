/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('insightTabService', insightTabService);

    insightTabService.$inject = ['personActivityCountAggregator', '$filter', 'chartConfigBuilderService', 'AccountJourneyConstants'];

    function insightTabService(personActivityCountAggregator, $filter, chartConfigBuilderService, AccountJourneyConstants) {
        var service = {};

        service.formatActivePersonData = function(data) {
            if(data['people'].data.contacts.length === 0) {
                return undefined;
            }
            personActivityCountAggregator(data);

            var filteredData =  data['people'].data.contacts.filter(function(contact) {
                // Filter out those people who do not have total counts. This means the were not
                // in the 90 day window, not relevant to this card, or have no touches. totalCounts field
                // is set in the personActivityCountAggregator.
                return !!contact.totalCounts;
            });

            filteredData.sort(function(a, b) {
                return (b.totalCounts - a.totalCounts) ||
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase()); // sort alphabetically and ignore case
            });

            return filteredData.length === 0 ? undefined : {
                count: filteredData[0].totalCounts,
                date: filteredData[0].lastTouch,
                person: filteredData[0].name
            };
        };

        service.formatPeopleAdded = function(data) {
            var newPeople = data['people'].data.contacts.filter(function(contact) {
                return contact.contact_created_date
                    && moment(contact.contact_created_date).isAfter(moment().subtract(90, 'days'));
            });

            newPeople.sort(function(a, b) {
                return moment(a.contact_created_date).isBefore(moment(b.contact_created_date)) ? 1 : -1;
            });

            return newPeople.length === 0 ? undefined : {
                count: newPeople.length,
                date: newPeople[0].contact_created_date
            };
        };

        service.formatPreviousOpportunityData = function(data) {
            var previousOppty = data['people'].data.contacts.filter(function(contact) {
                return contact.last_oppty_id != null;
            });

            return previousOppty.length === 0 ? undefined : {
                person: previousOppty[0].name,
                date: previousOppty[0].oppty_created_date,
                leadId: previousOppty[0].lead_id,
                title: previousOppty[0].title
            };
        };

        service.formatMarketingTouchData = function(data, cohort) {
            if(!cohort) {
                cohort = 'days90';
            }

            var peopleByDate = {};
            var uniquePeople= {};
            var totalTouches = 0;
            data['touches'].data.activity_list.forEach(function(activity) {
                var week =  $filter('date')(moment(activity.activity_date).startOf('isoWeek').toISOString(), 'shortDate');
                if(!peopleByDate[week]) {
                    peopleByDate[week] = {};
                }
                peopleByDate[week][activity.lead_id] = true;
                uniquePeople[activity.lead_id] = true;
            });

            if(Object.keys(peopleByDate).length === 0) {
                return {emptyChart: true};
            }

            Object.entries(peopleByDate).forEach(function(entry) {
                peopleByDate[entry[0]].total = Object.keys(entry[1]).length;
            });

            totalTouches = Object.keys(uniquePeople).length;

            var chartConfig =  chartConfigBuilderService.build(peopleByDate,  null, AccountJourneyConstants.LineGraph);

            return {
                chartConfig: chartConfig,
                totalTouches: totalTouches,
                emptyChart: false
            };
        };
        return service;
    }
})();
