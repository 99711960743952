/* eslint-disable no-redeclare */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
app.service('filters', ['$rootScope', '$filter', 'utilities', '_', '$state', '$location', function ($rootScope, $filter, utilities, _, $state, $location) {
    var that = this,
        savedFilters,
        selectedFilter,
        applyListener,
        skipAppliedFilters,
        toggleModel = { big: false, small: false, no: true, summaryPanel: false };

    this.getToggleModel = function () {
        return toggleModel;
    };

    //uib-tab set active property
    this.getActiveIndex = function (o) {
        for (var i = 0; i < o.length; i++) {
            if (!o[i].disabled) {
                return i;
            }
        }
    };

    this.typeKeyMap = {
        l: 'lead',
        o: 'opportunity',
        c: 'campaign',
        cm: 'campaign_member',
        a: 'account',
        ta: "terminus"
    };

    this.setSavedFilters = function (d) {
        savedFilters = d;
        $rootScope.$broadcast('$$rebind::presetsRefresh');
    };

    this.getSavedFilters = function () {
        return savedFilters;
    };

    this.setSelectedFilter = function (d) {
        selectedFilter = d;
    };

    this.getSelectedFilter = function () {
        return selectedFilter;
    };

    this.selectSavedFilter = function (savedFilter, filters) {
        if (Array.isArray(savedFilter)) {
            savedFilter = this.transformOld(savedFilter);
        }
        this.initializeFilters(filters, savedFilter, "save");
    };

    this.selectAllInFolder = function (folder, container) {
        var selected = 0;
        var diffBefore = 0;
        var diffAfter = 0;
        folder.contents.forEach(function (list) {
            diffBefore += list.selected !== list.applied ? 1 : 0;
            if (list.selected !== folder.all_selected) {
                selected += folder.all_selected ? 1 : -1;
                list.selected = folder.all_selected;
            }
            diffAfter += list.selected !== list.applied ? 1 : 0;
        });
        folder.selected = selected;
        container.select_apply_diff += diffAfter - diffBefore;
        return selected;
    };

    this.selectList = function (list, folder, container) {
        var isAllSelected = true;

        folder.contents.forEach(function (list) {
            if (!list.selected) {
                isAllSelected = false;
            }
        });

        folder.all_selected = isAllSelected;
        container.select_apply_diff += list.selected === list.applied ? -1 : 1;
        return list.selected ? 1 : -1;
    };

    this.getSelectedAccountLists = function (folders) {
        var selectedIds = [];
        folders.forEach(function (folder) {
            folder.contents.forEach(function (list) {
                if (list.selected) {
                    selectedIds.push(list.id);
                }
            });
        });
        return selectedIds;
    };

    this.setSelectedLists = function (folders, selectedIds, applyStatus, container) {
        var that = this;
        var total = 0;
        folders.forEach(function (folder) {
            folder.all_selected = false;
            folder.selected = 0;
            folder.contents.forEach(function (list) {
                if (selectedIds.indexOf(list.id.toString()) > -1 || selectedIds.indexOf(list.id) > -1) {
                    list.selected = true;
                    if (applyStatus !== "save") {
                        list.applied = applyStatus === "sync";
                    }
                    folder.selected++;
                    total++;
                } else {
                    list.selected = false;
                    if (applyStatus !== "save") {
                        list.applied = false;
                    }
                }
                container.select_apply_diff += list.selected === list.applied ? 0 : 1;
            });
            if (folder.contents && folder.contents.length) {
                folder.all_selected = folder.selected === folder.contents.length;
            }
        });
        return total;
    };

    this.clearAllLists = function (folders) {
        folders.forEach(function (folder) {
            folder.all_selected = false;
            folder.selected = 0;
            folder.contents.forEach(function (list) {
                list.selected = false;
            });
        });
    };

    this.disableList = function (folders, listId) {
        folders.forEach(function (folder) {
            folder.contents.forEach(function (list) {
                if (list.id === listId) {
                    folder.disabled = true;
                    list.disabled = true;
                }
            });
        });
    };

    this.enableList = function (folders, listId) {
        folders.forEach(function (folder) {
            folder.contents.forEach(function (list) {
                if (list.id === listId) {
                    folder.disabled = false;
                    list.disabled = false;
                }
            });
        });
    };

    this.getListIdsFromParams = function (params) {
        var selectedIds = [];
        if (params && params.al && params.al.length > 0) {
            selectedIds = params.al.split(",");
        }
        return selectedIds;
    };

    this.initializeFolders = function (folderContainer, selectedIds, applyStatus) {
        var validApplyStati = ["save", "sync", "clear"];

        if (validApplyStati.indexOf(applyStatus) > -1) {
            folderContainer.select_apply_diff = 0;
            folderContainer.selected = this.setSelectedLists(folderContainer.folders, selectedIds, applyStatus, folderContainer);
            if (applyStatus !== "save") {
                folderContainer.applied = applyStatus === "sync" ? folderContainer.selected : 0;
            }
        } else {
            console.warn("not a valid apply status");
        }
    };

    this.hasAccountLists = function (folders) {
        var hasLists = false;
        folders.forEach(function (folder) {
            if (folder.contents.length > 0) {
                hasLists = true;
            }
        });
        return hasLists;
    };

    this.filterToggled = function (filter, field, table, container) {
        field.selected += filter.selected ? 1 : -1;
        table.selected += filter.selected ? 1 : -1;
        container.selected += filter.selected ? 1 : -1;
        field.all_selected = field.data.length === field.selected;
        container.select_apply_diff += filter.selected === filter.applied ? -1 : 1;
    };

    this.fieldToggled = function (field, table, container) {
        var changed = 0;
        var diffBefore = 0;
        var diffAfter = 0;
        for (var i = 0; i < field.data.length; i++) {
            diffBefore += field.data[i].selected !== field.data[i].applied ? 1 : 0;
            if (field.data[i].selected !== field.all_selected) {
                field.data[i].selected = field.all_selected;
                changed++;
            }
            diffAfter += field.data[i].selected !== field.data[i].applied ? 1 : 0;
        }
        field.selected += field.all_selected ? changed : -changed;
        table.selected += field.all_selected ? changed : -changed;
        container.selected += field.all_selected ? changed : -changed;
        container.select_apply_diff += diffAfter - diffBefore;
    };

    this.computeValidFiltersForReport = function (filterContainer, state) {
        var totalValid = 0;
        for (var i = 0; i < filterContainer.tables.length; i++) {
            filterContainer.tables[i].enabled = false;
            if (state.data && state.data.filter && state.data.filter.indexOf(filterContainer.tables[i].table) > -1) {
                filterContainer.tables[i].enabled = true;
                totalValid++;
            }
        }
        filterContainer.tables.sort(function (a, b) {
            if (a.table < b.table) return -1;
            if (a.table > b.table) return 1;
            return 0;
        });
        return totalValid;
    };

    this.sanitizeSavedFilters = function (currentFilters, savedFilters) {
        angular.forEach(savedFilters, function (savedFilter, i) {

            _.forEach(savedFilter.filters, function (saved, i) {
                if (saved.filters) {
                    _.forEach(saved.filters, function (presetFilter, ii) {
                        var rawFilter = _.find(currentFilters[saved.table], { name: presetFilter.name });
                        if (!rawFilter) {
                            saved.filters = _.filter(saved.filters, function (v, i) {
                                return i !== ii;
                            });
                        } else {
                            var rawFilterData = rawFilter.data;
                            _.forEach(rawFilterData, function (data, iii) {
                                if (data) {
                                    var index;
                                    if (data.name == "(Blank)") {
                                        index = _.indexOf(rawFilter, "BF_None");
                                    } else {
                                        index = _.indexOf(rawFilter, data.name);
                                    }
                                    if (index == -1) {
                                        rawFilterData = _.filter(rawFilterData, function (v, i) {
                                            return i !== iii;
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            });
            if (savedFilter.private) {
                savedFilter.label = "Personal";
            } else {
                savedFilter.label = "Organization";
            }
        });
    };

    this.searchFilter = function (filterContainer, accountLists, searchTerm, scopeData) {
        _.validDeepPart(filterContainer.tables, { name: searchTerm });
        _.validDeepPart(accountLists.folders, { name: searchTerm });
        this.reValidateFoldersWithoutLists(accountLists.folders);
        if (searchTerm) {
            var activeFolders = _.filter(accountLists.folders, function (o) { return !o.$invalid; }).length;
            accountLists.$invalid = activeFolders === 0;
            scopeData.emptySearch = _.filter(filterContainer.tables, function (o) { return !o.$invalid; }).length
                + activeFolders;
            scopeData.onlyOneOpen = true;
            that.openAll(filterContainer.tables, accountLists);
        } else {
            scopeData.onlyOneOpen = false;
            accountLists.$invalid = false;
            scopeData.emptySearch = false;
            that.closeAll(filterContainer.tables, accountLists);
        }
    };

    this.reValidateFoldersWithoutLists = function (folders) {
        for (var i = 0; i < folders.length; i++) {
            if (folders[i].contents.length === 0) {
                delete folders[i]['$invalid'];
            }
        }
    };

    this.openAll = function (tables, accountLists) {
        angular.forEach(tables, function (table) {
            table.open = !table.$invalid;
            angular.forEach(table.fields, function (field) {
                field.open = !field.$invalid;
            });
        });
        accountLists.open = !accountLists.$invalid;
        angular.forEach(accountLists.folders, function (folder) {
            folder.open = !folder.$invalid;
        });
    };

    this.closeAll = function (tables, accountLists) {
        angular.forEach(tables, function (table, i) {
            table.open = false;
            angular.forEach(table.fields, function (field) {
                field.open = false;
            });
        });
        accountLists.open = false;
        angular.forEach(accountLists.folders, function (folder) {
            folder.open = false;
        });
    };

    this.createFiltersObject = function (filters) {
        var filterValues = {
            account: { letter: 'A', display: "Account", api_key: "a" },
            campaign: { letter: 'C', display: "Campaign", api_key: "c" },
            opportunity: { letter: 'O', display: "Opportunity", api_key: "o" },
            lead: { letter: 'P', display: "People", api_key: "l" },
            campaign_member: { letter: 'T', display: "Activity", api_key: "cm" },
            terminus_account: { letter: 'T', display: "Terminus", api_key: "ta" }
        };
        var container = {
            tables: [],
            selected: 0,
            applied: 0
        };

        for (var key in filters) {
            if (filterValues[key]) {
                container.tables.push(this.createTableFilterObject(key, filters[key], filterValues[key]));
            }
        }
        return container;
    };

    this.createTableFilterObject = function (key, tableFields, tableDefaults) {
        var tableObj = {
            selected: 0,
            applied: 0,
            fields: tableFields,
            table: key
        };

        for (var k in tableDefaults) {
            tableObj[k] = tableDefaults[k];
        }

        for (var i = 0; i < tableFields.length; i++) {
            this.appendFieldObject(tableFields[i]);
        }

        return tableObj;
    };

    this.appendFieldObject = function (field) {
        field.selected = 0;
        field.applied = 0;
        field.all_selected = false;
        field.data = this.createValueObjects(field.data);
    };

    this.createValueObjects = function (values) {
        var valueObjs = [];
        for (var i = 0; i < values.length; i++) {
            valueObjs.push({ name: values[i], selected: false, applied: false });
        }
        return valueObjs;
    };

    this.transformOld = function (savedFilters) {
        var newStyle = {};
        for (var i = 0; i < savedFilters.length; i++) {
            if (savedFilters[i].filters.length > 0) {
                var tableKey = savedFilters[i].table;
                newStyle[tableKey] = {};
                for (var j = 0; j < savedFilters[i].filters.length; j++) {
                    var fieldKey = savedFilters[i].filters[j].number;
                    newStyle[tableKey][fieldKey] = [];
                    for (var k = 0; k < savedFilters[i].filters[j].selected.length; k++) {
                        newStyle[tableKey][fieldKey].push(savedFilters[i].filters[j].selected[k].split(":")[0]);
                    }
                }
            }
        }
        return newStyle;
    };

    this.toSavable = function (filterContainer) {
        var savable = {};
        for (var i = 0; i < filterContainer.tables.length; i++) {
            var tableObj = filterContainer.tables[i];
            for (var j = 0; j < tableObj.fields.length; j++) {
                var fieldObj = tableObj.fields[j];
                for (var k = 0; k < fieldObj.data.length; k++) {
                    var dataObj = fieldObj.data[k];
                    if (dataObj.selected) {
                        var newTableObj = savable[tableObj.table];
                        if (!newTableObj) {
                            newTableObj = {};
                            savable[tableObj.table] = newTableObj;
                        }
                        var newDataList = newTableObj[fieldObj.number];
                        if (!newDataList) {
                            newDataList = [];
                            newTableObj[fieldObj.number] = newDataList;
                        }
                        newDataList.push(dataObj.name);
                    }
                }
            }
        }
        return savable;
    };

    this.getGlobalFiltersFromDashboardParams = function(params) {
        return Object.keys(params).reduce((acc, key) => {
            if (key.indexOf('filter') === 0) {
                if (typeof params[key] === 'string') {
                    acc[key] = params[key].split(',').map(decodeURIComponent);
                } else {
                    acc[key] = params[key];
                }
            }

            return acc;
        }, {});
    };

    this.makeSelectedFilterMap = function (params) {
        var tableLookup = { a: "account", o: "opportunity", l: "lead", c: "campaign", cm: "campaign_member", ta: "terminus_account" };
        var selectedFilters = {};

        for (var key in params) {
            if (key.indexOf("filter") == 0 && params[key] && params[key].length > 0) {
                if (Array.isArray(params[key])) {
                    for (var i = 0; i < params[key].length; i++) {
                        var pair = params[key][i].split(":");
                        var tableFilters = selectedFilters[tableLookup[pair[1]]];
                        if (!tableFilters) {
                            tableFilters = {};
                            tableFilters[key] = [];
                            selectedFilters[tableLookup[pair[1]]] = tableFilters;
                        }
                        var tableValues = tableFilters[key];
                        if (!tableValues) {
                            tableValues = [];
                            tableFilters[key] = tableValues;
                        }
                        tableValues.push(pair[0]);
                    }
                } else {
                    var pair = params[key].split(":");
                    var tableFilters = selectedFilters[tableLookup[pair[1]]];
                    if (!tableFilters) {
                        tableFilters = {};
                        tableFilters[key] = [];
                        selectedFilters[tableLookup[pair[1]]] = tableFilters;
                    }
                    var tableValues = tableFilters[key];
                    if (!tableValues) {
                        tableValues = [];
                        tableFilters[key] = tableValues;
                    }
                    tableValues.push(pair[0]);
                }
            }
        }
        return selectedFilters;
    };

    this.initializeFilters = function (filters, selectedFilters, applyStatus) {
        var validApplyStati = ["save", "sync", "clear"];
        if (validApplyStati.indexOf(applyStatus) > -1) {
            filters.selected = 0;
            filters.select_apply_diff = 0;
            for (var i = 0; i < filters.tables.length; i++) {
                filters.selected += this.initializeTableFilters(filters.tables[i], selectedFilters, applyStatus, filters);
            }
            if (applyStatus !== "save") {
                filters.applied = applyStatus === "sync" ? filters.selected : 0;
            }
        } else {
            console.warn("not a valid apply status");
        }

    };

    this.initializeTableFilters = function (tableFilter, selectedFilters, applyStatus, container) {
        tableFilter.selected = 0;
        for (var i = 0; i < tableFilter.fields.length; i++) {
            var selectedFilter = selectedFilters[tableFilter.table] ? selectedFilters[tableFilter.table] : {};
            tableFilter.selected += this.initializeFieldFilters(tableFilter.fields[i], selectedFilter, applyStatus, container);
        }
        if (applyStatus !== "save") {
            tableFilter.applied = applyStatus === "sync" ? tableFilter.selected : 0;
        }
        return tableFilter.selected;
    };

    this.initializeFieldFilters = function (field, selectedFields, applyStatus, container) {
        field.selected = 0;
        field.all_selected = false;
        for (var i = 0; i < field.data.length; i++) {
            var selectedField = selectedFields[field.number] ? selectedFields[field.number] : [];
            field.selected += this.initializeDataFilters(field.data[i], selectedField, applyStatus, container);
        }
        field.all_selected = field.data.length === field.selected;
        if (applyStatus !== "save") {
            field.applied = applyStatus === "sync" ? field.selected : 0;
        }
        return field.selected;
    };

    this.initializeDataFilters = function (data, selectedData, applyStatus, container) {
        var selected = 0;
        if (selectedData.indexOf(data.name) > -1) {
            data.selected = true;
            if (applyStatus !== "save") {
                data.applied = applyStatus === "sync";
            }
            selected++;
        } else {
            data.selected = false;
            if (applyStatus !== "save") {
                data.applied = false;
            }
        }

        container.select_apply_diff += data.selected !== data.applied ? 1 : 0;

        return selected;
    };

    this.reconcileFilterState = function (filterContainer, setter, getter) {
        for (var i = 0; i < filterContainer.tables.length; i++) {
            var table = filterContainer.tables[i];
            for (var j = 0; j < table.fields.length; j++) {
                var field = table.fields[j];
                for (var k = 0; k < field.data.length; k++) {
                    field.data[k][setter] = field.data[k][getter];
                }
                field[setter] = field[getter];
            }
            table[setter] = table[getter];
        }
        filterContainer[setter] = filterContainer[getter];
        filterContainer.select_apply_diff = 0;
    };

    this.reconcileFolderState = function (folderContainer, setter, getter) {
        for (var i = 0; i < folderContainer.folders.length; i++) {
            var folder = folderContainer.folders[i];
            for (var j = 0; j < folder.contents.length; j++) {
                folder.contents[j][setter] = folder.contents[j][getter];
            }
            folder[setter] = folder[getter];
        }
        folderContainer[setter] = folderContainer[getter];
        folderContainer.select_apply_diff = 0;
    };



    this.getFiltersAsParams = function (filterContainer) {
        var params = {};
        if (filterContainer && filterContainer.tables) {
            for (var i = 0; i < filterContainer.tables.length; i++) {
                var table = filterContainer.tables[i];
                for (var j = 0; j < table.fields.length; j++) {
                    var field = table.fields[j];
                    for (var k = 0; k < field.data.length; k++) {
                        var data = filterContainer.tables[i].fields[j].data[k];
                        if (data.applied) {
                            var values = params[field.number];
                            if (!values) {
                                values = [];
                                params[field.number] = values;
                            }
                            values.push(data.name + ":" + table.api_key);
                        }
                    }
                }
            }
        }
        return params;
    };


}]);
