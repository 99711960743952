/**
 * Sets a URL for the authorize backend.
 */
(function () {
    'use strict';

    angular
        .module('jwt')
        .constant('Constants',
            {
                GRAILS_URL_VAR: 'GRAILS_URL',
                UNIFIED_LOGIN_URL: 'UNIFIED_LOGIN_URL',
                EMPLOYEE_USER: 'employee',
                OKTA_USER: 'okta',
                LOGOUT_ENDPOINT: '/logout',
                LOGIN_URL: '/login',
                PANIC_URL: '/panic',
                LOGIN_ENDPOINT: '/login',
                LEARN_MORE_ENDPOINT: '/learn_more',
                LOGIN_QUERY: '?p=data&skey=',
                LEARN_MORE_QUERY: '?p=data&utm_cust_domain=',
                API_URL: '/authenticate',
                UNIFIED_LOGIN_API_URL: '/login/handle_login_ul',
                BOMBORA : 'Bombora',
                TERMINUS_HUB: 'Terminus Hub',
                COOKIE_NAME: 'jwt_cookie',
                AUTH_CONT_COOKIE_NAME: 'auth_cont',
                HEADER: 'Bearer ',
                SUB: 'sub',
                BF_POD: 'bf.pod',
                TOKEN_STORAGE_KEY: 'ngx-tools-jwtTokenManagement',
                SPLIT_NAME: 'unified_login',
                LOGIN_TOKEN: 'token',
                LOGIN_PANIC: 'panic',
                TOKEN_EXPIRED: 'expired',
                SESSION_EXPIRED: 'session_expired',
                MISSING_PERMISSIONS: 'missing_permissions',
                MISSING_IDENTITY: 'identity',
            });
})();
