import {PBIdentifier} from './pbi-identifier';
import {
    MAX_RETRIES,
    PLATFORM_BROWSER_IDENTITY_ENV,
    USER_BROWSER_IDENTIFY_DECORATE_URL,
    USER_BROWSER_IDENTIFY_URL,
} from './constants';

export class PlatformBrowserIdentitySrvc {

    static $inject: Array<string> = ['$cookies', '$http', 'environmentSrvc', 'domainSrvc'];

    constructor(
        private $cookies: any,
        private $http: any,
        private environmentSrvc: any,
        private domainSrvc: any,
    ) {
    }

    private retry = (error: any, fn: any, params: any) => {
        switch (error.status) {
        case 429:
        case 500:
        case 503:
        case 504:
            if (params.retry < MAX_RETRIES) {
                params.retry++;
                return fn(params);
            } else {
                return Promise.reject(error);
            }
        default:
            return Promise.reject(error);
        }
    };

    private makePost(obj: { url: string; params: any; retry: number }): any {
        return this.environmentSrvc.getVariable(PLATFORM_BROWSER_IDENTITY_ENV).then(
            (url) => {
                return this.$http.post(url + obj.url,
                    JSON.stringify(obj.params),
                    {
                        withCredentials: true,
                    }).then((data) => {
                    return data.data;
                }, (error) => {
                    return this.retry(error, this.makePost, obj);
                });
            },
            () => {
                throw 'Platform Browser Identifier Service URL not defined';
            });

    }

    getIdentity(): Promise<PBIdentifier> {
        return this.makePost({
            url: USER_BROWSER_IDENTIFY_URL,
            params: {},
            retry: 0
        }).then((data) => {
            return new PBIdentifier(data.identity);
        });
    }

    getIdentityDecorate(orgUuid: string): void {
        this.makePost({
            url: USER_BROWSER_IDENTIFY_DECORATE_URL,
            params: {cust_hub_org_uuids: [orgUuid]},
            retry: 0
        });
    }
}
