(function () {
    'use strict';

    angular.module('accountJourney').controller('journeyTabController', journeyTabController);

    journeyTabController.$inject = ['$scope', '$rootScope',
        'chartDataGenerationService', 'accountJourneyService',
        'journeyTabService', 'journeyDateRangeGenerator', 'AccountJourneyConstants'];

    function journeyTabController ($scope, $rootScope,
        chartDataGenerationService, accountJourneyService,
        journeyTabService, journeyDateRangeGenerator, AccountJourneyConstants) {
        var self = this;
        self.tooltipData = {};
        self.sfdcUrl = $rootScope.sfdcURL;
        self.setSelectedOppty = applySelectedOpptyRegion;
        self.message = AccountJourneyConstants.noData.LABELS.ACTIVITY_NO_DATA;

        accountJourneyService.getData().then(function (data) {
            self.chartData = chartDataGenerationService.generateChartData(data,
                AccountJourneyConstants.JourneyData,
                AccountJourneyConstants.JourneyGraph, {cohort: 'days90', sfdcUrl: self.sfdcUrl});

            if (self.chartData && self.chartData.chartData) {
                self.chartData.emptyChart = journeyTabService.isAllChartDataEmpty(self.chartData.chartData);
            }

            self.opptyHistoryData = journeyTabService.opptyHistoryMapper(data['oppty'].data, data['oppty_history'].data);

            if (self.opptyHistoryData.length) {
                self.opptyHistoryData.unshift({
                    createdDate: null,
                    closedDate: null,
                    history: [],
                    name: 'None',
                    opportunityId: 'DEFAULT'
                });
                self.selectedOppty = self.opptyHistoryData[0];
            }

        });

        function applySelectedOpptyRegion() {
            var targetOppty = self.selectedOppty,
                selectedOpptyHistory = self.opptyHistoryData.filter(function (oppty) { return oppty.opportunityId === targetOppty.opportunityId; })[0],
                dateRange = journeyDateRangeGenerator.generateDateRange();

            self.chartData.chartConfig.regionArea = chartDataGenerationService.generateOpptyRegion(selectedOpptyHistory, dateRange);
        }
    }
})();
