/**
 * Add the onboarding module.
 */
(function () {
    'use strict';

    var app = angular
        .module('brightfunnelOnboard',
            ['jwt', 'environment']);


    appConfig.$inject = [];

    /**
     * Configuration for the application.
     */
    function appConfig() {
    }

    app.config(appConfig);

    /**
     * Run function for the application.
     */
    function appRun() {
    }

    app.run(appRun);

})();
