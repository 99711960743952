/**
 * Set the constants for eloqua.
 */
(function () {
    'use strict';

    angular
        .module('brightfunnelOnboard')
        .constant('onboardingConstants', {
            LANDING_PAGE: 'eloqua',
            TERMINUS_HUB: 'Terminus Hub',
            SERVICE_URL: '/v1/get_authorization_url',
            LOGIN_URL: '/eloqua',
            NOTY_SUCCESS: 'success',
            NOTY_ERROR: 'error',
            STORE_TOKEN_URL: '/v1/evaluate_oauth_callback',
            CONFIG_KEY: 'ELOQUA_INGEST_URL',
            CALLBACK_URL: '/eloqua/callback',
            AUTHORIZE_URL: '/v1/authorize',
            URL_MAPPING: {
                'studio.terminuplatform.com': 'app.brightfunnel.com',
                'studio.terminuplatform.ninja': 'dev.brightfunnel.com',
            }
        });
})();
