(function () {
    'use strict';

    angular.module('accountJourney').filter('orderByWithNullsLast',orderByWithNullsLast);

    orderByWithNullsLast.$inject = ['$filter'];

    function orderByWithNullsLast($filter) {
        return function (collection, expression, reverse) {
            var partitionedCollection = partitionByNulls(collection, expression);
            return $filter('orderBy')(partitionedCollection.valuesToSort, expression, reverse)
                .concat(partitionedCollection.nullValues);
        };

        function partitionByNulls(collection, expression) {
            var values = [];
            var nullValues = [];
            collection.forEach(function(element) {
                var value = getValue(element, expression);
                if (!value) {
                    nullValues.push(element);
                } else {
                    values.push(element);
                }
            });

            return {
                valuesToSort: values,
                nullValues: nullValues
            };
        }

        function getValue(element, expression) {
            return typeof expression === 'function' ? expression(element) : element[expression];
        }
    }
})();
