/**
 * Generates tooltips used in line and journey graphs
 */
(function () {
    'use strict';

    angular.module('accountJourney').service('accountJourneyToolTipContentGenerator',accountJourneyToolTipContentGenerator);

    accountJourneyToolTipContentGenerator.$inject = ['journeyDateRangeGenerator', 'tooltipTemplateService', 'tooltipDataFormatService', 'AccountJourneyConstants'];

    function accountJourneyToolTipContentGenerator(journeyDateRangeGenerator, tooltipTemplateService, tooltipDataFormatService, AccountJourneyConstants) {
        var service = {};

        service.generateToolTipFn = function (aggregateData, data, chartType, sfdcUrl) {
            if (chartType === AccountJourneyConstants.JourneyGraph) {
                return generateJourneyToolTipFn(aggregateData, data, sfdcUrl);
            }
            return undefined;
        };

        function generateJourneyToolTipFn(aggregateData, data, sfdcUrl) {
            return function (d, defaultTitleFormat, defaultValueFormat) {
                var dateRange = journeyDateRangeGenerator.generateDateRange();
                var $$ = this,
                    config = $$.config,
                    currentDate = dateRange[d[0].index],
                    currentCampaigns = aggregateData.marketing && aggregateData.marketing[currentDate] ? tooltipDataFormatService.formatCampaignData(aggregateData.marketing[currentDate].data) : undefined,
                    currentTasks = aggregateData.sales && aggregateData.sales[currentDate] ? tooltipDataFormatService.formatTaskData(aggregateData.sales[currentDate].data) : undefined,
                    nameFormat = config.tooltip_format_name || function (name) {
                        return name;
                    },
                    titleFormat = config.tooltip_format_title || defaultTitleFormat,
                    valueFormat = config.tooltip_format_value || defaultValueFormat,
                    intent = aggregateData.intent && aggregateData.intent[currentDate]  ? aggregateData.intent[currentDate].data[0] : undefined,
                    engagement = aggregateData.engagement && aggregateData.engagement[currentDate] ? aggregateData.engagement[currentDate].data[0] : undefined,
                    text;


                text = "<div class='journey-tooltip-container'>";

                text += tooltipTemplateService.generateSummaryTemplate(d, nameFormat, titleFormat, valueFormat);

                if (engagement && engagement.models) {
                    text += tooltipTemplateService.generateEngagementTemplate(engagement);
                }

                if (intent && intent.topics) {
                    text += tooltipTemplateService.generateIntentTemplate(intent);
                }

                if (currentCampaigns) {
                    text += tooltipTemplateService.generateCampaignTemplate(currentCampaigns, sfdcUrl);
                }

                if (currentTasks) {
                    text += tooltipTemplateService.generateTaskTemplate(currentTasks, sfdcUrl);
                }

                text += '</div>';

                return text;
            };
        }

        return service;
    }
})();
