/**
 * Constants to use in the user auth module
 */
(function () {
    'use strict';

    angular
        .module('brightfunnelUserAuth')
        .constant('userAuthConstants', {
            API: {
                REGISTER_URL: '/register/handle_register',
                FORGOT_URL: '/register/handle_forgot_password',
                REVERIFY_URL: '/register/handle_verify_email',
                REGISTER_SSO_URL: '/register/handle_sso_register',
                LOGIN_URL: '/login/handle_login',
                LOGIN_SSO_URL: '/login/handle_sso_login',
                LOGIN_SSO_ACCEPT_URL: '/login/handle_sso_accept',
                FORGOT_PAGE_URL: '/register/forgot_password',
                SSO_PAGE_URL: '/login/sso',
                UNIFIED_LOGIN_ENDPOINT: '/login?p=data',
                ENGAGE_PAGE_URL: 'https://my.terminus.com/',
                RESET_URL: '/register/handle_reset_password',
                CONFIRM_URL: '/register/handle_verify_registration',
                BITIUM_TOKEN_TAG: 'token',
                TOKEN_TAG: 't',
                TARGET_TAG: 'targetUri',
                COOKIE_NAME: 'jwt_cookie',
                TOKEN_STORAGE_KEY: 'ngx-tools-jwtTokenManagement',
                AUTH_CONT_KEY: 'auth_cont',
                SPLIT_COOKIE: 'term_ul_opt',
                AUTH_CONT_OKTA: 'okta',
                AUTH_CONT_EMPLOYEE: 'employee',
                GRAILS_KEY: 'GRAILS_URL',
                SPLIT_NAME: 'unified_login',
                UNIFIED_LOGIN_URL_VAR: 'UNIFIED_LOGIN_URL',
            },
            LABELS: {
                PAGE_TITLE: 'New User Registration',
                FORGOT_PAGE_TITLE: 'Forgot Password',
                REVERIFY_PAGE_TITLE: 'Send Re-verification Email',
                RESET_PAGE_TITLE: 'Reset Password',
                LOGIN_PAGE_TITLE: 'Log in to Terminus Hub',
                CONFIRM_PAGE_TITLE: 'Registration Confirmation',
                EMAIL: 'E-mail',
                PASSWORD: 'Password',
                RETYPE: 'Retype password',
                LOGIN_BTN: 'Log In',
                REGISTER_BTN: 'Create Account',
                SSO_LOGIN_BTN: 'Redirect to Identity Provider',
                SUBMIT_BTN: 'Submit',
                RESET_BTN: 'Reset Password',
                INFO: 'Password must be at least 8 characters long and contain: at least ' +
                    'one capital letter, at least one lowercase letter, at least one ' +
                    "number and at least one special character selected from the set '!@#$%^&'",
                FORGOT_INFO: "Enter your Terminus Hub email and we'll send you a link to reset " +
                    'your password',
                REVERIFY_INFO: 'Enter your username and we will send you a new verification ' +
                    'email for your account.',
                CONFIRM_INFO: 'Verifying your registration.',
                TERMS: 'I agree to the ',
                TERMS_2: 'terms of service',
                FORGOT_ENGAGE: 'Reset your Terminus Engage password',
                AUTHENTICATE_SSO: 'Authenticate with Single Sign On',
                ENGAGE_GO_TO: 'For web engagement and ad orchestration, go to',
                ENGAGE_GO_TO_LINK: 'Terminus Engage'
            },
            ERRORS: {
                SUCCESS: 'Your account registration email was sent - check your e-mail!',
                MESSAGE_SUCCESS: 'Message sent - check your e-mail!',
                RESET_SUCCESS: 'Your password was successfully reset.',
                LOGIN_SUCCESS: 'Login Success',
                UNKNOWN: 'Unexpected error',
                DEADLINE_EXCEEDED: 'The request timeout. Try again later',
                PERMISSION_DENIED: 'You need to request permission from your administrator to access this resource.',
                UNAUTHENTICATED: 'Sorry, we were not able to find a user with that email and password.',
                INVALID_EMAIL: 'The email provided is not valid',
                PASSWORD_MESSAGE: 'Your password is missing ',
                MISSING_NUMBER: 'a number',
                MISSING_UPPERCASE: 'an uppercase letter',
                MISSING_LOWERCASE: 'a lowercase letter',
                MISSING_SYMBOL: 'a symbol',
                SMALL_PASSWORD: 'Your password requires at least 8 characters',
                NOT_MATCH: 'Your passwords do not match.',
                NO_CODE: 'Sorry, we have no record of that request, or it has expired.',
                TOKEN_MISSING: 'Your credentials are missing. Please try again.',
            }
        });
})();
