(function () {
    'use strict';

    angular.module('accountJourney').controller('interestOnWebsiteCardController', interestOnWebsiteCardController);

    interestOnWebsiteCardController.$inject = ['$scope', 'chartDataGenerationService', 'accountJourneyService', 'AccountJourneyConstants'];

    function interestOnWebsiteCardController($scope, chartDataGenerationService, accountJourneyService, AccountJourneyConstants) {
        var self = this;
        self.headerText = 'Interest on Website';
        accountJourneyService.getData().then(function(data) {
            self.interestOnWebSiteFeatureEnabled = accountJourneyService.spikeModelFeatureEnabled(data);

            var spikeModelData = self.interestOnWebSiteFeatureEnabled ?
                data['anonymous'].data['spike_models']:
                accountJourneyService.mockSpikeModelData(data);

            self.interestOnWebSite = chartDataGenerationService.generateChartData(spikeModelData,
                AccountJourneyConstants.AnonymousData,
                AccountJourneyConstants.BarGraph, {
                    cohort : 'days90', latestDate: true
                },
                $scope);
        });

        // The height without the graph
        // We add the height of the graph in the watch to get the total component height
        var baseHeight = 59;

        $scope.$watch('interestOnWebsiteCardCtrl.interestOnWebSite.chartConfig.size.height', function(newValue) {
            if(newValue) {
                self.componentHeight = (newValue + baseHeight) + 'px';
            } else {
                self.componentHeight = 'auto';
            }
        });
    }
})();
