app.controller('permissionConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$cookies', 'api', '$state', '_', '$rootScope', 'navigation', function ($scope, $http, $window, $location, utilities, $cookies, api, $state, _, $rootScope, navigation) {
    $scope.cookies = $cookies;
    var loadLeadCompaniesCall;
    var loadLeadDomainsCall;
    var globalPropertyCall;
    var findOlfCountCall;

    $scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : { companyName: 'Acme Inc.' };

    // eslint-disable-next-line no-unused-vars
    function refresh_organizations() {
        $http.get('admin/organizations').success(function (data) {
            $scope.orgs.organizations = data;
        });
    }

    function applyMask(tab) {
        if ((tab == "permission" || tab == "users" || tab == "organization") && window.location.href.indexOf("stage.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        } else if ((tab == "dataCycle" || tab == "olf" || tab == "waterfall") && $scope.data.stageActive && window.location.href.indexOf("app.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        }
    }

    $scope.add_to_group = function (feature, type) {
        $scope.configs[type][feature.key].show = false;
        // eslint-disable-next-line no-unused-vars
        var uri = 'admin/add_feature_to_group?';
        uri += utilities.param.toQueryString({
            name: $scope.data.selectedFeatureGroupKey,
            child: feature.key,
            type: type,
            orgId: $scope.query.orgId
        });
    };

    $scope.add_feature_group = function () {
        $scope.data.featureGroups[$scope.data.newFeatureGroup] = [];
        $scope.data.selectedFeatureGroupKey = $scope.data.newFeatureGroup;
    };

    $scope.add_hide_feature = function (status, type) {
        var uri = 'admin/add_feature_state?';
        uri += utilities.param.toQueryString({
            name: $scope.query[type + "Id"],
            status: status,
            type: type,
            orgId: $scope.query.orgId
        });

        $http.get(uri).success(function (data) {
            $scope.hidden[type] = data;
        });
    };

    $scope.remove_hide_feature = function (obj) {
        var uri = 'admin/remove_feature_state?';
        uri += utilities.param.toQueryString({
            id: obj.id
        });

        $scope.hidden[obj.type].splice([$scope.hidden[obj.type].indexOf(obj)], 1);

        // eslint-disable-next-line no-unused-vars
        $http.get(uri).success(function (data) { });
    };

    $scope.orgChanged = function () {
        if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
        if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
        $state.current.data.loading = true;
        $scope.data.company = [];
        $scope.data.domain = [];
        $scope.data.olf = null;
        $http.get('admin/org_configurations?orgId=' + $scope.query.orgId).success(function (data) {
            $scope.hidden = data.features;
            $scope.query.groupId = Object.keys(data.groups)[0];
            $scope.org_info = data.org_info;
            $scope.platform = data.platform;

            $scope.data.featureGroups = data.groups;
            $scope.data.users = data.users;
            $scope.data.org_influence_rules = data.influence_rules;
            $scope.data.olf = data.olf;
            $scope.data.oppty = data.oppty;
            $scope.data.attribution = data.attribution;

            if ($scope.configs.extraCampaignDateTypes[data.org_info.id]) {
                $scope.configs.campaignDateTypes = $scope.configs.defaultCampaignDateTypes.concat($scope.configs.extraCampaignDateTypes[data.org_info.id]);
            }

            createReportTree($scope.data.reports[0], $scope.nav[0].children, data.active_reports);
            $state.current.data.loading = false;
        });
    };

    function createReportTree(item, children, stati) {
        if (children) {
            item.children = [];
            children.forEach(function (child) {
                var newItem = {
                    name: child.name,
                    title: (child.config.data ? child.config.data.title : navigation.extractName(child.name)),
                    status: stati[child.name] ? stati[child.name] : "removed",
                    alwaysAdd: child.config.alwaysAdd
                };

                item.children.push(newItem);
                createReportTree(newItem, child.children, stati);
                createReportTree(newItem, child.tabs, stati);
            });
        }
    }

    $scope.updateUserPermission = function (user, key) {
        var url = "admin/update_user_permission?";
        url += utilities.param.toQueryString({ username: user.username, level: key, add: user.permissions[key] });
        $http.get(url);

    };

    $scope.updateUserEnabled = function (user) {
        var url = "admin/update_user_enabled?";
        url += utilities.param.toQueryString({ username: user.username, enabled: user.enabled });
        $http.get(url);

    };

    $scope.updateMarketingRole = function (user) {
        var url = "admin/update_user_marketing_role";
        api.get(url, { username: user.username, role: user.marketing_role }, false, true);
    };

    $scope.updateOrgData = function (item) {
        api.get("admin/update_org_data", { field: item, value: $scope.org_info[item], orgId: $scope.org_info.id }, false, true);
    };

    $scope.clear_user_updates = function () {
        $scope.user_updates = {};
        $http.get('admin/users').success(function (data) {
            $scope.users = data;
            angular.forEach($scope.users, function (u) { u.updated_org_id = u.org_id; });
        });
    };

    $scope.updateNavStatus = function (item) {
        $state.current.data.loading = true;
        var keys = updateChildNavStatus(item.children, item.status);
        if (item.name) {
            keys.push(item.name);
        }
        var params = { keys: keys, status: item.status, orgId: $scope.query.orgId };
        api.set("admin/update_nav_setting", params, true).success(function () {
            $state.current.data.loading = false;
        });
    };

    $scope.shouldShowPermission = utilities.shouldShowPermission;

    function updateChildNavStatus(children, newStatus) {
        var keys = [];
        if (children) {
            children.forEach(function (child) {
                if (!child.alwaysAdd) {
                    child.status = newStatus;
                    keys.push(child.name);
                    var next = child.children ? child.children : child.tabs;
                    keys = keys.concat(updateChildNavStatus(next, newStatus));
                }
            });
        }
        return keys;
    }

    (function init() {
        $scope.query = {
            orgId: 0,
            pageId: "app.campaigns.attribution",
            featureId: "tot.rev.predict"
        };
        $scope.data = {
            featureGroups: {},
            selectedFeatureGroupKey: "",
            showEditFeatureGroup: false,
            mask: { permission: false, dataCycle: false, users: false, organization: false },
            //            stageActive: false,
            //            newAccount: "",
            ignoreType: "domain",
            ignoreOptions: ['domain', 'account', 'company'],
            domain: [],
            company: [],
            marketingRoles: [
                { value: "", ui: "" },
                { value: "demand generation", ui: "Demand Generation" },
                { value: "marketing operations", ui: "Marketing Operations" },
                { value: "field marketing", ui: "Field Marketing" },
                { value: "content marketing", ui: "Content Marketing" },
                { value: "digital marketing", ui: "Digital Marketing" },
                { value: "sales manager", ui: "Sales Manager" },
                { value: "executive", ui: "Executive" },
                { value: "other marketing", ui: "Other Marketing" }
            ],
            reports: [{
                title: "All",
                status: "",
                isTop: true,
                children: [],
                alwaysAdd: false
            }],
            activeOrgsOnly: true
        };
        $scope.configs = {
            page: {},
            //all new features added here should start with feature- and be broken into 3 parts with 2 -'s. this will add the setting to the org config table
            feature: {
                "waterfallStages": { key: "waterfallStages", name: "Waterfall Stages", show: true },
                "analyticsAttributedColumns": { key: "analyticsAttributedColumns", name: "Campaign Analytics/Web Activities Attributed Columns", show: true },
                "feature-model-influence_type": { key: "feature-model-influence_type", name: "Full Influence Type", show: true },
                "feature-model-influence_type_limited": { key: "feature-model-influence_type_without_attribution", name: "Limited Influence Type", show: true },
                "feature-scorecard_overview-new_ux": { key: "feature-scorecard_overview-new_ux", name: "Scorecard Overview New Ux", show: true },
                "olf-sfdc-push": { key: "feature-model-influence_type", name: "Orphan Lead SFDC Push", show: true },
                "feature-settings-migrated": { key: "feature-settings-migrated", name: "Settings New UI", show: true },
                "feature-segment-builder": { key: "feature-segment-builder", name: "Segment Builder", show: true },
            },
            reportOptions: [{ name: "Enabled", value: "enabled" }, { name: "Removed", value: "removed" }, { name: "Disabled Link", value: "disabled" }, { name: "URL Only", value: "url" }],
            //			org_influence_rules: {cio: [], lto: []},
            defaultCampaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            campaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            //            opptyAmountType: ['ARR', 'MRR'],
            //            pipePostTouchType: ['ON', 'OFF'],
            extraCampaignDateTypes: { 16: ["Last Member Status Change"] }
        };
        $scope._ = _;


        function reduce() {
            // eslint-disable-next-line no-unused-vars
            var m = _.flatten($scope.subnav.map(function (c) {
                return c.children;
            }));
        }

        reduce();

        $scope.orgs = {
            new_organization_name: '',
            organization: []
        };

        $http.get('admin/internal_config').success(function (data) {
            var active = [];
            data.orgs.forEach(function (item) {
                if (item.active) {
                    active.push(item);
                }
            });
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.orgs);
            $scope.configs.influence_rules = data.influence_rules;
        });

        (globalPropertyCall = api.get("globalProperty", { name: "stage.active" }, false, true)).then(function (data) {
            $scope.data.stageActive = data.data[0].value == "true";
        });

        $scope.$on('$destroy', function () {
            if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
            if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
            if (findOlfCountCall) { findOlfCountCall.abort(); }
            if (globalPropertyCall) { globalPropertyCall.abort(); }
        });

        applyMask("permission");
        //		refresh_organizations();
        //		$scope.clear_user_updates();
        //		$scope.show_unassigned_users = true;

    })();
}])
    .factory('isFeatureEnabled', ['$rootScope', function ($rootScope) {
        return function (featureName) {
            if (!featureName) {
                return true;
            }
            var parts = featureName.split('-');

            if (parts.length != 3) {
                return false;
            }

            return $rootScope.orgConfig && $rootScope.orgConfig.features && $rootScope.orgConfig.features[parts[1]]
                && $rootScope.orgConfig.features[parts[1]][parts[2]] === 'canAccess';
        };
    }]);
