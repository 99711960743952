(function () {
    'use strict';

    angular.module('accountJourney').controller('mostActivePersonCardController', mostActivePersonCardController);

    mostActivePersonCardController.$inject = ['$scope', 'insightTabService',
        'accountJourneyService'];

    function mostActivePersonCardController($scope, insightTabService, accountJourneyService) {
        var self = this;
        accountJourneyService.getData().then(function(data) {
            self.mostActivePerson = insightTabService.formatActivePersonData(data);
            self.peopleAdded = insightTabService.formatPeopleAdded(data);
            self.previousOpptys = insightTabService.formatPreviousOpportunityData(data);
            if(self.previousOpptys) {
                self.previousOpptyContact = self.previousOpptys.person
                    + (self.previousOpptys.title ? (' ' + self.previousOpptys.title) : '');
            }
        });
    }
})();
