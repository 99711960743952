(function () {
    'use strict';

    angular.module('accountJourney').service('tooltipDataFormatService',tooltipDataFormatService);

    tooltipDataFormatService.$inject = [];

    function tooltipDataFormatService() {
        var service = {};

        service.formatCampaignData = function (currentCampaigns) {
            var campaignData = {};

            if (currentCampaigns && currentCampaigns.length) {
                currentCampaigns.forEach(function (currentCampaign) {
                    var campaignId = currentCampaign.campaign.id,
                        contact = currentCampaign.contact;
                    if (!campaignData[campaignId]) {
                        campaignData[campaignId] = {
                            campaign: currentCampaign.campaign,
                            contacts: [],
                        };
                    }
                    campaignData[campaignId].contacts.push(contact);
                });
            }

            return campaignData;
        };


        service.formatTaskData = function (currentTasks) {
            var taskData = {};

            if (currentTasks && currentTasks.length) {
                currentTasks.forEach(function (person) {
                    if (!taskData[person.activityId]) {
                        taskData[person.activityId] = {
                            people: {},
                            subject: person.subject
                        };
                    }

                    if (taskData[person.activityId]['people'][person.leadId]) {
                        taskData[person.activityId]['people'][person.leadId].count++;
                    } else {
                        taskData[person.activityId]['people'][person.leadId] = {
                            name: person.name,
                            leadId: person.leadId,
                            count: 1
                        };
                    }
                });
            }

            return taskData;
        };

        return service;
    }
})();
