(function () {
    'use strict';

    angular.module('accountJourney').controller('peopleTabController', peopleTabController);

    peopleTabController.$inject = ['peopleTabService', '$scope',
        'accountJourneyService', 'AccountJourneyConstants'];

    function peopleTabController(peopleTabService, $scope, accountJourneyService,
        AccountJourneyConstants) {
        var self = this;
        var currentRow = undefined;
        self.displayedCollection = [];
        self.message = AccountJourneyConstants.noData.LABELS.PEOPLE_NO_DATA;

        accountJourneyService.getData().then(function(data){
            self.rowCollection = peopleTabService.mapPeople(data);
        });

        self.opttyCellLink = function(row) {
            return row.lastOpptyId ? $scope.shref('app.analyze.opportunities.opportunitySpecific.attribution.totalTouches',
                {oppty: row.lastOpptyId, isId: true}) : false;
        };

        self.handleRowOpen = function(event) {
            if(event.data && event.data.autoCollapseFn) {
                currentRow = event.data.autoCollapseFn(currentRow);
            }
        };
    }

    angular.module('accountJourney').directive('stSortBy', function() {
        return {
            require: 'stTable',
            link: function (scope, element, attr, ctrl) {
                attr.$observe('stSortBy', function (newValue) {
                    if(newValue) {
                        ctrl.sortBy(newValue, true);
                    }
                });
            }
        };
    });
})();
