(function () {
    'use strict';

    angular.module('accountJourney').directive('interestOnWebsiteCard',interestOnWebsiteCard);

    interestOnWebsiteCard.$inject = [];

    function interestOnWebsiteCard() {
        return {
            restrict:  'E',
            template: require('./interest-on-website-card.html'),
            controller: 'interestOnWebsiteCardController',
            controllerAs: 'interestOnWebsiteCardCtrl',
        };
    }
})();
