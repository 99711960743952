/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('activityTabService', activityTabService);

    activityTabService.$inject = [];

    function activityTabService() {
        var service = {};
        var spikeModelTypes = {
            page_views: 'Weekly Page Views',
            spike_models: 'Weekly Engagement Spike',
            intent_topics: 'Weekly Intent Surge'
        };

        service.mapActivityData = function (data) {
            var keys = Object.keys(data || {});
            if (!keys) {
                return {};
            }
            return _.flatMap(keys, function (key) {
                switch (key) {
                case 'touches':
                    return marketingMapper(data[key]);
                case 'sales':
                    return salesMapper(data[key]);
                case 'anonymous':
                    return anonymousMapper(data[key]);
                case 'oppty':
                    return opptyMapper(data[key], data['oppty_history']);
                case 'oppty_history':
                    return opptyHistoryMapper(data[key]);
                default:
                    return [];
                }
            });
        };

        function marketingMapper(marketingData) {
            return marketingData.data.activity_list.map(function (m) {
                return {
                    date: m.activity_date,
                    activityName: m.campaign.name,
                    touchType: 'Marketing',
                    activityType: m.campaign.revenue_group,
                    fullName: m.contact.name,
                    contactID: m.contact.id,
                    title: m.contact.title,
                    email: m.contact.email,
                    activityId: m.campaign.campaignMemberId
                };
            });
        }

        function salesMapper(salesData) {
            return salesData.data.tasks.map(function (s) {
                return {
                    date: s.created_date,
                    activityName: s.subject,
                    touchType: 'Sales',
                    activityType: s.type,
                    fullName: s.name, contactID: s.leadId,
                    title: s.title, email: s.email,
                    activityId: s.activityId
                };
            });
        }

        function anonymousMapper(anonymousData) {
            function getActivityName(a) {
                if (a.counts) {
                    return a.counts;
                }
                return (a.topics ? a.topics.join(', ') : a.models.join(', '));
            }

            var keys = Object.keys(anonymousData.data || {});
            if (!keys) {
                return {};
            }

            return _.flatMap(keys, function (key) {
                return anonymousData.data[key].map(function (data) {
                    return {
                        date: moment(data.date).valueOf(),
                        activityName: getActivityName(data),
                        touchType: 'Anonymous',
                        activityType: spikeModelTypes[key],
                        fullName: '',
                        contactId: '',
                        title: '',
                        email: '',
                        displayPageViewLabel: data.counts ? true : false
                    };
                });
            });
        }

        function opptyMapper(opptyData, opptyHistoryData) {
            var latestOpptyDates = calculateLatestStartDates(opptyHistoryData);

            var keys = opptyData.data || {};

            return _.flatMap(keys, function (t) {
                var mappedDataForOppty = [];
                mappedDataForOppty.push({
                    date: t.created_date,
                    activityName: t.name,
                    touchType: 'Opportunity',
                    activityType: 'Opportunity Created',
                    fullName: t.contactName || '',
                    contactID: t.contactId,
                    title: t.title || '',
                    email: t.email || '',
                    activityId: t.id
                });
                if (t.close_date) {
                    mappedDataForOppty.push({
                        date: calculateOpptyCloseDate(t.close_date, latestOpptyDates[t.id]),
                        activityName: t.name,
                        touchType: 'Opportunity',
                        activityType: 'Opportunity Closed',
                        fullName: t.contactName || '',
                        contactID: t.contactId,
                        title: t.title || '',
                        email: t.email || '',
                        activityId: t.id
                    });
                }
                return mappedDataForOppty;
            });
        }

        function opptyHistoryMapper(opptyHistoryData) {

            var keys = Object.keys(opptyHistoryData.data || {});
            if (!keys) {
                return {};
            }

            return _.flatMap(keys, function (key) {
                return Object.keys(opptyHistoryData.data[key].history).map(function (historyKey) {
                    var t = opptyHistoryData.data[key].history[historyKey];
                    return {
                        date: t.stage_start_date,
                        activityName: t.oppty_name,
                        touchType: 'Opportunity',
                        activityType: 'Stage: ' + t.stage_name,
                        fullName: t.contact_name || '',
                        contactID: t.contact_id,
                        title: t.title || '',
                        email: t.email || '',
                        activityId: t.opportunity_id
                    };
                });
            });
        }

        // We update the opportunity close date so that it is after the latest
        // date among all the opportunity stages. This way the close will appear after
        // all of it's stages
        function calculateOpptyCloseDate(opptyCloseDate, latestOpptyDate) {
            if (!latestOpptyDate) {
                return opptyCloseDate;
            }
            return moment(latestOpptyDate).isAfter(opptyCloseDate) ? moment(latestOpptyDate).add(10, 's').valueOf() : opptyCloseDate;
        }

        // Create a map from opportunity id to the latest date amongst all stages
        function calculateLatestStartDates(opptyHistoryData) {
            var latestOpptyDate = {};
            var keys = Object.keys(opptyHistoryData.data || {});
            if (!keys) {
                return {};
            }

            _.flatMap(keys, function (key) {
                return Object.keys(opptyHistoryData.data[key].history).map(function (historyKey) {
                    var t = opptyHistoryData.data[key].history[historyKey];
                    latestOpptyDate[t.opportunity_id] = !latestOpptyDate[t.opportunity_id]
                        ? t.stage_start_date
                        : moment(t.stage_start_date).isAfter(latestOpptyDate[t.opportunity_id]) ? t.stage_start_date : latestOpptyDate[t.opportunity_id];
                });
            });
            return latestOpptyDate;
        }

        return service;
    }
})();
