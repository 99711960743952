/**
 * Controller for the card directive.
 */
(function () {
    'use strict';

    angular
        .module('accountJourney')
        .directive('infoCard', function () {
            return {
                restrict: 'E',
                template: require('./info-card.html'),
                controller: infoCardCtrl,
                controllerAs: '$ctrl',
                bindToController: true,
                scope: { },
                transclude: true
            };
        });

    infoCardCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function infoCardCtrl() { }

})();
