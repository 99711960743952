/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').filter('currentDate', currentDate);

    currentDate.$inject = ['$filter'];

    /**
     * Controller.
     * @param $scope
     */
    function currentDate() {
        return function() {
            return moment().format('MM-DD-YYYY-h:mm:ss');
        };
    }
})();
