/* eslint-disable no-undef */
(function () {
    'use strict';

    angular.module('accountJourney').service('chartConfigBuilderService', chartConfigBuilderService);

    chartConfigBuilderService.$inject = ['$compile', 'AccountJourneyConstants', 'journeyDateRangeGenerator'];

    function chartConfigBuilderService($compile, AccountJourneyConstants, journeyDateRangeGenerator) {
        var service = {};

        service.build = function (aggregateData, data, chartType, scope) {
            if (!aggregateData) {
                return {};
            }
            var chartConfig = {};
            chartConfig.size = buildSize(aggregateData, chartType);
            chartConfig.bar = buildBar(chartType);
            chartConfig.data = data ? data : buildData(aggregateData, chartType);
            chartConfig.axis = buildAxis(chartConfig.data.columns, chartType);
            chartConfig.padding = { top: 5 };

            if (chartType === AccountJourneyConstants.BarGraph) {
                chartConfig.padding.right = 20;
            }

            if (chartType !== AccountJourneyConstants.JourneyGraph) {
                chartConfig.legend = { 'hide': true };
            }

            if (chartType !== AccountJourneyConstants.BarGraph) {
                chartConfig.point = { focus: { expand: { r: 5 } } };
            }

            if (chartType === AccountJourneyConstants.LineGraph) {
                chartConfig.tooltip = { show: false };
                chartConfig.point.show = false;
            }

            if (chartType === AccountJourneyConstants.BarGraph) {
                chartConfig.tooltip = { show: false };

                chartConfig.onrendered = function () {
                    const self = this;
                    const xLabel = d3.selectAll(self.config.bindto + ' .tick');
                    const categoryValue = self.api.categories();
                    if (xLabel) {
                        xLabel.attr({
                            'tooltip-placement': 'top',
                            'tooltip-append-to-body': 'true',
                            'uib-tooltip': (d) => categoryValue[d],
                        }).on('mouseenter', function () {
                            $compile(xLabel[0])(scope);
                        });
                    }
                };
            }

            if (chartType === AccountJourneyConstants.JourneyGraph) {
                chartConfig.grid = {
                    'y': {
                        'show': true
                    }
                };

                chartConfig.point.r = 5;

                chartConfig.onrendered = function () {
                    var yLabel = d3.selectAll(this.config.bindto + ' .c3-axis-y-label'),
                        y2Label = d3.selectAll(this.config.bindto + ' .c3-axis-y2-label');
                    if (yLabel && y2Label) {
                        var xOffset = -y2Label.attr('x'),
                            dyOffset = yLabel.attr('dy');

                        // invert attributes generated by c3
                        y2Label.attr({
                            dy: dyOffset,
                            transform: 'rotate(90)',
                            x: xOffset
                        });
                    }
                };
            }
            return chartConfig;
        };

        service.buildRegion = function (className, startDate, endDate, dateRange, labels) {
            var region = {};
            var startDateIndex = dateRange.indexOf(startDate);
            var endDateIndex = dateRange.indexOf(endDate);

            region.class = className;
            region.labels = [].concat(labels);
            region.start = startDateIndex >= 0 ? startDateIndex : 0;

            if (startDateIndex === endDateIndex) {
                region.end = endDateIndex + 1;
            } else if (endDateIndex < 0) {
                region.end = dateRange.length;
            } else {
                region.end = endDateIndex;
            }

            return region;
        };

        function buildBar(chartType) {
            if (chartType === AccountJourneyConstants.JourneyGraph) {
                return { width: { ratio: 0.5 } };
            }
            if (chartType === AccountJourneyConstants.BarGraph) {
                return { width: { ratio: 0.25 } };
            }
            return {};
        }

        function buildAxis(aggregateData, chartType) {
            var axis = {};
            if (chartType === AccountJourneyConstants.BarGraph) {
                axis.rotated = true;
            }
            axis.x = buildXAxis(chartType);
            axis.y = buildYAxis(aggregateData, chartType);
            axis.y2 = buildY2Axis(aggregateData, chartType);
            return axis;
        }

        function buildXAxis(chartType) {
            var x = { show: chartType !== AccountJourneyConstants.LineGraph, tick: { outer: false }, type: 'category' };
            if (chartType === AccountJourneyConstants.JourneyGraph) {
                x.height = 75;
                x.tick.multiline = false;
                x.tick.rotate = -45;
                x.label = { position: 'outer-center', text: 'Activities by Week' };
            } else if (chartType === AccountJourneyConstants.BarGraph) {
                x.tick.format = function (x) {
                    const maxCharSize = 15;
                    const value = this.api.categories()[x];
                    return value && value.length > maxCharSize
                        ? `${value.substring(0, maxCharSize - 3)}...`
                        : value;
                };
            }
            return x;
        }

        function buildY2Axis(chartData, chartType) {
            if (chartType === AccountJourneyConstants.JourneyGraph) {
                var y2 = {};
                y2.default = [0, 1, 2, 3, 4];
                y2.label = { position: 'outer-middle', text: 'Anonymous Page Views' };
                y2.min = 0;
                y2.max = offsetJourneyY2Axis(chartData[3].slice(1));
                y2.padding = { bottom: 0, top: 0 };
                y2.show = true;
                y2.tick = { count: 5, outer: false };
                y2.tick.format = tickFormatter;
                return y2;
            } else {
                return { label: { text: '' } };
            }

        }

        function buildYAxis(chartData, chartType) {
            var y = {};
            y.label = { text: '' };
            y.padding = { bottom: 0 };
            y.min = 0;
            y.tick = { outer: false };
            if (chartType === AccountJourneyConstants.LineGraph) {
                y.default = [0, 2, 4, 6, 8, 10, 12, 14];
                y.padding.top = 5;
                y.tick.count = 2;
                y.tick.format = tickFormatter;
            } else if (chartType === AccountJourneyConstants.BarGraph) {
                y.default = [0, 2, 4, 6, 8, 10, 12, 14];
                y.label.position = 'outer-middle';
                y.padding.top = 1;
                y.max = 14;
                y.tick.centered = true;
                y.tick.multiline = false;
            } else if (chartType === AccountJourneyConstants.JourneyGraph) {
                y.max = offsetJourneyYAxis(chartData[1].slice(1), chartData[2].slice(1));
                y.default = [0, 5, 10, 15, 20];
                y.label.position = 'outer-middle';
                y.label.text = 'Marketing and Sales Activites';
                y.padding.top = 0;
                y.tick.count = 5;
                y.tick.format = tickFormatter;
            }
            return y;
        }

        function tickFormatter(d) {
            return d3.round(d);
        }

        function buildData(aggregateData, chartType) {
            var data = {};
            var columns = [];
            var dateRange;

            if (chartType === AccountJourneyConstants.JourneyGraph) {
                columns.push(['x']);
                columns.push(['Sales Activity']);
                columns.push(['Marketing Activity']);
                columns.push(['Page Views']);
                data.types = {
                    'Sales Activity': 'bar',
                    'Marketing Activity': 'bar',
                    'Page Views': 'spline'
                };
                dateRange = journeyDateRangeGenerator.generateDateRange();

                dateRange.forEach(function (date) {
                    columns[0].push(date);
                    columns[1].push(aggregateData['sales'][date] ? aggregateData['sales'][date]['total'] : 0);
                    columns[2].push(aggregateData['marketing'][date] ? aggregateData['marketing'][date]['total'] : 0);
                    columns[3].push(aggregateData['pageViews'][date] ? aggregateData['pageViews'][date]['total'] : 0);
                });

                data.colors = {
                    'Sales Activity': '#0098CE',
                    'Marketing Activity': '#F4A71C',
                    'Page Views': '#9428A0'
                };

                data.axes = {
                    'Sales Activity': 'y',
                    'Marketing Activity': 'y',
                    'Page Views': 'y2'
                };
            } else if (chartType === AccountJourneyConstants.LineGraph) {
                columns.push(['x']);
                columns.push(['total']);

                dateRange = journeyDateRangeGenerator.generateDateRange();

                dateRange.forEach(function (date) {
                    columns[0].push(date);
                    columns[1].push(aggregateData[date] ? aggregateData[date].total : 0);
                });
                data.type = chartType;
            } else {
                columns.push(['x']);
                columns.push(['total']);

                aggregateData.sort(function (a, b) {
                    return b.total - a.total;
                });

                aggregateData.forEach(function (d) {
                    columns[0].push(addSpaceIfEmptyString(d.text));
                    columns[1].push(d.total);
                });
                data.type = chartType;
            }

            data.columns = columns;
            data.x = 'x';
            data.empty = { empty: { label: { text: 'No Data Available' } } };

            return data;
        }

        function addSpaceIfEmptyString(text) {
            return text || ' ';
        }

        function buildSize(aggregateData, chartType) {
            if (chartType === AccountJourneyConstants.JourneyGraph) {
                return { height: 450 };
            } else if (chartType === AccountJourneyConstants.LineGraph) {
                return { height: 125 };
            } else if (chartType === AccountJourneyConstants.BarGraph) {
                return { height: calculateBarGraphHeight(aggregateData) };
            }
        }

        function calculateBarGraphHeight(aggregateData) {
            var suggestedHeight = aggregateData.length * 40;
            return suggestedHeight < 150 ? 150 : suggestedHeight;
        }

        function offsetJourneyYAxis(salesActivity, marketingActivity) {
            var salesMax, marketingMax;

            salesMax = maxOfArray(salesActivity);

            marketingMax = maxOfArray(marketingActivity);

            return salesMax > marketingMax ? offsetAxis(salesMax) : offsetAxis(marketingMax);
        }

        function offsetJourneyY2Axis(siteVisits) {
            var y2Max = maxOfArray(siteVisits);
            return offsetAxis(y2Max);
        }

        function offsetAxis(value) {
            var padding = 4;
            var offset = 0;
            offset = value + padding - (value % padding);
            return offset;
        }

        function maxOfArray(numArray) {
            return Math.max.apply(null, numArray);
        }

        return service;
    }
})();

