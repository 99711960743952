import {ITokenService} from './interfaces';

export class DynamicsSrvc implements ITokenService {

    static $inject: Array<string> = ['$window', 'onboardingConstants', 'authSrvc', 'utilsSrvc'];

    constructor(private $window: any, private onboardingConstants: any,
                private authSrvc: any, private utilsSrvc: any) {
        return;
    }

    addSubmitParams(paramsObj: any, vars: any): void {
        paramsObj.params[this.onboardingConstants.FIELDS_FIELD] = this.utilsSrvc.jsonToFieldObject(vars);
        paramsObj.params[this.onboardingConstants.SERVICE_ID_FIELD] = paramsObj.state.id;
        paramsObj.params[this.onboardingConstants.CALLBACK_URL_FIELD] = this.$window.location.protocol +
            '//' + this.$window.location.host +
            this.onboardingConstants.ONBOARDING_CALLBACK_URL;
    }

    storeParams(paramsObj: any): any {
        const jwt = this.authSrvc.getParsedJwt(this.onboardingConstants.TERMINUS_HUB);
        const orgAcl = {}, params = {}, vars = {};
        const error =
            this.utilsSrvc.getParamFromQueryString(this.onboardingConstants.ERROR_FIELD);
        if (error) {
            return {
                error: error,
                serviceName: paramsObj.state.name
            };
        }
        orgAcl[this.onboardingConstants.SUB_ORG_CLAIM] = jwt[this.onboardingConstants.SUB_ORG_CLAIM];
        orgAcl[this.onboardingConstants.SUB_CLAIM] = jwt[this.onboardingConstants.SUB_CLAIM];
        params[this.onboardingConstants.ACL_FIELD] = this.utilsSrvc.jsonToFieldObject(orgAcl);
        vars[this.onboardingConstants.CODE_FIELD] =
            this.utilsSrvc.getParamFromQueryString(this.onboardingConstants.CODE_FIELD);
        vars[this.onboardingConstants.STATE_FIELD] =
            this.utilsSrvc.getParamFromQueryString(this.onboardingConstants.STATE_FIELD);
        params[this.onboardingConstants.FIELDS_FIELD] = this.utilsSrvc.jsonToFieldObject(vars);
        return {
            params: params,
            serviceName: paramsObj.state.name
        };
    }
}
