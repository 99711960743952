/**
 * Onboarding Service.
 */
(function () {
    'use strict';

    angular
        .module('brightfunnelOnboard')
        .factory('onboardingSrvc', onboardingSrvc);

    onboardingSrvc.$inject = ['$http', 'authSrvc', 'onboardingConstants',
        'Constants', '$q', 'environmentSrvc', 'storageSrvc', 'pardotSrvc',
        'salesforceSrvc', 'hubspotSrvc', 'dynamicsSrvc'];

    /**
     * Service to get onboarding data.
     */
    function onboardingSrvc($http, authSrvc, onboardingConstants, Constants,
        $q, environmentSrvc, storageSrvc, pardotSrvc, salesforceSrvc,
        hubspotSrvc, dynamicsSrvc) {
        const SUB_ORG = 'sub.org';
        let service = {},
            domain = '';

        function config() {
            return environmentSrvc.getVariable(onboardingConstants.TOKEN_MANAGER_DOMAIN_KEY).then(function (value) {
                domain = value;
            }, function (error) {
                throw error;
            });
        }

        function authorizeSrvc(error, fn, params) {
            switch (error.status) {
            case 403:
                return $http.get(`${domain}${onboardingConstants.AUTHORIZE_URL}`).then(
                    function (authorizeResponse) {
                        const tokenObj = authSrvc.parseJwt(authorizeResponse.data.token);
                        if (!tokenObj['tkn.mng']) {
                            throw { data: "The user cannot access this page"};
                        }
                        authSrvc.writeJwtToCookie(authorizeResponse.data.token);
                        initialize();
                        return fn(params);
                    });
            default:
                return $q.reject(error);
            }
        }

        service.getServiceNames = function () {
            let jwtObj = authSrvc.getParsedJwt(onboardingConstants.TERMINUS_HUB);
            let params = {
                orgUuid: jwtObj[SUB_ORG]
            };
            return config().then(() => {
                return $http.post(`${domain}${onboardingConstants.SERVICE_NAMES_URL}`, params).then((response) => {
                    return response.data;
                }, (error) => {
                    return authorizeSrvc(error, service.getServiceNames, params);
                });
            });
        };

        service.getCredentialFields = function (serviceId) {
            const params = { service: {serviceId}};
            return config().then(() => {
                return $http.post(`${domain}${onboardingConstants.CREDENTIAL_FIELDS_URL}`, params).then((response) => {

                    return response.data;
                }, (error) => {
                    return authorizeSrvc(error, service.getCredentialFields, params);
                });
            });
        };

        function getOAuthUrl(params) {
            return config().then(() => {
                return $http.post(`${domain}${onboardingConstants.AUTHORIZATION_URL}`, params).then((response) => {
                    return response.data;
                }, (error) => {
                    return authorizeSrvc(error, getOAuthUrl, params);
                });
            });
        }

        service.submit = function (serviceObj, vars) {
            let paramsObj = {state: serviceObj, params: {}};
            switch (paramsObj.state.name) {
            case 'PARDOT':
                pardotSrvc.addSubmitParams(paramsObj, vars);
                storageSrvc.save([onboardingConstants.STORAGE_TOKEN], JSON.stringify(paramsObj));
                service.redirect(onboardingConstants.ONBOARDING_CALLBACK_URL);
                return $q.when();
            case 'SALESFORCE':
                salesforceSrvc.addSubmitParams(paramsObj, vars);
                storageSrvc.save([onboardingConstants.STORAGE_TOKEN], JSON.stringify(paramsObj));
                return getOAuthUrl(paramsObj.params).then(function (response) {
                    service.redirect(response.oauthUrl);
                });
            case 'HUBSPOT':
                hubspotSrvc.addSubmitParams(paramsObj, vars);
                storageSrvc.save([onboardingConstants.STORAGE_TOKEN], JSON.stringify(paramsObj));
                return getOAuthUrl(paramsObj.params).then(function (response) {
                    service.redirect(response.oauthUrl);
                });
            case 'DYNAMICS':
                dynamicsSrvc.addSubmitParams(paramsObj, vars);
                storageSrvc.save([onboardingConstants.STORAGE_TOKEN], JSON.stringify(paramsObj));
                return getOAuthUrl(paramsObj.params).then(function (response) {
                    service.redirect(response.oauthUrl);
                });
            default:
                return $q.reject({ data: { message: onboardingConstants.UNKNOWN_INTEGRATION_ERROR }});
            }
        };

        service.save = function (paramsObj) {
            let storeObj;

            if (!paramsObj.state) {
                return $q.reject({ data: { message: onboardingConstants.UNKNOWN_INTEGRATION_ERROR }});
            }
            switch (paramsObj.state.name) {
            case 'PARDOT':
                storeObj = pardotSrvc.storeParams(paramsObj);
                break;
            case 'SALESFORCE':
                storeObj = salesforceSrvc.storeParams(paramsObj);
                break;
            case 'HUBSPOT':
                storeObj = hubspotSrvc.storeParams(paramsObj);
                break;
            case 'DYNAMICS':
                storeObj = dynamicsSrvc.storeParams(paramsObj);
                break;
            default:
                storeObj = {
                    error: onboardingConstants.UNKNOWN_INTEGRATION_ERROR,
                    serviceName: paramsObj.state.name,
                };
            }
            if (storeObj.error) {
                return $q.reject({ data: { message: storeObj.error}});
            }
            return config().then(() => {
                return $http.post(`${domain}${onboardingConstants.STORE_TOKEN_URL}${storeObj.serviceName.toLowerCase()}`,
                    storeObj.params).then((response) => {
                    return response.data;
                }, (error) => {
                    return authorizeSrvc(error, service.save, storeObj.params).catch((authorizeError) => {
                        throw authorizeError;
                    });
                });
            });
        };

        /**
         * Setup FullStory
         */
        function setupFullStory() {
            window['_fs_debug'] = false;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_org'] = 'NBGR';
            window['_fs_namespace'] = 'FS';
            (function (m, n, e, t, l, o, g, y) {
                if (e in m) {
                    if (m.console && m.console.log) {
                        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
                    }
                    return;
                }
                g = m[e] = function (a, b, s) {
                    g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
                };
                g.q = [];
                o = n.createElement(t);
                o.async = 1;
                o.src = 'https://' + window['_fs_host'] + '/s/fs.js';
                y = n.getElementsByTagName(t)[0];
                y.parentNode.insertBefore(o, y);
                g.identify = function (i, v, s) {
                    g(l, {uid: i}, s);
                    if (v) g(l, v, s);
                };
                g.setUserVars = function (v, s) {
                    g(l, v, s);
                };
                g.event = function (i, v, s) {
                    g('event', {n: i, p: v}, s);
                };
                g.shutdown = function () {
                    g("rec", !1);
                };
                g.restart = function () {
                    g("rec", !0);
                };
                g.consent = function (a) {
                    g("consent", !arguments.length || a);
                };
                g.identifyAccount = function (i, v) {
                    o = 'account';
                    v = v || {};
                    v.acctId = i;
                    g(o, v);
                };
                g.clearUserCookie = function () {
                };
            })(window, document, window['_fs_namespace'], 'script', 'user');
        }

        /**
         * Initialize Fullstory for the user
         */
        service.initFullStory = function () {
            authSrvc.storeToken(onboardingConstants.TERMINUS_HUB);

            return authSrvc.isLoggedIn(onboardingConstants.TERMINUS_HUB).then(() => {
                let jwtObj = authSrvc.getParsedJwt(onboardingConstants.TERMINUS_HUB);

                if (!window['FS']) {
                    setupFullStory();
                }

                var uid = jwtObj[Constants.SUB];
                let colonIndex = uid.indexOf(':') + 1;
                var userVars = {
                    email: uid.substring(colonIndex),
                    orgUuid: jwtObj[SUB_ORG]
                };
                window['FS'].identify(uid, userVars);
            }, (error) => {
                authSrvc.goToEndpoint(error, window.location.pathname);
                throw 'Redirecting to login';
            });
        };

        /**
         * Redirect the window to a specific url
         * @param url The url to redirect
         */
        service.redirect = function (url) {
            window.location.href = url;
        };

        function initialize() {
            authSrvc.storeToken(onboardingConstants.TERMINUS_HUB);
            service.initFullStory();
        }

        initialize();

        return service;
    }
})();
