
/**
 * Bootstraps environment vars from built-in object and a
 * dynamic fetch of /environment.json for production builds.
 *
 * Overrides can be passed in for local testing when loading
 * a production /environment.json
 *
 * Note: This code warns of any discrepancies between the two.
 * Use ensureEnvironmentValidity
 *
 * @param builtinNinjaEnvJson object that defines the expected shape of environment.json.
 *      This should come from a direct import of environment/environment.json in the consuming app
 *
 * @param isProductionConfiguration boolean for whether this is a production configuration build.
 *      If true, a call for /environment.json happens and is used
 *      If false, no call is made, and builtin argument is used
 *
 * @param overrides local override of env values for testing.
 *      Note: DO NOT commit code that uses this override. It's a blunt tool and circumvents our
 *      environment settings tracking done through our *-infra repos!
 *
 * @returns Resolved with the finalized environment object, or rejected with errors.
 */
export function bootstrapEnvironmentVars(
    $http, $q,
    builtinNinjaEnvJson,
    isProductionConfiguration,
    overrides={}
){
    overrides = Object.assign( {},
        { production: isProductionConfiguration, }, 
        overrides
    );
    
    // If we aren't a production build,
    // just use the compiled-in (ninja) env vars with optional overrides
    if (!isProductionConfiguration) {
        if (document.location.search === '?health-check') {
            // eslint-disable-next-line no-console
            console.warn("?health-check endpoint does not work in development builds, as there is no runtime environment.json to compare to.");
        }
        return $q.when(Object.assign({}, builtinNinjaEnvJson, overrides));
    }

    // We're a production build, so grab /environment.json
    // as json, bubbling up any errors
    // Note: appending ?date=`Date.now()` here to cache-bust older nginx-based
    // deployments. Once all apps are deployed on S3, this will no longer be
    // needed
    return $http.get('/environment.json')
        .then( response => {
            const runTimeEnvJson = response.data;

            if(!runTimeEnvJson) return false;
    
            // combine the fetched env vars with optional overrides
            const finalEnv = Object.assign({}, runTimeEnvJson, overrides);
    
            // validate the final environment shape
            const envErrors = ensureEnvShape(finalEnv, builtinNinjaEnvJson);
            if (document.location.search === '?health-check') {
                // if doing a /health-check, don't resolve the bootstrapEnvironmentVars Promise,
                // instead, just write the health status to the page
                writeHealthToPage(envErrors);
                return false;
            } else {
                // Not doing a health check, resolve the bootstrapEnvironmentVars Promise
                // so the app can bootstrap. Any Health check issues will have been
                // logged to the console as errors
                return finalEnv;
            }
        });
    
}
    
/**
 * Writes health-check status to the document.
 * This should only be called if /health-check is triggered.
 * Otherwise, we allow the app to bootstrap and just write
 * any errors to the JS console.
 */
function writeHealthToPage(errors) {
    const e = document.createElement('div');
    e.style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; z-index: 1000";
    if (errors) {
        e.innerHTML = 'Health: <span id="status">ERROR</span><br /><div id="errors">' 
                + errors.map( ({key, expectedType, foundType}) => 
                    `<span>Expected to find environment key [${key}] of type [${expectedType}] but found type [${foundType}] instead.</span>`
                ).join('<br />')
                + "</div>";
    } else {
        e.innerHTML = 'Health: <span id="status">OK</span>';
    }
    document.body.append(e);
}
    
/**
 * Compare an environment object with one that represents
 * the expected shape (keys and value types).
 * Warns to the console of differences and returns a list
 * of them to the caller.
 *
 * @param env Environment object to validate
 * @param expectedShape Environment object use compare to
 * @returns false if no errors found, otherwise list of issues found
 */
function ensureEnvShape(env, expectedShape) {
    const issues = Object.entries(expectedShape)
        .map(([key, val]) => ({
            key,
            expectedType: typeof val,
            foundType: typeof env[key]
        }))
        .filter(({expectedType, foundType})=> expectedType !== foundType);
    // log issues to console
    issues.forEach(({key, expectedType, foundType}) =>
    // eslint-disable-next-line no-console
        console.error(`Expected to find environment key [${key}] of type [${expectedType}] but found type [${foundType}] instead.`)
    );
    return issues.length > 0 && issues;
}