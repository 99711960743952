/**
 * Directive to implement a form text input
 */
(function () {
    'use strict';

    angular.module('brightfunnelUserAuth').directive('formInput', formInputComponent);

    formInputComponent.$inject = [];

    /**
     * Form input component
     * @returns {{bindToController: boolean, controller: formInputCtrl, scope: {placeholder: string, type: string, value: string}, restrict: string, controllerAs: string, templateUrl: string}}
     */
    function formInputComponent() {
        return {
            restrict: 'E',
            template: require('./form-input.html'),
            controller: formInputCtrl,
            controllerAs: '$ctrl',
            scope: {
                'focus': '=',
                'type': '=',
                'value': '=',
                'placeholder': '='
            },
            bindToController: true,
            link: formInputLink
        };
    }

    /**
     * Empty controller
     */
    function formInputCtrl() {
    }

    /**
     * Link to give focus to the input
     * @param scope Directive scope
     * @param element The HTML element
     * @param attrs
     * @param ctrl
     */
    function formInputLink(scope, element, attrs, ctrl) {
        if (ctrl.focus) {
            element[0].getElementsByTagName('input')[0].focus();
        }
    }
})();
