/**
 * Onboarding Pardot Service.
 */
(function () {
    'use strict';

    angular
        .module('brightfunnelOnboard')
        .factory('pardotSrvc', pardotSrvc);

    pardotSrvc.$inject = ['onboardingConstants', 'authSrvc', 'utilsSrvc'];

    /**
     * Service to set Pardot data.
     */
    function pardotSrvc(onboardingConstants, authSrvc, utilsSrvc) {
        var service = {};

        service.addSubmitParams = function (paramsObj, vars) {
            let jwt = authSrvc.getParsedJwt(onboardingConstants.TERMINUS_HUB);
            let orgAcl = {};
            orgAcl[onboardingConstants.SUB_ORG_CLAIM] = jwt[onboardingConstants.SUB_ORG_CLAIM];
            orgAcl[onboardingConstants.SUB_CLAIM] = jwt[onboardingConstants.SUB_CLAIM];
            paramsObj.params[onboardingConstants.ACL_FIELD] = utilsSrvc.jsonToFieldObject(orgAcl);
            paramsObj.params[onboardingConstants.FIELDS_FIELD] = utilsSrvc.jsonToFieldObject(vars);
        };

        service.storeParams = function (paramsObj) {
            return {
                params: paramsObj.params,
                serviceName: paramsObj.state.name
            };

        };

        return service;
    }
})();
