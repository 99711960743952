/**
 * Directive to implement container for a user auth form
 */
(function () {
    'use strict';

    angular.module('brightfunnelUserAuth').directive('formContainer', formContainerComponent);

    formContainerComponent.$inject = ['userAuthSrvc'];

    /**
     * Form container component
     * @returns {{bindToController: boolean, controller: formContainerCtrl, transclude: boolean, restrict: string, controllerAs: string, templateUrl: string}}
     */
    function formContainerComponent() {
        return {
            restrict: 'E',
            template: require('./form-container.html'),
            controller: formContainerCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true,
            link: formContainerLink
        };
    }

    /**
     * Empty Controller
     */
    function formContainerCtrl($scope, userAuthSrvc) {
        $scope.service = userAuthSrvc;
    }

    function formContainerLink(scope, element) {
        element.bind('keydown keypress', function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    var fn = scope.service.getFormClickFn();
                    if (fn) {
                        fn()();
                    }
                });

                event.preventDefault();
            }
        });
    }
})();
