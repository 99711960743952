/* eslint-disable no-console */
(function () {
    'use strict';

    angular.module('accountJourney').service('peopleTabService', peopleTabService);

    peopleTabService.$inject = ['personActivityCountAggregator'];

    function peopleTabService(personActivityCountAggregator) {
        var service = {};

        service.mapPeople = function (data) {
            personActivityCountAggregator(data);
            var people = data['people'].data.contacts.map(function (m) {
                return {
                    personName: m.name,
                    title: m.title || '',
                    createdDate: m.created_date,
                    lastOpptyDate: m.oppty_created_date,
                    lastTouch: m.lastTouch,
                    totalCounts: m.totalCounts,
                    marketingCounts: m.marketingCounts,
                    salesCounts: m.salesCounts,
                    id: m.id,
                    leadId: m.lead_id,
                    contactId: m.contact.id,
                    lastOpptyId: m.last_oppty_id,
                    salesOrMarketingActivity: hasSalesOrMarketingActivities(data, m)
                };
            });

            return people;
        };

        service.getPeopleDetails = function (data, person) {
            var salesActivityForPerson = data['sales'].data.tasks.filter(function (s) {
                return s.leadId === person.leadId || s.leadId === person.contactId;
            }).map(function (salesActivity) {
                return {
                    activityName: salesActivity.subject,
                    touchType: 'Sales',
                    activityType: salesActivity.type,
                    date: salesActivity.created_date,
                    record: getRecord(salesActivity.leadId, person),
                    contactId: salesActivity.leadId,
                    entityId: salesActivity.activityId
                };
            });

            var marketingActivityForPerson = data['touches'].data.activity_list.filter(function (s) {
                return s.lead_id === person.leadId || s.lead_id === person.contactId;
            }).map(function (marketingActivity) {
                return {
                    activityName: marketingActivity.campaign.name,
                    touchType: 'Marketing',
                    activityType: marketingActivity.campaign.revenue_group,
                    date: marketingActivity.activity_date,
                    record: getRecord(marketingActivity.lead_id, person),
                    contactId: marketingActivity.lead_id,
                    entityId: marketingActivity.campaign.campaignMemberId
                };
            });

            return salesActivityForPerson.concat(marketingActivityForPerson);
        };

        function getRecord(leadId, person) {
            if (leadId === person.id) {
                return 'Lead';
            }
            if (leadId === person.contactId) {
                return 'Contact';
            }
            return '';
        }

        function hasSalesOrMarketingActivities(data, person) {
            return data['sales'].data.tasks.filter(function (s) {
                return s.leadId === person.lead_id || s.leadId === person.contact.id;
            }).length > 0 ||
                data['touches'].data.activity_list.filter(function (s) {
                    return s.lead_id === person.lead_id || s.lead_id === person.contact.id;
                }).length > 0;
        }

        return service;
    }
})();

